import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import Group from "../../components/teacher/group";
import Notification from "../../components/alert"

const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
});

export default function Students() {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState(null);
  const [grades, setGrades] = useState([]);
  const [GradeValue, SetGradeValue] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [days, setDays] = useState([])
  const [search,setSearch] = useState("")
  const [start_time,setStart_time] = useState(null)
  const [end_time,setEnd_time] = useState(null)
  const [teacherOption, setTeacherOption] = useState([])
  const [teacherSelected, setTeacherSelected] = useState(null)


  const navigate = useNavigate();

  const [groupTitle, setGroupTitle] = useState();
  const [telegramId, setTelegramId] = useState();
  const [formDays, setFormDays] = useState();
  const [teacher, setTeacher] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [formSubject, setFormSubject] = useState(null);
  const [formGrade, setFormGrade] = useState();
  const [who, setWho] = useState(null);
  const [sms, setSms] = useState("Assalomu alaykum @oquvchi siz ushbu @guruh uchun to'lov qilishni unutmang.");



  const handleGroupSubmit = (event) => {
    event.preventDefault();
    if (formDays){
      const formData = {
        title: groupTitle,
        telegram_id: telegramId,
        days: formDays.map(day => day.id),
        teacher: teacher,
        start_time:startTime.$d.toLocaleTimeString('en-US', { hour12: false }),
        end_time: endTime.$d.toLocaleTimeString('en-US', { hour12: false }),
        subject: formSubject,
        grade: formGrade.id
      }
      axios
        .post('main/group/', formData)
        .then(res => {
          Notification(
            "Muvaffaqiyatli amalga oshirildi",
            "Guruh muvaffaqiyatli qo'shildi",
            "var(--green)",
            6000
          )
          console.log(res.data)
          setGroups([...groups, res.data])
          setFiltered([...groups, res.data])
          setGroupTitle("");
          setTelegramId("");
          setFormDays("");
          setTeacher("");
          setStartTime("");
          setEndTime("");
          setFormSubject("");
          setFormGrade("");
          setModal(false);
        })
        .catch(err => {
          console.log(err);
          if(err.response.data["non_field_errors"]){
            Notification(
              "Muvaffaqiyatli amalga oshirilmadi",
              "Bu vaqtda allaqachon darsi bor",
              "var(--red)",
              6000
            )
          }
          else{
            Notification(
              "Xatolik yuz berdi",
              err.message,
              "var(--red)",
              6000
            )
          }
        })
    }
    else{
      Notification(
        "Formani to'ldiring",
        "iltimos hafta kunlarini tanlang",
        "var(--yellow)",
        6000
      )
    }
  };

  useEffect(() => {
    setWho(JSON.parse(localStorage.getItem("who")))

    setIsLoading(true);
    
    
    let local_user = JSON.parse(localStorage.getItem("who"))
    
    if ( local_user?.includes("0") || local_user?.includes("1") || local_user?.includes("4") ||  local_user?.includes("5")){
        axios.get("main/subject/").then((response) => {
          setSubjects(response.data);
        }).catch(err => console.log(err));
        axios
        .get('accounts/teachers/option/')
        .then((res) =>{
          setTeachers(res.data);
          setTeacherOption(res.data)
        }).catch(err => console.log(err))
    }
    axios
      .get("main/group/")
      .then((res) => {
        if (res.status === 200) {
          setGroups(res.data);
          setFiltered(res.data);
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  }, [useLocation()]);

  useEffect(() => {
    SetGradeValue(null);
    if (subject) {
      axios.get("main/grade/" + subject.id + "/").then((response) => {
        setGrades(response.data);
      });

      let searchData = {
        searchTitle: search,
        subject: subject ? subject.id : null,
        grade:  GradeValue ? GradeValue.id : null,
        weekdays: days ? days : null,
        start_time: start_time,
        end_time: end_time,
        teacher: teacherSelected ? teacherSelected : false
      }
      filterGroups(searchData)

    } else {
      setGrades([]);
      let searchData = {
        searchTitle: search,
        subject:  null,
        grade:  GradeValue ? GradeValue.id : null,
        weekdays: days ? days : null,
        start_time: start_time,
        end_time: end_time,
        teacher: teacherSelected ? teacherSelected : false
      }
      filterGroups(searchData)
    }
  }, [subject]);

  useEffect(()=>{
    let searchData = {
      searchTitle: search,
      subject: subject ? subject.id : null,
      grade:  GradeValue ? GradeValue.id : null,
      weekdays: days ? days : null,
      start_time: start_time,
      end_time: end_time,
      teacher: teacherSelected ? teacherSelected : false
    }
    filterGroups(searchData)
  },[days,start_time,end_time, GradeValue, search, teacherSelected])

  function filterGroups(data){
    let for_filter = groups
    for (const item of for_filter){
      if(data.subject !== null){
        for_filter = for_filter.filter(item => item.subject.id === data.subject)
      }
      if(data.grade !== null){
        for_filter = for_filter.filter(item => item.grade.id === data.grade)
      }
      if(data.weekdays?.length){
        let week_id = data.weekdays?.map(item => item.id)
        for ( const i of week_id){
          console.log(i)
          for_filter = for_filter.filter(item => item.days.includes(String(i)))
        }
      }
      if(data.searchTitle !== ""){
        for_filter = for_filter.filter(item => item.title.toLowerCase().includes(data.searchTitle.toLowerCase()))
      }
      if (teacherSelected){
        for_filter = for_filter.filter(item => item.teacher.id === teacherSelected.id)
      }
      
      let start_time = `${data.start_time?.$H}:${data.start_time?.$m < 10 ? "0"+data.start_time?.$m: data.start_time?.$m }`
      if(start_time.length === 5){
        for_filter = for_filter.filter(item => {
          let time = item.start_time.slice(0,5)
          return time === start_time
        }
      )
      }
  
      let end_time = `${data.end_time?.$H}:${data.end_time?.$m < 10 ? "0"+data.end_time?.$m: data.end_time?.$m }`
      if(end_time.length === 5){
        for_filter = for_filter.filter(item => {
          let time = item.end_time.slice(0,5)
          return time === end_time
        }
      )
      }
    }
    setFiltered(for_filter)
  }

  useEffect(() => {
    if (formSubject) {
      axios.get("main/grade/" + formSubject + "/").then((response) => {
        console.log(response);
        setGrades(response.data);
      });
    } else {
      setGrades([]);
      setFormGrade(null);
    }
    console.log(formSubject);
  }, [formSubject]);


    document.body.ondblclick = (event) => {
      setList([]);
      setModal(false);
      setModalType(0);
    };
  
    const handleListChange = (event, group) => {
      if (event.target.checked) {
        setList([...list, group]);
      } else {
        setList(list.filter((item) => item !== group));
      }
    };
    const handleCheckAllChange = (e) => {
      if (e.target.checked) {
        const all = groups.map((c) => c);
        setList(all);
      } else {
        setList([]);
      }
    };
  
    function deleteGroups(e){
      e.preventDefault()
      let data = new FormData(e.target)
  
      axios
        .delete("main/group/",{data: {
          group_ids: list.map(item => item.id),
          reason: data.get('reason')
        }})
        .then((res)=>{
          navigate('/groups/')
          setGroups(groups.filter(item => !list.includes(item)))
          setFiltered(filtered.filter(item => !list.includes(item)))
          setModalType(0)
          setModal(false)
          Notification(
            "Muvaffaqiyatli O'chirildi",
            "Bu guruhni 3kun ichida yana qaytarish mumkin ",
            "var(--green)",
            6000
          )
        }).catch(err => console.log(err))
    }

    const sendSms = () => {
      console.log("Sending sms...")
      const groupIdList = [];
      list.forEach(item => {
        groupIdList.push(item.id)
      })
      const formData = {
        groups: groupIdList,
        message: sms
      }
      axios
        .post("sms/send-group/", formData)
        .then(res => {
          return Notification("Muvaffaqiyatli amalga oshirildi", "Sms habarlar yuborildi", "var(--green)", 5000)
        }).catch(err => Notification("Xatolik", "Sms habarlar yuborilmadi", "var(--red)", 5000))
    }

  return (
    <>
      <div className="br-card" style={{ minHeight: "unset" }}>
        <br />
        <div className="flex justify-between flex-wrap">
          <div className="flex gap-4 flex-wrap my-4">
            <h2 className="text-3xl m-3 font-bold">Guruhlar</h2>
          </div>
         
          {
           who?.includes("0") || who?.includes("1") || who?.includes("4") ?  (
              <div className="flex flex-wrap gap-4 items-center flex-wrap my-4">
                <button
                  title="Guruh qo'shish"
                  onClick={() => {setModalType(2); setModal(true)}}
                  className="button bg-[var(--oppocity-bg)] text-[var(--theme-bg)] hover:opacity-80"
                >
                  <span className=" text-xl mx-2">Guruh qo'shish</span>
                  <i className="fa-regular fa-layer-plus text-xl mr-2"></i>
                </button>
                <button
                  title="Xabar yozish"
                  onClick={() => {setModalType(0); setModal(true)}}
                  className="button reception_button__lYibt text-[var(--yellow)]"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 7L16 18L4 7"
                      stroke="#FFCC00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                      stroke="#FFCC00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M13.8184 16L4.30859 24.7174"
                      stroke="#FFCC00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M27.6916 24.7175L18.1816 16"
                      stroke="#FFCC00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
                <button
                  title="O'chirish"
                  className="button"
                  onClick={() => {setModalType(1); setModal(true)}}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.687 7.4375L5.31201 7.43751"
                      stroke="#FC3F1D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.8125 13.8125V22.3125"
                      stroke="#FC3F1D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.1875 13.8125V22.3125"
                      stroke="#FC3F1D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                      stroke="#FC3F1D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                      stroke="#FC3F1D"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button title="Yuklab olish" className="button">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.75 13.7515L16 19.0001L21.25 13.7515"
                      stroke="#4FB286"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 5V18.9963"
                      stroke="#4FB286"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
                      stroke="#4FB286"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )
            : (<><h1></h1></>)
          }
        </div>
        <br />
        {
            (who?.includes("0") || who?.includes("1") || who?.includes("4") || who?.includes("5") )  ? (
              <>
                <div className="d-flex flex-wrap">
                  <TextField
                    fontcolor="var(--theme-color)"
                    onChange={(event) => setSearch(event.target.value)}
                    value={search}
                    label="Qidiruv"
                    size="small"
                  />
                  <Autocomplete
                    onChange={(event, newValue) => {
                        if(newValue){
                          setSubject(newValue);
                        }
                        else{
                          setSubject(null)
                        }
                    }}
                    disablePortal
                    value={subject}
                    id="combo-box-demo"
                    options={subjects}
                    getOptionLabel={(option) => option.title || ""}
                    // isOptionEqualToValue={(option, value) => option.id == value.id}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    style={{ width: "200px" }}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Fan" />
                    )}
                  />
                  <Autocomplete
                    onChange={(event, newValue) => {
                        SetGradeValue(newValue);
                    }}
                    value={GradeValue}
                    disablePortal
                    id="combo-box-demo"
                    options={grades}
                    style={{ width: "200px" }}
                    getOptionLabel={(option) => option.title}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Daraja" />
                    )}
                  />
                  <Autocomplete
                    onChange={(event, newValue) => {
                        setTeacherSelected(newValue);
                    }}
                    value={teacherSelected}
                    disablePortal
                    id="combo-box-demo"
                    options={teacherOption}
                    style={{ width: "250px" }}
                    getOptionLabel={(option) => option?.name + " " + option?.surname}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="O'qituchi" />
                    )}
                  />
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setDays(newValue)
                    }}
                    value={days}
                    disablePortal
                    id="combo-box-demo"
                    multiple
                    options={Weekdays}
                    isOptionEqualToValue={(option, value) => days.includes(option)}
                    style={{ minWidth: "200px" }}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Hafta kuni" />
                    )}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      label="Boshlanasish vaqti"
                      value={start_time}
                      onChange={(newValue) => setStart_time(newValue)}
                      format="HH:mm"
                      size="small"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      label="Tugash vaqti"
                      value={end_time}
                      onChange={(newValue) => setEnd_time(newValue)}
                      format="HH:mm"
                      size="small"
                    />
                  </LocalizationProvider>
                </div>
                <br />
                <br />
                <h3 className="text-xl m-4">
                  <input
                    type="checkbox"
                    checked={groups.length === list.length}
                    onChange={(e) => handleCheckAllChange(e)}
                    className="accent-[var(--theme)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                  />
                  Hammasini belgilash
                </h3>
                </>
           )
           :
           (
            <></>
           )
        }
      </div>
      <br /> <hr />
      <br />
      <div
        onDoubleClick={() => setList([])}
        className="grid 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-2 sm:p-5 sm:gap-5 gap-3 gap-2"
      >
        {filtered.map((item, index) => (
          <Group
            item={item}
            handleClick={(event, item) => handleListChange(event, item)}
            key={index}
            active={list.includes(item)}
          />
        ))}
      </div>
      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">
                    Tanlangan foydalanuvchilar: {list.length}ta{" "}
                  </h3>
                  <div className="flex gap-3">
                    <button className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <MultiAutocomplete
                    sx={{
                      border: "1px solid var(--blue)",
                      outline: "var(--blue)",
                    }}
                    color="var(--blue)"
                    placeholder="Guruhlar"
                    HandleChange={(event, value) => setList(value)}
                    options={groups}
                    value={list }
                    getOptionLabel={(option) => option.title}
                  />
                  <textarea
                    rows={7}
                    value={sms}
                    onChange={(e) => setSms(e.target.value)}
                    className="textarea"
                    required
                    placeholder="Xabar yozish (majburiy)"
                  ></textarea>
                  <br />
                  <br />
                  <h3 className="text-base">
                    @oquvchi - Oquvchining ism familyasi
                    <br />
                    @guruh - Guruh nomi
                  </h3>
                </div>
                <div className="flex">
                  <button
                    onClick={sendSms}
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Xabar berish
                  </button>
                </div>
              </div>
            ),
            1: (
              <form onSubmit={deleteGroups} className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      stroke-width="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      stroke-width="10"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      stroke-width="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      stroke-width="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      stroke-width="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      stroke-width="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz hozirda {list.length}ta guruhni ochirmoqdasiz va bularni
                    qaytarib bolmaydi
                  </h3>
                </div>
                <input
                    type="text" 
                    name="reason"
                    maxLength={125}
                    required={true}
                    className="input" placeholder="Sabab" />
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                  type="reset"
                    onClick={() => {
                      setList([]);
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                  
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                    O'chirish
                  </button>
                </div>
              </form>
            ),
            2: (
              <form onSubmit={handleGroupSubmit} className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <button type="reset" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content h-100 overflow-auto">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="input">
                      <TextField
                        className="w-full"
                        size="small"
                        value={groupTitle || ""}
                        onChange={(e) => setGroupTitle(e.target.value)}
                        name="groupName"
                        label="Guruh nomi"
                        required
                      />
                    </div>
                    <div className="input">
                      <TextField
                        className="w-full"
                        size="small"
                        value={telegramId }
                        onChange={(e) => setTelegramId(e.target.value)}
                        name="telegramId"
                        label="Telegram Id"
                        required
                      />
                    </div>
                    <Autocomplete
                      onChange={(event, newValue) => {
                          if (newValue != null) {
                            setFormDays(newValue);
                          }
                         else {
                            setFormDays("")
                          }
                         }}
                      disablePortal
                      value={formDays }
                      id="combo-box-demo"
                      multiple
                      name="weekDays"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      className=" input"
                      options={Weekdays}
                      style={{ minWidth: "200px" }}
                      classes={{ paper: classes.paper }}
                      required
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Hafta kuni"
                        />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      required
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                           if (newValue != null) {
                               setTeacher(newValue.id);
                           }
                           else {
                               setTeacher("")
                           }
                       }}
                      disablePortal
                      id="combo-box-demo"
                      options={teachers }
                      getOptionLabel={(option) => {
                        return option.name + " " + option.surname;
                      }}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          label="O'qituvchi"
                        />
                      )}
                    />
                    <div className="input">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          label="Boshlananish vaqti"
                          className="w-full"
                          required={true}
                          value={startTime}
                          onChange={(newValue) => {
                            setStartTime(newValue)
                          }}
                          format="HH:mm"
                          size="small"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="input">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          label="Tugash vaqti"
                          className="w-full"
                          required={true}
                          value={endTime}
                          onChange={(newValue) => setEndTime(newValue)}
                          format="HH:mm"
                          size="small"
                        />
                      </LocalizationProvider>
                    </div>
                    <Autocomplete
                      className=" input"
                      required
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        console.log(grades)
                        if (newValue != null) {
                          setFormSubject(newValue.id);
                        } else {
                          setFormSubject("");
                          setFormGrade("");
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={subjects }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} required size="small" label="Fan" />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      required
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setFormGrade(newValue);
                        } else {
                          setFormGrade(null);
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={grades}
                      value={formGrade} 
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} required size="small" label="Daraja" />
                      )}
                    />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Guruh qo'shish
                  </button>
                </div>
              </form>
            ),
          }[modalType]
        }
      </div>
    </>
  );
}
const Weekdays = [
  { label: "Dushanba", id: 0 },
  { label: "Seshanba", id: 1 },
  { label: "Chorshanba", id:2 },
  { label: "Payshanba", id: 3 },
  { label: "Juma", id: 4 },
  { label: "Shanba", id: 5 },
  { label: "Yakshanba", id: 6 },
];

const MultiAutocomplete = (props) => {
  return (
    <Autocomplete
      sx={{ m: 1 }}
      style={{ "--set-color": props.color }}
      value={props.value}
      onChange={(event, value) => props.HandleChange(event, value)}
      multiple
      id="tags-standard"
      options={props.options}
      getOptionLabel={(option) => option.title}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem
          key={option}
          value={option}
          sx={{ justifyContent: "space-between" }}
          {...props}
        >
          {option.title}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.placeholder}
          placeholder={props.placeholder}
          fullWidth
        />
      )}
    />
  );
};
