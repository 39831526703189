import axios from "axios";
import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Notification from "../../components/alert";
import {  useLocation } from "react-router-dom";

export default function SubjectCreate(params) {
    const [subject, setSubject] = useState([]);
    const [degree, setDegree] = useState([]);
    const p = useParams();
    const subjectId = p.subjectId
    const [deletedGrades, setDeletedGrades] = useState([]);
    const navigate = useNavigate()


    useEffect(() => {
        axios
            .get('main/subject/' + subjectId + '/')
            .then(res => {
                setSubject(res.data);
                axios
                    .get('main/grade/' + subjectId + '/')
                    .then(res => {
                        console.log(res.data);
                        setDegree(res.data);
            })
            .catch(error => navigate(`/errors/${error.request.status}`))
        })
        .catch(error => navigate(`/errors/${error.request.status}`))
    },[useLocation()] )

    function degreeCreate(){
        setDegree([...degree, {id: "new" + degree.length+1, title:"", price: 0}])
    }
    function deleteDegree(id){
        setDegree(degree.filter((item) => item.id !== id));
        setDeletedGrades([...deletedGrades, id]);
    }
    function changeTitle(title, id){
        const newState = degree.map(obj => {
            // 👇️ if id equals 2, update country property
            if (obj.id === id) {
              return {...obj, title: title};
            }
      
            // 👇️ otherwise return the object as is
            return obj;
          });
          setDegree(newState);
    }
    function changePrice(e, id){
        if (e.target.value.length < 12) {
            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
            if (n) {
                e.target.value = n.toLocaleString().replaceAll(',', ' ' );
            } else {
                e.target.value = ""
            }
        } else {
            e.target.value = e.target.value.toLocaleString().slice(0, -1);
        }
        let money = e.target.value

        const newState = degree.map(obj => {
            // 👇️ if id equals 2, update country property
            if (obj.id === id) {
              return {...obj, price: money};
            }
      
            // 👇️ otherwise return the object as is
            return obj;
          });
          setDegree(newState);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let subject_title = data.get('subject_title');
        const formData = {
            title: subject_title,
            grades: degree,
            deletedGrades: deletedGrades
        }
        axios
            .put('main/subject/' + subjectId + '/', formData)
            .then(response => {
                navigate("/settings")
                Notification(
                    "Fan yangilandi",
                    "Mufaqiyatli amalga oshdi",
                    "var(--green)",
                    5000
                  )
            })
            .catch(error => {
                Notification(
                    "Nimadir hato ketdi",
                    "Keyinroq urinib ko'ring",
                    "var(--red)",
                    5000
                  )
            })
    }

    const handleTitleChange = (e) => {
        setSubject(e.target.value)
    };
    // const handleGradeTitleChange = (e) => {};
    // const handleGradePriceChange = (e) => {};
    
    function visibleNumber(value){
        if (value.trim() !== 0){
            var n = parseInt(value.replaceAll(/\D/g, ''), 10);
            n = n.toLocaleString().replaceAll(',', ' ' )
            if (n) {
              return  n;
            } else {
              return "0"
            }
        }
        else{
            return "0"
        }
      
      }
    return (
        <>
            <form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-5">
                <div>
                    <div className="bg-[var(--theme-sidebar)] rounded-3xl p-6">
                        <h1 className="text-3xl">Fan qo'shish</h1>
                        <br />
                        <div className="bg-[var(--theme-bg)] flex items-center my-3 gap-5 max-w-[400px]  rounded-[15px] p-4 px-[20px]">
                            <i className="fa-solid fa-flask text-2xl"></i>
                            <input onChange={e => handleTitleChange(e)} type="text" required name="subject_title" placeholder="Fan nomi" className="bg-transparent w-full text-2xl outline-none border-none" defaultValue={subject.title} />
                        </div>
                        <br />
                        <div className="flex gap-5">
                            <button className="bg-[var(--theme-bg)] hover:bg-[var(--green)] text-[var(--green)] text-xl px-5 font-semibold hover:text-white py-2 px-4 hover:border-transparent rounded-3xl">
                                Jo'natish
                            </button>
                            <span className="bg-[var(--theme-bg)] hover:bg-[var(--red)] text-[var(--red)] text-xl px-5 font-semibold hover:text-white py-2 px-4 hover:border-transparent rounded-3xl">
                                Bekor qilish
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bg-[var(--theme-sidebar)] rounded-3xl p-6">
                        <h1 className="text-3xl">Darajasini qo'shish</h1>
                        <br />
                        <div className="max-h-[450px] w-full pr-5 overflow-x-auto mt-5">
                            {degree.slice(0).reverse().map( (item, idx) => (
                                <div className="flex gap-5 sm:flex-nowrap flex-wrap items-center" key={idx}>
                                    <input type="hidden" value={item.id} name='id' />
                                    <div className="bg-[var(--theme-bg)] flex items-center my-3 gap-5 max-w-[400px]  rounded-[15px] p-4 px-[20px]">
                                        <i className="fa-solid fa-vial text-2xl"></i>
                                        <input 
                                            type="text"
                                            placeholder="Daraja nomi"
                                            name={`grades${item.id}`}
                                            className="bg-transparent w-full text-2xl outline-none border-none"
                                            value={item.title}
                                            onChange={(e) => changeTitle(e.target.value, item.id) }
                                            
                                        />
                                    </div>
                                    <div className="bg-[var(--theme-bg)] flex items-center my-3 gap-5 max-w-[400px]  rounded-[15px] p-4 px-[20px]">
                                        <i className="fa-solid fa-dollar-sign text-2xl"></i>
                                        <input type="text"
                                            placeholder="Narx"
                                            name="grades_price"
                                            className="bg-transparent w-full text-xl outline-none border-none"
                                            value={visibleNumber(`${item.price}`)}
                                            onChange={(e) => changePrice(e, item.id)} />
                                    </div>
                                    <div>
                                        <div className="button" onClick={()=> deleteDegree(item.id)}>
                                            <svg className="md:w-[28px] md:h-[28px] w-[24px] h-[24px]" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.625 6.125L4.375 6.12501" stroke="var(--red)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.375 11.375V18.375" stroke="var(--red)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.625 11.375V18.375" stroke="var(--red)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125" stroke="var(--red)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125" stroke="var(--red)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br /> <br />
                        <span
                            onClick={degreeCreate}
                            className="bg-[var(--theme-bg)] cursor-pointer hover:bg-[var(--oppocity-bg)] text-[var(--theme-color)] text-xl px-5 font-semibold hover:text-[var(--theme-bg)] py-2 px-4 hover:border-transparent rounded-3xl">
                            <i className="fa-regular fa-circle-plus mx-2"></i>   
                            Daraja qo'shish
                        </span>
                    </div>
                </div>
            </form>
        </>
    )
};
