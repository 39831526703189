import weekdays from '../../assets/css/weekdays.module.css';

const WeekDays = (props) => {
    return (
    <>
        <div className={`${weekdays.days} ${props.bg ? weekdays.active : ''}`}>
            <span className={`${props?.days?.includes("0") ? weekdays.active : ''}`}>Du</span>
            <span className={`${props?.days?.includes("1") ? weekdays.active : ''}`}>Se</span>
            <span className={`${props?.days?.includes("2") ? weekdays.active : ''}`}>Ch</span>
            <span className={`${props?.days?.includes("3") ? weekdays.active : ''}`}>Pa</span>
            <span className={`${props?.days?.includes("4") ? weekdays.active : ''}`}>Ju</span>
            <span className={`${props?.days?.includes("5") ? weekdays.active : ''}`}>Sh</span>
            <span className={`${props?.days?.includes("6") ? weekdays.active : ''}`}>Ya</span>
        </div>
    </>
    )
  }


export default WeekDays;