import React from "react";

export function Loader(params) {
  return (
    <>
      <div className="center">
        <div className="loader">
          <div className="field"></div>
          <div className="field"></div>
          <div className="field"></div>
          <div className="field"></div>
        </div>
      </div>
    </>
  );
}



export default Loader