import CardHome from '../../components/card_home';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styleNames
import 'swiper/css';
import "swiper/css/pagination";

import { Pagination } from "swiper";


function Home() {
    const list = [
        { 'id': 0, 'name': 'Kirim', "number": '128.11 sum' },
        { 'id': 1, 'name': 'Chiqim', "number": '18.11 sum' },
        { 'id': 2, 'name': 'Kutayotganlar', "number": '200 ta' },
        { 'id': 3, 'name': 'O‘quvchilar', "number": '882 ta' },
        { 'id': 4, 'name': 'Guruhlar', "number": '25 ta' },
        { 'id': 5, 'name': 'Chiqim ketganlar', "number": '50 ta' },
        { 'id': 6, 'name': 'Kelmayotganlar', "number": '150 ta' },
        { 'id': 7, 'name': 'To‘lovlar', "number": '628ta / 200ta' },
    ]
    return (
        <>
            <Swiper
                slidesPerView={4}
                spaceBetween={5}
                pagination={true}
                modules={[Pagination]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    680: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    910: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                }}
                className="mySwiper"
            >
                {list.slice(0, 4).map((item) => <SwiperSlide key={item.id}><CardHome href='/' index={item.id} title={item.name} number={item.number} ></CardHome></SwiperSlide>)}

            </Swiper>
            <br /><br />
            <Swiper
                slidesPerView={1}
                spaceBetween={5}
                pagination={true}
                modules={[Pagination]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    680: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    910: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                }}
                className="mySwiper"
            >
                {list.slice(4, 8).map((item) => <SwiperSlide key={item.id}><CardHome href='/' index={item.id} title={item.name} number={item.number} ></CardHome></SwiperSlide>)}

            </Swiper>
            <br /><br /><br />

            <div className="br-card">
                
            </div>
        </>
    )
}

export default Home;