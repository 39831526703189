import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios"

const Logout = () => {
  React.useEffect(() => {
    localStorage.removeItem('token');
  }, [])

  return (
    <div className="bg-gray-50 dark:bg-gray-900 w-full h-screen flex items-center justify-center">
      <div className="container mx-auto flex items-center justify-center">
        <div className="w-auto max-w-full h-auto py-3 px-4 lg:px-52 lg:py-16 bg-white rounded-xl">
          <center>
            <a
              href="#"
              className="flex items-center justify-center gap-2 mb-6 text-3xl font-semibold text-black"
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 30 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_189_79"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="30"
                  height="25"
                >
                  <path d="M30 0H0V24.9622H30V0Z" fill="white" />
                </mask>
                <g>
                  <path
                    d="M6.765 4.3463V18.5195C6.75574 19.4105 6.39527 20.2619 5.76194 20.8886C5.12861 21.5154 4.27354 21.867 3.3825 21.867C2.49145 21.867 1.63639 21.5154 1.00305 20.8886C0.369717 20.2619 0.00926656 19.4105 0 18.5195V4.3463C0.000582015 3.4493 0.357428 2.58924 0.992056 1.9553C1.62669 1.32135 2.48713 0.965437 3.38415 0.965824C3.94511 0.964335 4.4974 1.10399 4.99024 1.37193C4.99793 1.37522 5.00122 1.37924 5.00488 1.37924C5.00678 1.38133 5.00938 1.38263 5.0122 1.3829C5.2927 1.53594 5.54912 1.72949 5.77317 1.95729C6.08766 2.27059 6.33713 2.6429 6.50737 3.05288C6.67756 3.46286 6.76511 3.90241 6.765 4.3463Z"
                    fill="#6285FF"
                  />
                  <path
                    d="M14.4951 7.40854V21.578C14.4951 22.0222 14.4076 22.4621 14.2376 22.8724C14.0676 23.2828 13.8185 23.6556 13.5044 23.9697C13.1904 24.2838 12.8175 24.5329 12.4071 24.7029C11.9968 24.8729 11.557 24.9604 11.1128 24.9604C10.6686 24.9604 10.2288 24.8729 9.81843 24.7029C9.40805 24.5329 9.03521 24.2838 8.72113 23.9697C8.40704 23.6556 8.1579 23.2828 7.98792 22.8724C7.81794 22.4621 7.73047 22.0222 7.73047 21.578V7.40854C7.73036 6.9641 7.81783 6.52398 7.98788 6.11338C8.1579 5.70274 8.40719 5.32965 8.72146 5.01538C9.03572 4.70111 9.40882 4.45182 9.81942 4.28181C10.2301 4.11176 10.6702 4.02428 11.1146 4.02439C11.2353 4.02439 11.3524 4.03171 11.4658 4.04232H11.4735C12.3033 4.13254 13.0707 4.52598 13.6283 5.14712C14.1859 5.76827 14.4946 6.57381 14.4951 7.40854Z"
                    fill="#6285FF"
                  />
                  <path
                    d="M22.2695 3.38045V17.5536C22.2695 18.4507 21.9132 19.311 21.2789 19.9453C20.6445 20.5796 19.7842 20.9359 18.8872 20.9359C17.9902 20.9359 17.1299 20.5796 16.4955 19.9453C15.8612 19.311 15.5049 18.4507 15.5049 17.5536V3.38045C15.5049 2.93621 15.5924 2.49632 15.7626 2.08594C15.9327 1.67557 16.182 1.30274 16.4963 0.988785C16.8106 0.674831 17.1837 0.425904 17.5942 0.256233C18.0048 0.0865625 18.4448 -0.000521894 18.889 -4.11141e-05C19.0286 -0.00147909 19.1681 0.00830851 19.3061 0.0292271H19.3134C20.0573 0.11923 20.7493 0.456848 21.2781 0.987764C21.5925 1.30178 21.842 1.67475 22.0121 2.08533C22.1823 2.49591 22.2697 2.93602 22.2695 3.38045Z"
                    fill="#6285FF"
                  />
                  <path
                    d="M29.9999 7.24716V21.417C29.9999 21.8612 29.9124 22.301 29.7424 22.7114C29.5724 23.1218 29.3233 23.4946 29.0092 23.8087C28.6951 24.1228 28.3222 24.3719 27.9119 24.5419C27.5015 24.7119 27.0617 24.7994 26.6175 24.7994C26.1734 24.7994 25.7335 24.7119 25.3232 24.5419C24.9128 24.3719 24.5399 24.1228 24.2259 23.8087C23.9118 23.4946 23.6627 23.1218 23.4927 22.7114C23.3227 22.301 23.2352 21.8612 23.2352 21.417V7.24716C23.2352 6.80272 23.3227 6.36267 23.4927 5.95204C23.6628 5.54144 23.9121 5.16834 24.2263 4.85411C24.5406 4.53984 24.9137 4.29058 25.3243 4.12054C25.7349 3.95049 26.1749 3.86297 26.6194 3.86301C27.067 3.86118 27.5102 3.95096 27.9218 4.12679H27.9255C27.9274 4.12888 27.93 4.13019 27.9328 4.13045C28.3355 4.29874 28.701 4.54493 29.0084 4.85484C29.3238 5.16808 29.5739 5.54089 29.7441 5.95156C29.9143 6.36223 30.0012 6.80261 29.9999 7.24716Z"
                    fill="url(#paint0_linear_189_79)"
                  />
                  <g opacity="0.3">
                    <path
                      d="M6.765 8.42554V18.5194C6.75574 19.4104 6.39527 20.2618 5.76194 20.8886C5.12861 21.5154 4.27354 21.8669 3.3825 21.8669C2.49145 21.8669 1.63639 21.5154 1.00305 20.8886C0.369717 20.2618 0.00926656 19.4104 0 18.5194V17.9377C0 17.9377 0.00731759 18.0215 0.0219517 18.1752C0.0292688 18.3325 0.0512199 18.5633 0.0881711 18.8447C0.1505 19.18 0.258712 19.5051 0.409756 19.8109C0.676815 20.3541 1.09264 20.8102 1.60885 21.1263C2.12505 21.4424 2.7204 21.6053 3.32561 21.5963C3.60008 21.5934 3.87304 21.5553 4.1378 21.4829C4.69712 21.3254 5.20141 21.015 5.5939 20.5865C5.99583 20.1506 6.26049 19.606 6.35488 19.0207C6.3622 18.9475 6.38049 18.889 6.38415 18.8048L6.39915 18.556C6.4061 18.428 6.40244 18.2341 6.4061 18.0768C6.4061 17.7475 6.40976 17.4146 6.41341 17.089C6.41341 16.4341 6.43537 15.7902 6.44305 15.1646C6.46829 13.917 6.50854 12.7532 6.5561 11.7548C6.64427 9.75724 6.765 8.42554 6.765 8.42554Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.6">
                    <path
                      d="M5.00524 1.37927C4.87804 1.3378 4.74856 1.3036 4.61744 1.27683C4.27943 1.19848 3.93234 1.16648 3.58573 1.18171C3.47673 1.18577 3.36809 1.19677 3.26049 1.21463C3.14714 1.22856 3.03474 1.24933 2.9239 1.27683C2.16918 1.44753 1.49608 1.8725 1.01744 2.48049C0.850584 2.68494 0.710437 2.90979 0.600366 3.14963C0.495282 3.38184 0.415555 3.62471 0.362561 3.87402C0.335648 4.00086 0.31598 4.1291 0.303658 4.25817L0.292683 4.35695C0.292683 4.36061 0.289024 4.41549 0.285365 4.4411C0.281707 4.50695 0.278049 4.5728 0.271098 4.635C0.256098 4.8911 0.230488 5.13256 0.208537 5.35573C0.16396 5.85402 0.0943602 6.34979 0 6.8411V4.34597C0.000675505 3.75914 0.153928 3.18261 0.444713 2.67291C0.735498 2.16321 1.15382 1.73789 1.65862 1.43868C2.16342 1.13948 2.73735 0.976674 3.32407 0.966258C3.91079 0.955842 4.49012 1.09817 5.00524 1.37927Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.3">
                    <path
                      d="M14.4951 11.4728V21.578C14.4951 22.0222 14.4076 22.462 14.2376 22.8724C14.0676 23.2827 13.8185 23.6556 13.5044 23.9697C13.1904 24.2838 12.8175 24.5329 12.4071 24.7029C11.9968 24.8729 11.557 24.9603 11.1128 24.9603C10.6686 24.9603 10.2288 24.8729 9.81843 24.7029C9.40805 24.5329 9.03521 24.2838 8.72113 23.9697C8.40704 23.6556 8.1579 23.2827 7.98792 22.8724C7.81794 22.462 7.73047 22.0222 7.73047 21.578V21.1683C7.73047 21.1683 7.73779 21.2524 7.75242 21.4057C7.76537 21.6273 7.79596 21.8475 7.84388 22.0642C8.0091 22.8176 8.42907 23.4909 9.03291 23.9707C9.42419 24.2788 9.88107 24.4928 10.3683 24.5963C10.4928 24.6215 10.6186 24.6398 10.7451 24.6512C10.8839 24.6607 11.0231 24.6632 11.1622 24.6585C11.4378 24.6434 11.7106 24.5954 11.9747 24.5155C12.5219 24.336 13.01 24.0109 13.3866 23.5752C13.7728 23.1321 14.0197 22.5848 14.0963 22.002C14.1179 21.8517 14.1301 21.7002 14.1329 21.5484L14.1366 21.0541C14.1366 20.7248 14.1402 20.3956 14.1442 20.0703C14.1442 19.4228 14.1658 18.7825 14.1735 18.1606C14.1988 16.9236 14.239 15.7679 14.2866 14.7764C14.3744 12.7939 14.4951 11.4728 14.4951 11.4728Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.6">
                    <path
                      d="M11.4658 4.04232C11.4329 4.05 11.3049 4.06829 11.1036 4.09756C10.9902 4.12683 10.8516 4.14878 10.7012 4.19268C10.5333 4.24167 10.3685 4.30017 10.2073 4.36793C10.0224 4.4497 9.84382 4.54522 9.67315 4.65366C9.48883 4.77575 9.31396 4.91148 9.14998 5.05976C8.98257 5.22209 8.83066 5.39967 8.69632 5.59024C8.55807 5.78356 8.44033 5.99078 8.3451 6.20854C8.25232 6.42659 8.18135 6.65331 8.13327 6.88537C8.10872 7.00138 8.09029 7.11864 8.07803 7.23659L8.05974 7.41183L8.05242 7.60244C8.02681 8.10366 7.98657 8.57159 7.93901 8.97439C7.89444 9.42549 7.82482 9.87373 7.73047 10.3171V7.40854C7.73036 6.9641 7.81783 6.52398 7.98788 6.11338C8.1579 5.70274 8.40719 5.32965 8.72146 5.01538C9.03572 4.70111 9.40882 4.45182 9.81942 4.28181C10.2301 4.11176 10.6702 4.02428 11.1146 4.02439C11.2353 4.02439 11.3524 4.03171 11.4658 4.04232Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.3">
                    <path
                      d="M22.2695 4.82927V17.5536C22.2695 18.4507 21.9132 19.311 21.2789 19.9453C20.6445 20.5796 19.7842 20.936 18.8872 20.936C17.9902 20.936 17.1299 20.5796 16.4955 19.9453C15.8612 19.311 15.5049 18.4507 15.5049 17.5536V17.2683C15.5049 17.2683 15.5122 17.3598 15.5268 17.5317C15.5467 17.7779 15.5895 18.0216 15.6549 18.2598C15.7601 18.6226 15.9259 18.9651 16.1451 19.2728C16.4202 19.6657 16.7788 19.9931 17.1951 20.2313C17.6784 20.5119 18.2277 20.6585 18.7866 20.6561C19.0828 20.6594 19.3778 20.6175 19.6613 20.5317C19.9597 20.4489 20.2436 20.3205 20.5028 20.1512C21.0422 19.797 21.4588 19.2845 21.6955 18.6841L21.7796 18.4573L21.8382 18.2191C21.8601 18.139 21.8638 18.0622 21.8784 17.9854C21.8941 17.9021 21.9015 17.8176 21.9004 17.7329C21.9187 17.4036 21.9004 17.0049 21.904 16.6463C21.9077 15.9073 21.9113 15.1683 21.9187 14.4402C21.9187 13.7122 21.9406 12.9988 21.9479 12.3073C21.9735 10.9207 22.0138 9.62923 22.0613 8.5207C22.1488 6.30362 22.2695 4.82927 22.2695 4.82927Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.6">
                    <path
                      d="M19.3061 0.0292271C19.2585 0.0365442 19.1378 0.0548368 18.9585 0.076788C18.8488 0.0841051 18.7061 0.1313 18.5524 0.15691C18.3839 0.203439 18.2178 0.25851 18.0549 0.32191C17.8679 0.399855 17.6869 0.491571 17.5134 0.596301C17.3242 0.712002 17.1456 0.844225 16.9796 0.991422C16.8915 1.06459 16.8146 1.1524 16.7345 1.23289C16.6613 1.32801 16.5768 1.41179 16.511 1.51093C16.368 1.70173 16.2465 1.90774 16.1488 2.1252C16.0478 2.34071 15.9705 2.56654 15.9183 2.79874C15.9037 2.91581 15.8671 3.00362 15.8561 3.14264L15.8378 3.33252L15.8342 3.38045V3.38411L15.8305 3.3874V3.41337L15.8268 3.5085C15.8012 4.01338 15.761 4.48167 15.7134 4.88411C15.669 5.33641 15.5993 5.7859 15.5049 6.23045V3.38045C15.5049 2.93621 15.5924 2.49632 15.7626 2.08594C15.9327 1.67557 16.182 1.30274 16.4963 0.988785C16.8106 0.674831 17.1837 0.425904 17.5942 0.256233C18.0048 0.0865625 18.4448 -0.000521894 18.889 -4.11141e-05C19.0286 -0.00147909 19.1681 0.00830851 19.3061 0.0292271Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.6">
                    <path
                      d="M27.9218 4.12679C27.8889 4.11911 27.7572 4.0935 27.5486 4.06825C27.4279 4.05362 27.2816 4.04594 27.1169 4.04228C26.9335 4.04158 26.7502 4.05271 26.5682 4.07557C26.3581 4.10685 26.1503 4.15196 25.9462 4.21057C25.7263 4.28151 25.5132 4.37224 25.3096 4.48167C24.8746 4.71973 24.4964 5.04926 24.2011 5.44752C24.05 5.64688 23.9223 5.86291 23.8206 6.09143C23.7226 6.31236 23.6478 6.54285 23.5974 6.77923C23.5701 6.89843 23.5517 7.01949 23.5425 7.14143L23.5316 7.23289L23.5279 7.25118L23.5243 7.26545L23.5206 7.31338C23.5169 7.37557 23.5133 7.43411 23.5096 7.4963C23.4913 7.73777 23.4694 7.96825 23.4438 8.17679C23.3994 8.64969 23.3297 9.11985 23.2352 9.58533V7.24716C23.2352 6.80272 23.3227 6.36267 23.4927 5.95204C23.6628 5.54144 23.9121 5.16834 24.2263 4.85411C24.5406 4.53984 24.9137 4.29058 25.3243 4.12054C25.7349 3.95049 26.1749 3.86297 26.6194 3.86301C27.067 3.86118 27.5102 3.95096 27.9218 4.12679Z"
                      fill="#6285FF"
                    />
                  </g>
                  <g opacity="0.3">
                    <path
                      d="M29.9999 12.3293V21.4171C29.9999 21.8612 29.9124 22.3011 29.7424 22.7114C29.5724 23.1218 29.3233 23.4946 29.0092 23.8087C28.6951 24.1228 28.3222 24.3719 27.9119 24.5419C27.5015 24.7119 27.0617 24.7994 26.6175 24.7994C26.1734 24.7994 25.7335 24.7119 25.3232 24.5419C24.9128 24.3719 24.5399 24.1228 24.2259 23.8087C23.9118 23.4946 23.6627 23.1218 23.4927 22.7114C23.3227 22.3011 23.2352 21.8612 23.2352 21.4171C23.2352 21.4171 23.2462 21.4939 23.2682 21.6402C23.2774 21.727 23.2945 21.8127 23.3194 21.8963C23.342 22.0121 23.3738 22.1258 23.4145 22.2366C23.6001 22.7979 23.9323 23.2994 24.3768 23.6892C24.8212 24.079 25.3618 24.3429 25.9425 24.4537C26.0633 24.4756 26.1767 24.4829 26.2974 24.4976L26.5023 24.5012H26.6377L26.6852 24.4939C26.9432 24.4805 27.1987 24.4362 27.4462 24.3622C27.9579 24.2037 28.4203 23.9164 28.7889 23.5277C28.9768 23.328 29.139 23.1057 29.2718 22.8659C29.3979 22.6227 29.4926 22.3646 29.5535 22.0976C29.5862 21.9677 29.6094 21.8356 29.623 21.7024L29.6377 21.4756V21.4171L29.6413 21.3765V21.2634C29.6413 20.9561 29.645 20.6484 29.6486 20.3451C29.6486 19.7415 29.6706 19.1415 29.6779 18.5671C29.7035 17.411 29.7438 16.3317 29.7913 15.4098C29.8791 13.5582 29.9999 12.3293 29.9999 12.3293Z"
                      fill="#6285FF"
                    />
                  </g>
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_189_79"
                    x1="26.6175"
                    y1="4.34451"
                    x2="26.6175"
                    y2="25.5531"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#6285FF" />
                    <stop offset="1" stopColor="#6285FF" />
                  </linearGradient>
                </defs>
              </svg>
              BrainCore
            </a>
            <h1 className="text-2xl">
                Siz hisobdan chiqdingiz! <NavLink className="text-blue-500" to="/login">qayta kirish</NavLink>
            </h1>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Logout;
