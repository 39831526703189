import { RouterProvider } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import router from './router';
import axios from "axios";
import './assets/css/global.css'


axios.defaults.baseURL = 'https://api1.timeschool.uz/'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token
} else {
  axios.defaults.headers.common['Authorization'] = ""
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);
