import React, { useState, useEffect } from "react";
import { NavLink, useLocation,useParams } from "react-router-dom";
import axios from "axios";

export default function ReceptionReports() {
const p = useParams();
  const [Wherelist, setWherelist] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [reports, setReports] = useState(null)
  const [students, SetStudents] = useState(null)

  useEffect(() => {

    axios
    .get("leads/reports/reception/" +p['slug'] + "/" )
    .then((response) => {
      setReports(response.data.report);
      SetStudents(response.data.students);
      console.log(response.data.students);
    });
  }, [useLocation()]);

  function getDateReport(e){
    e.preventDefault();
    let data = new FormData(e.target);
    console.log(data.get("beginned_data"))
    console.log(data.get("finished_data"))
    axios
    .get(
      "leads/reports/reception/" +p['slug'] + "/",
      {params: {
        "beginned_data": data.get("beginned_data"),
        "finished_data": data.get("finished_data"),
      }})
    .then((response) => {
      setReports(response.data.report);
      SetStudents(response.data.students);
    });
  }

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5 gap-2 mt-5">
        <div className="bg-[var(--theme-sidebar)] p-5  rounded-[25px]">
          <h1
            className="text-2xl text-[var(--theme)]"
            style={{ textAlign: "left" }}
          >
            Tanlash
          </h1>
          <br />
          <br />
          <br />
          <form action="" onSubmit={getDateReport}>
            <div className="grid lg:grid-cols-2">
              <div>
                <h3 className="text-xl ml-2">Boshlanish vaqti</h3>
                <input 
                  type="date"
                  name="beginned_data"
                  placeholder="Boshlanish"
                  className="input"
                  required/>
              </div>
              <div>
                <h3 className="text-xl ml-2">Tugash vaqti</h3>
                <input
                  type="date"
                  name="finished_data"
                  placeholder="Boshlanish"
                  className="input" 
                  required/>
              </div>
            </div>
            <br />
            <br />
            <button 
              type="submit"
              className="bg-[var(--theme)] hover:opacity-60 text-white font-bold py-2 px-4 rounded-lg">
              Ma'lumotlarni olish
            </button>
          </form>
        </div>
        <div className="bg-[var(--theme-sidebar)] p-5 rounded-[25px]">
          <div className="flex justify-between">
            <h1 className="text-2xl text-[var(--theme)]">
              Qo'shilgan O'quvchilar
            </h1>
            <h1 className="text-2xl">{reports?.overall_students}ta</h1>
          </div>
          <br />
          <div className="max-h-[200px] w-full overflow-x-auto mt-5">
            {reports?.subject_reports.map(item => (
              <div className="flex justify-between m-3  flex-wrap border-b-2">
                <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                  {item.title}
                </h3>
                <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                  {item.count}ta
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-container bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
        <br />
        <br />
        <br />
        <div className="table-horizontal-container">
          <table className="table-border td-min-width">
            <thead>
              <tr>
                <th></th>
                <th>&#8470;</th>
                <th>Ism Familya</th>
                <th>Fan</th>
                <th><pre>Telefon raqami</pre></th>
                <th><pre>   Qachon qo'shilgan   </pre></th>
                <th>Kim qo'shdi</th>
                <th>Izoh</th>
              </tr>
            </thead>
            <tbody>
              {students?.map((item, index) => (
                <tr>
                  <td></td>
                  <td>{index + 1}</td>
                  <td><span className="whitespace-nowrap">{item.full_name}</span></td>
                  <td>
                    <ul>
                      <li>{item?.subject?.title}</li>
                      <li>{item?.grade?.title}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li><span className="whitespace-nowrap">{item.phone}</span></li>
                      <li><span className="whitespace-nowrap">{item.parent_phone}</span></li>
                    </ul>
                  </td>
                  <td>{item.created_at ? 
                             new Date(item.created_at)
                              .toLocaleString([], {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })
                            :
                            'Vaqt belgilanmagan'
                      }
                  </td>
                  <td>
                    <NavLink
                        to=""
                        className="bg-[var(--theme)] whitespace-nowrap text-white  p-2 px-3 rounded-full"
                      >
                      <i className="fa-solid fa-user-secret mx-1"></i>
                      {item?.created_by?.name}
                    </NavLink>
                  </td>
                  <td>{item.comment ? item.comment : "Izoh yo'q"}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
