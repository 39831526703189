import React from "react";
import { useParams } from "react-router-dom";

export default function Failed(params) {
    const p = useParams();
    const errorStatus = p.errorStatus
  return (
    <div className="center2">
        <div className="flex h-full justify-evenly items-center flex-col">
            <div className="loader error" >
                <div className="field"></div>
                <div className="field"></div>
                <div className="field"></div>
                <div className="field"></div>
            </div>
            <h1 className="text-5xl">{errorStatus} Xato!</h1>

        </div>
    </div>
  );
}
