import React, {useState,useEffect} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom"

export default function DeletedObjects() {
  
  const [deletedObject, setDeletedObject] = useState([])
  const [type,setType] = useState(1)

  useEffect(()=>{
    axios
    .get('leads/deleted/objects/',{params:{type: type }})
    .then((res) => {
      setDeletedObject(res.data)
    })
  },[useLocation(), type])

  function restorElement(id){
    console.log({id: id, type: type})
    axios
    .put('leads/deleted/objects/',{id: id, type: type})
    .then((res) => {
      setDeletedObject(deletedObject.filter(item => item.id !== id))
    })
  }

  function htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  return (
    <>
      <div className="bg-[var(--theme-sidebar)] rounded-3xl items-start gap-3 p-6 flex flex-wrap">
      <div className="flex flex-wrap gap-5">
            <div 
              onClick={()=> setType(1)}
              className={`p-2 rounded-lg hover:opacity-80 cursor-pointer ${type===1 ? "bg-[var(--oppocity-bg)] text-[var(--theme-bg)]": 'bg-[var(--theme-bg)] text-[var(--theme-color)]'}`}>
                <i className="fa-solid fa-graduation-cap  mx-2 text-xl"></i>
                O'chirilgan o'quvchilar
            </div>
            <div 
              onClick={()=> setType(0)}
              className={`p-2 rounded-lg hover:opacity-80 cursor-pointer  ${type===0 ? "bg-[var(--oppocity-bg)] text-[var(--theme-bg)]": 'bg-[var(--theme-bg)] text-[var(--theme-color)]'}`}>
                <i className="fa-regular fa-clipboard-user mx-2 text-xl"></i>
                O'chirilgan Ishchilar
            </div>
            <div 
              onClick={()=> setType(2)}
              className={`p-2 rounded-lg hover:opacity-80 cursor-pointer  ${type===2 ? "bg-[var(--oppocity-bg)] text-[var(--theme-bg)]": 'bg-[var(--theme-bg)] text-[var(--theme-color)]'}`}>
                <i className="fa-regular fa-clipboard-user mx-2 text-xl"></i>
                O'chirilgan Guruhlar
            </div>
        </div>
        <br /><br />
        <br />
      </div>
      <div className="table-container bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
                <div className="table-horizontal-container">
                    <table className='table-border td-min-width'>
                        <thead>
                          <tr>
                            <th>&#8470;</th>
                            <th>Nomi</th>
                            <th>O'chirilish sababi</th>
                            <th>O'chirilgan sana</th>
                            <th>Kim tomonidan O'chirildi</th>
                            <th>Qayta tiklash</th>
                          </tr>
                        </thead>
                        <tbody>
                        {deletedObject?.map((item, index) => {
                                  const date =  new Date(item.deleted_date)
                                  let month = ['Yan', 'Feb', 'Mar', 'Apr', 'May', 'Iyun', 'Iyul','Avg', 'Sen', 'Okt','Noy', 'Dekabr']
                                  const visibledate = `${date.getDate()}  ${month[date.getMonth()]} ${ date.getFullYear()} `
                                  return (
                                    <tr key={index}>
                                      <td style={{width: "unset"}}>{index + 1})</td>
                                      <td><span className="truncate">{item.item}</span></td>
                                      <td><span className="truncate">{item.deleted_reason}</span></td>
                                      <td>
                                        <span className="truncate">
                                        {visibledate} {date.toLocaleString([], {
                                                      hour: '2-digit',
                                                      minute: '2-digit',
                                                      hour12: false,
                                                  })}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          to=""
                                          className="bg-[var(--theme)] text-white truncate rounded-full"
                                          style={{padding: "10px", margin: "15px"}}
                                        >
                                          <i className="fa-solid fa-user-secret mx-1"></i>
                                          {item.deleted_by}
                                        </span>
                                      </td>
                                      <td>
                                        <div>
                                          <span 
                                            style={{padding: "10px", margin: "15px"}}
                                            onClick={()=>restorElement(item.id)}
                                            className="bg-[var(--red)] text-white truncate mt-3 rounded-full cursor-pointer">
                                            <i className="fa-light fa-rotate-left mx-1"></i>
                                            Qayta tiklash
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                  )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
    </>
  );
}
