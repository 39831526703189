
export default function Notification(header,text, alertType, delay) {
    const newDiv = document.createElement("div");
    const newContent = document.createTextNode("");
    newDiv.classList.add("control");
    newDiv.innerHTML = `
    <div role="alert " class="bg-[var(--theme-sidebar)]">
    <div class="bg-[${alertType}] text-white  font-bold rounded-t px-4 py-2">
        <div class="flex justify-between">
          <span>${header}</span>          
          <svg onclick="removeAlert()" class="fill-current h-6 w-6 text-white" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </div>
      </span>
      </div>
      <div class="border border-t-0 border-[${alertType}] rounded-b bg-[var(--theme--sidebar)] px-4 py-3 text-[var(--theme-color)]">
        <p>${text}.</p>
      </div>
    </div>
    `;
  newDiv.appendChild(newContent);
  const currentDiv = document.getElementById("div1");
  document.querySelector(".notification").insertBefore(newDiv, currentDiv);
  setTimeout(() => {
    newDiv.classList.add("fade-right");
    setTimeout(() => {
      newDiv.remove();
    }, delay);
  }, 100);

}