import axios from "axios";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Notification from "../../components/alert";
import { rootPath } from "../../utils/main"

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut} from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BigAvatar = styled(Avatar)(({ theme }) => ({
  width: 150,
  height: 150,
}));

function visibleNumber(value) {
  var n = parseInt(value.replaceAll(/\D/g, ""), 10);
  if (n) {
    return n.toLocaleString().replaceAll(",", " ");
  } else {
    return "";
  }
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const useStyles = makeStyles({
    paper: {
      backgroundColor: "var(--theme-color)",
    },
  });
const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data1 = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [15, 14, 13, 12, 11, 10],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [10, 11, 12, 13, 14, 15],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export default function Profile(params) {
  const [condition, setCondition] = useState(true);
  const [requestUser, setRequestUser] = useState(null);
  const [teacherDetails, setTeacherDetails] = useState(null);
  const [budgetList, setBudgetList] = useState([]);
  const [reports, setReports] = useState({});
  const [optionAvatar, setOptionAvatar] = useState([])

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [isSend, setIsSend] = useState(false);
  const [errors, setError] = useState('');

  const [pieData, setPieData] = useState({
    labels: ["Hisobda qolgan mablag'", "Jarima", "Avans", "Mukofat pullari"],
    datasets: [
      {
        label: "Narxi: ",
        data: [0, 0, 0, 0],
        backgroundColor: ["#F7AE01", "#D23F3F","#7C90DB" ,"#7CBBA1"],
        borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  });



  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(" ", "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }


  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    
    axios.get("accounts/user/profile/").then((res) => {
      setRequestUser(res.data.user);
      setTeacherDetails(res.data.teacher_detail);
      setBudgetList(res.data.recent_action.objects);
      setReports(res.data.recent_action.reports);
      setOptionAvatar(res.data.recent_action.option_avatar);
    });
    }, [useLocation()]);
    useEffect(() => {
        setPieData({
            labels: ["Hisobga qolgan mablag'", "Jarima", "Avans", "Mukofat pullari"],
            datasets: [
                {
                    label: "Narxi: ",
                    data: Object.keys(reports).map(k => reports[k]),
                    backgroundColor: ["#F7AE01", "#D23F3F","#7CBBA1", "#7C90DB" ],
                    borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
                    borderWidth: 1,
                },
            ],
        });
    }, [reports]);

    function changePicture(item){
      
        axios
        .post("accounts/user/profile/", {id: item[0]} )
        .then((res) => {
            setRequestUser({...requestUser, avatar_select: item[0], avatar_select_display: item[1]})
            document.querySelector('#avatar img').src = rootPath + item[1] 
            Notification(
                "Mufaqiyatli amalga oshdi",
                "Rasm almashdi",
                "var(--green)",
                5000
                )
        });
        
    }
    function visiblephoneKeyup(value) {
        var y = value.replaceAll("+998", "").replaceAll(" ", "");
        var x = y.replaceAll(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
        if (y > 0) {
            return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
            x[3] ? " " + x[3] : ""
          }${x[4] ? " " + x[4] : ""}`;
        } else {
          return "";
        }
      }

    function handleForm(e){
        e.preventDefault();
        let data = new FormData(e.target);
        axios
        .put("accounts/user/profile/", {
            name: data.get('name'),
            surname: data.get('surname'),
        } )
        .then((res) => {
            setRequestUser({...requestUser, name: data.get('name'), surname: data.get('surname')})
            console.log()
            Notification(
                "Mufaqiyatli amalga oshdi",
                "Ma'lumotlar yangilandi",
                "var(--green)",
                5000
                )
        });
    }

    function handlePhone(e){
        e.preventDefault();
        let data = new FormData(e.target);
        if (!isSend){
            axios
            .patch("accounts/user/profile/", {
                phone: data.get('phone'),
            } )
            .then((res) => {
                setIsSend(true)
                e.target.reset()
                Notification(
                    "Mufaqiyatli amalga oshdi",
                    res.data.response,
                    "var(--green)",
                    5000
                    )
            })
            .catch(function (error) {
              console.log(error.response.status)
                if (error.response.status === 400){
                    setIsSend(true)
                    e.target.reset()
                }
                Notification(
                    "Xato",
                    error.response.data.response,
                    "var(--red)",
                    5000
                )
            })
        }
        else{
            axios
            .post("accounts/user/profile/verification/", {
                otp: data.get('otp'),
            } )
            .then((res) => {
                setIsSend(false)
                e.target.reset()
                setRequestUser({
                    ...requestUser,
                    phone: res.data.phone
                })
                Notification(
                    "Mufaqiyatli amalga oshdi",
                    "Telefon raqam yangilandi",
                    "var(--green)",
                    5000
                    )
            })
            .catch(function (error) {
                Notification(
                    "Xato",
                    error.response.data.response,
                    "var(--red)",
                    5000
                )
            })
        }
    }

    function changePassword(e){
      e.preventDefault();
      let data = new FormData(e.target);
      let form = {
        old_password: data.get('old_password'),
        new_password: data.get('new_password'),
        confirm_password: data.get('confirm_password'),
      }
      axios
      .post("accounts/user/password/change", form)
      .then(res => {
        e.target.reset()
        Notification(
          "Mufaqiyatli amalga oshdi",
          "Parol yangilandi",
          "var(--green)",
          5000
          )
      })
      .catch(error => {
        setError(error.response.data)
      })
    }

return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="xl:col-span-5 lg:col-span-12 col-span-12  bg-[var(--theme-sidebar)]  rounded-3xl px-3 py-5">
          <center>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              onClick={()=> {setModal(true); setModalType(0)}}
              badgeContent={
                <span>
                  <div className="h-[45px] w-[45px] box-content ml-3 mt-3 flex items-center justify-center border-4 border-[var(--theme-sidebar)] rounded-full bg-[var(--theme)]">
                    <i className="fa-regular fa-pen-nib text-2xl h-[30px] w-[30px] text-[var(--theme-sidebar)]"></i>
                  </div>
                </span>
              }
            >
              <BigAvatar
                alt="Travis Howard"
                src={
                  requestUser?.avatar_select_display &&
                  rootPath + requestUser?.avatar_select_display
                }
              />
            </Badge>
          </center>
          <br />
          <br />
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 gap-5">
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
              <i className="fa-solid fa-user text-xl mx-3"></i>
              <ul>
                <li>Ism & familiya</li>
                <li>
                  <span>
                    {requestUser?.name} {requestUser?.surname}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
              <i className="fa-solid fa-phone text-xl mx-3"></i>
              <ul>
                <li>Telefon raqami</li>
                <li>
                  <span>
                    <a href={`tel:${requestUser?.phone}`}>
                      {visiblephoneKeyup(`${requestUser?.phone}`)}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            {requestUser?.percent ? (
              <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
                <i className="fa-solid fa-coins text-xl mx-3"></i>
                <ul>
                  <li>Ish Foizi</li>
                  <li>
                    <span>{requestUser?.percent} %</span>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
                <i className="fa-solid fa-percent text-xl mx-3"></i>
                <ul>
                  <li>Ish Maoshi</li>
                  <li>
                    <span>{visibleNumber(`${requestUser?.absolute_salary}`)}</span>
                  </li>
                </ul>
              </div>
            )}
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
              <ul>
                <li>Ishchi huquqi</li>
                <li>
                  <div className="flex flex-wrap gap-2">
                    {requestUser?.who.map((w, index) => {
                      const arr = [
                        "Admin",
                        "Manager",
                        "Cashier",
                        "Tacher",
                        "Manager",
                        "Call operator"
                      ];
                      return (
                        <span
                          key={`who-${index}`}
                          className="py-1 px-2 rounded-full bg-[var(--oppocity-bg)] text-[var(--theme-bg)] text-sm"
                        >
                          {arr[parseInt(w)]}
                        </span>
                      );
                    })}
                  </div>
                </li>
              </ul>
            </div>
            {requestUser?.who.includes("3") && (
              <>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
                  <i className="fa-solid fa-people-roof text-xl mx-3"></i>
                  <ul>
                    <li>Sinflar soni</li>
                    <li>
                      <span>{teacherDetails.all_groups}</span>
                    </li>
                  </ul>
                </div>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
                  <i className="fa-solid fa-graduation-cap text-xl mx-3"></i>
                  <ul>
                    <li>O'quvchilar soni</li>
                    <li>
                      <span>{teacherDetails.all_students}</span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <br />
          <br />
          <div className="flex flex-wrap gap-5 ">
            <button 
              onClick={(e) => {setModalType(2); setModal(true);}}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">
              <i className="fa-solid fa-lock mx-2"></i>
              Parolni o'zgartirish
            </button>
            <button 
                onClick={(e) => {setModalType(1); setModal(true);}}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full">
              <i className="fa-solid fa-phone mx-2"></i>
              Telefon raqamni o'zgartirish
            </button>
          </div>
          <br />
        </div>
        <div className="xl:col-span-7 lg:col-span-12 col-span-12  bg-[var(--theme-sidebar)]  rounded-3xl px-3 pt-5">
          <div className="flex flex-wrap gap-5 m-5">
            <div
              onClick={() => setCondition(true)}
              className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
                condition && "active"
              }`}
            >
              <i className="fa-regular fa-list mx-2 text-xl"></i>
              Oylik hisobot
            </div>
            <div
              onClick={() => setCondition(false)}
              className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
                !condition && "active"
              }`}
            >
              <i className="fa-duotone fa-calendar mx-2 text-xl"></i>
              Yillik hisobot
            </div>
          </div>
          {condition ? (
            <div className="max-h-[400px] flex md:justify-center">
              <Doughnut data={pieData} />
            </div>
          ) : (
            <div className="max-h-[600px] w-full md:flex md:justify-center">
              {/* <Line options={options} data={data1} /> */}
              <br /><br />
              <h1 className="text-4xl">Tez orada qo'shiladi</h1>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
      <div className="bc-card">
        <br />
        <h1 className="text-4xl mx-3">So'ngi hodisalar</h1>
        <br />
        <div className="table-container">
          <div className="table-horizontal-container">
            <table className="table-information">
              <tbody>
                {budgetList?.map((item, index) => {
                  const WHY_GIVEN = ["advance", "punishment", "bonus"];
                  const type_of_tAction = [
                    "Avans olindi",
                    "Jarima solindi",
                    "Mukofat puli",
                  ];
                  return {
                    0: (
                      <tr key={index} className="outcome">
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.title}</span>
                          <br />
                          <a href="">{item.reason}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>..</span>
                        </td>
                        <td>
                          <span>Chiqim</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                        <td>
                          <div className="flex p-5 gap-5 justify-center">
                            <div
                              className="button"
                              onClick={function () {
                                setModal(true);
                                setModalType(0);
                               
                              }}
                            >
                              {item.id}
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.625 6.125L4.375 6.12501"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.375 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.625 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                    1: (
                      <tr key={index} className={WHY_GIVEN[item.why_given]}>
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.people_name}</span>
                          <br />
                          <a href={`tel: {people_name}`}>
                            {item.people_phone}
                          </a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>{item.reason}</span>
                        </td>
                        <td>
                          <span>{type_of_tAction[item.why_given]}</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                      </tr>
                    ),
                    2: (
                      <>
                            <tr key={index} className="payment">
                              <td>{index + 1})</td>
                              <td>
                                <span>{item.people_name}</span>
                                <br />
                                <a href={`tel: {people_name}`}>
                                  {item.people_phone}
                                </a>
                              </td>
                              <td>
                                <span>{item.created_at}</span>
                                <br />
                                <span>{item.payment_month} uchun</span>
                              </td>
                              <td>
                                <span>Oylik To'lov</span>
                                <br />
                                <span>
                                  {visibleNumber(`${item.money_with_discount}`)} 
                                  {item.discount > 0 ? (
                                    <strong className="text-[var(--red)]">
                                      {" "}-{" "} {item.discount}%
                                    </strong>
                                  ) : (
                                    item.discount_absolute > 0 && (
                                      <strong className="text-[var(--red)]">
                                        {" "}-{" "} {visibleNumber(
                                          `${item.discount_absolute}`
                                        )}
                                      </strong>
                                    )
                                  )}
                                </span>
                              </td>
                            </tr>   
                      </>
                    ),
                  }[item["list_type"]];
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
          {
            {
              0: (
                <form className="content" onSubmit={handleForm}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">Profilni tahrirlash</h3>
                    <div className="flex gap-3">
                      <button
                        className="button"
                        type="reset"
                        onClick={() => {
                          setModal(false);
                          setModalType(0);
                        }}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid xl:grid-cols-2  gap-5">
                    <div className="flex gap-5 xl:col-span-2 flex-wrap items-center">
                        {optionAvatar.map((item, index) => {
                            return (
                                <div
                                    key={`avatar-${index}`}
                                    className={requestUser?.avatar_select === item[0] ? 'bg-[var(--theme)] rounded-md': 'p-1' }>
                                    <img 
                                        onClick={() => changePicture(item)}
                                        className={requestUser?.avatar_select === item[0] ? "h-[80px] w-auto" : "h-[60px]"} 
                                        key={`img-${index}`} 
                                        src={`${rootPath}${item[1]}`} 
                                        alt="" 
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <input 
                        type="text"
                        className="input"
                        name="name"
                        defaultValue={requestUser?.name}
                        placeholder="ISM"
                        required
                        />
                    <input
                        type="text"
                        className="input"
                        name="surname"
                        defaultValue={requestUser?.surname}
                        placeholder="Familya"
                        required
                        />
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      title="xabar berish"
                      className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Tahrirlash
                    </button>
                  </div>
                </form>
              ),
              1: (
                <form className="content" onSubmit={handlePhone}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Raqamni tahrirlash</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                        setModalType(0);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                    {!isSend ?
                        <input 
                            type="text"
                            className="input"
                            name="phone"
                            onChange={(e) => phoneKeyup(e)}
                            defaultValue={!isSend ? visiblephoneKeyup(`${requestUser?.phone}`) : ''}
                            placeholder="Telefon raqam"
                            required
                            />
                        :
                        <input
                            type="text"
                            className="input"
                            name="otp"
                            placeholder="Tasdiqlash kodi"
                            defaultValue=""
                            required
                        />                    
                    }
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'zgartirish
                  </button>
                </div>
              </form>
              ),
              2:(
                <form className="content" onSubmit={changePassword}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Raqamni tahrirlash</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                        setModalType(0);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                    <p className="text-[var(--red)]">{Object.keys(errors).map(k => errors[k])}</p>
                    <input
                      className={`input font-xl ${errors.hasOwnProperty("old_password") && "border-[var(--red)] border-2 border-solid"}`}
                      type="password"
                      name="old_password"
                      placeholder="Eski parol" 
                      required
                      />
                    
                    <br /><br />
                    <input
                      className={`input ${errors.hasOwnProperty("new_password") && "border-[var(--red)] border-2 border-solid"}`}
                      type="password"
                      name="new_password"
                      placeholder="Yangi parol"
                      required
                      />
                    <input
                      className={`input ${errors.hasOwnProperty("new_password") && "border-[var(--red)] border-2 border-solid"}`}
                      type="password"
                      name="confirm_password"
                      placeholder="Yangi parolni tasdiqlang"
                      required
                      />
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Jo'natish
                  </button>
                </div>
              </form>
              )
            }[modalType]
          }
        </div>
    </>
  );
}
