import React from 'react';
import groupCardStyle from '../../assets/css/groupCardStyle.module.css';
import WeekDays from './weekdays';
import { NavLink } from "react-router-dom";

export default function Group(props) {


    return (
        <div 
            className={`${groupCardStyle.card} ${props.active && groupCardStyle.active}`}>
            <div className="flex justify-between items-center mx-2 mt-4">
                <input type="checkbox"
                    onClick={(event) => props.handleClick(event, props.item)}
                    checked={props.active}
                    className="accent-[var(--theme)] mx-1 w-[22px] h-[22px] text-blue-600  border-gray-300 rounded-full" />
                <h1 className='text-2xl truncate'>
                    {props.item?.title}
                </h1>
                <NavLink to={`/groups/${props.item?.id}`}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z"
                            stroke="var(--theme)" strokeWidth="3" strokeMiterlimit="10" />
                        <path d="M18.8521 22.7734L23.625 18.0005L18.8521 13.2275" stroke="var(--theme)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.375 18H23.625" stroke="var(--theme)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </NavLink>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 27C10.433 27 12 25.433 12 23.5C12 21.567 10.433 20 8.5 20C6.567 20 5 21.567 5 23.5C5 25.433 6.567 27 8.5 27Z" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M23.5 11.9995C25.433 11.9995 27 10.4325 27 8.49951C27 6.56652 25.433 4.99951 23.5 4.99951C21.567 4.99951 20 6.56652 20 8.49951C20 10.4325 21.567 11.9995 23.5 11.9995Z" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.5 11.9995C10.433 11.9995 12 10.4325 12 8.49951C12 6.56652 10.433 4.99951 8.5 4.99951C6.567 4.99951 5 6.56652 5 8.49951C5 10.4325 6.567 11.9995 8.5 11.9995Z" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.5 20V19C8.50002 18.2044 8.81609 17.4413 9.37868 16.8787C9.94128 16.3162 10.7043 16.0001 11.4999 16.0001L20.5001 15.9999C21.2957 15.9999 22.0587 15.6838 22.6213 15.1213C23.1839 14.5587 23.5 13.7956 23.5 13V12" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.5 12V20" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <h2 className="text-lg truncate">{props.item?.subject?.title}</h2>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28 26H4V6" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M28.0002 12L20.0002 19L12.0002 13L4.00024 20" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>


                <h2 className="text-lg truncate">{props.item?.grade?.title}</h2>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 20C14.5899 20 17.5 17.0899 17.5 13.5C17.5 9.91015 14.5899 7 11 7C7.41015 7 4.5 9.91015 4.5 13.5C4.5 17.0899 7.41015 20 11 20Z" stroke="var(--theme-color)" strokeWidth="2" strokeMiterlimit="10" />
                    <path d="M19.4265 7.24215C20.3205 6.99026 21.2582 6.93287 22.1762 7.07386C23.0943 7.21486 23.9715 7.55095 24.7487 8.0595C25.526 8.56805 26.1852 9.23726 26.682 10.022C27.1789 10.8068 27.5018 11.689 27.629 12.6091C27.7562 13.5291 27.6847 14.4658 27.4194 15.3559C27.1541 16.2461 26.7012 17.069 26.0911 17.7694C25.481 18.4697 24.7278 19.0312 23.8825 19.416C23.0371 19.8008 22.119 19.9999 21.1902 20" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.99951 24.6746C3.01468 23.2306 4.36238 22.052 5.92882 21.2384C7.49527 20.4248 9.23448 20.0001 10.9996 20C12.7648 19.9999 14.504 20.4246 16.0705 21.238C17.637 22.0515 18.9848 23.23 20.0001 24.6739" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.1902 20C22.9555 19.9987 24.6951 20.4228 26.2617 21.2364C27.8284 22.05 29.1759 23.2291 30.1902 24.6739" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <h2 className="text-lg truncate">{props.item?.students_count}</h2>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="var(--theme-color)" strokeWidth="2" strokeMiterlimit="10" />
                    <path d="M16 9V16H23" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <h2 className="text-lg truncate">{props.item?.start_time.slice(0,5)}-{props.item?.end_time.slice(0,5)}</h2>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z" stroke="var(--theme-color)" strokeWidth="2" strokeMiterlimit="10" />
                    <path d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <h2 className="text-lg truncate">{props.item?.teacher?.name} {props.item?.teacher?.surname}</h2>
            </div>
            <div className="flex justify-between mx-2 mt-4">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12L16 4L31 12L16 20L1 12Z" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M23.5 30V16L16 12" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M27.5 13.8667V20.6818C27.5004 20.8975 27.4307 21.1075 27.3013 21.2802C26.4592 22.4007 22.9066 26.5 16 26.5C9.09339 26.5 5.54077 22.4007 4.69869 21.2802C4.56932 21.1075 4.49959 20.8975 4.5 20.6818V13.8667" stroke="var(--theme-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>



                <h2 className="text-lg truncate">{props.item?.all_lesson_days}</h2>
            </div>
            <br />
            <WeekDays days={props.item?.days} />
        </div>
    )
};
