import React, { useState, useEffect } from "react";
import studentStyles from "../../../assets/css/reception.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {getDate} from "../../../utils/main"
import axios from "axios";


export default function Students() {


    const [comments, setComments] = useState()
    const [commentType, setCommentType] = useState(null)

    useEffect(()=> {
        axios
            .get("main/comments/get/")
            .then(res => {
                console.log(res.data)
                setComments(res.data)
            })

    }, [useLocation()])

    useEffect(()=> {
        if (commentType){
            axios
                .get("main/comments/get/", {params: {type: commentType.id}})
                .then(res => {
                    setComments(res.data)
                })
            }
        else{
            axios
                .get("main/comments/get/")
                .then(res => {
                    setComments(res.data)
                })

        }

    }, [commentType])


    function phoneKeyup(phone) {
      var y = phone.replaceAll("+998 ", "").replaceAll(" ", "");
      var x = y
        .replaceAll(/\D/g, "")
        .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
      if (y > 0) {
        return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
          x[3] ? " " + x[3] : ""
        }${x[4] ? " " + x[4] : ""}`;
      } else {
        return "";
      }
    }



 
  return (
    <div className={studentStyles.main_page}>
      <div className="br-card">
        <br />
        <div>
        <Autocomplete
                    className=" input w-[350px]"
                      onChange={(event, newValue) => {
                        setCommentType(newValue);
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        {
                          id: 0,
                          title: "Qabulxona"
                        },
                        {
                          id: 1,
                          title: "Davomat"
                        },
                        {
                          id: 2,
                          title: "To'lov"
                        },
                        {
                          id: 3,
                          title: "Taklif"
                        },
                        {
                          id: 4,
                          title: "Shikoyat"
                        },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                    //   classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required label="Izoh turi" />
                      )}
                    />
        </div>
        <div className={studentStyles.table_cap}>
          <table className="td-min-width-150">
            <thead>
              <tr>
                <th width="10px">
                </th>
                <th>Ism & familya</th>
                <th>
                  <div className="whitespace-nowrap mx-1">Telefon raqami</div>
                </th>
                <th>Izoh turi</th>
                <th>Izoh</th>
                <th>qachon yaratildi</th>
              </tr>
            </thead>
            <tbody>
                {comments?.map((comment, index) => {
                    const type_color = ["bg-[var(--yellow)]", "bg-[var(--red)]", "bg-[var(--purple)]", "bg-[var(--green)]","bg-[var(--oppocity-bg)]"]
                    const text = ["Qabulxona","Davomat", "To'lov", "Taklif", "Shikoyat"]
                    return (
                        <tr key={index}>
                            <td style={{minWidth: "20px"}}> <span className="text-2xl pl-2">{index +1})</span></td>
                            <td>{comment?.student?.full_name}</td>
                            <td>
                                <ul>
                                  <li>
                                    <div className="whitespace-nowrap mx-1">{phoneKeyup(comment?.student?.phone)}</div>
                                  </li>
                                  <li>
                                    <div className="whitespace-nowrap mx-1">{phoneKeyup(comment?.student?.parent_phone)}</div>
                                  </li>
                                </ul>
                            </td>
                            <td><div className={`status_bar ${type_color[comment.type_comment]}`}>{text[comment.type_comment]}</div></td>
                            <td>{comment.comment}</td>
                            <td>{getDate(comment.created_at)}</td>
                        </tr>
                    )
                    
                })}
            </tbody>
          </table>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}


