import React from "react";
import { useRouteError } from "react-router-dom";


export default function ErrorPage(params) {
  const error = useRouteError();
  return (
    <div className="center2">
        <div className="flex h-full justify-evenly items-center flex-col">
            <div className="loader error" >
                <div className="field"></div>
                <div className="field"></div>
                <div className="field"></div>
                <div className="field"></div>
            </div>
            <h1 className="text-5xl">404 Xato</h1>

        </div>
    </div>
  );
}
