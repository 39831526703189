import React, {useEffect, useState} from 'react';

export default function AttendanceDrop(props) {

    const [color, setColor] = useState('var(--green)')

    function handleChange(e){
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('color');
        setColor(option)
        props.getID(e.target.value)
    }
    useEffect(() => {
        console.log(typeof(props.value))
        const colors = ["var(--green)", "var(--red)", "var(--yellow)", "var(--oppocity-bg)"]
        if (props.value !== null) {
            setColor(colors[parseInt(props.value)])
            console.log(props.value)
        }
        else{
            setColor("")
        }
        
    }, [props.value])

    return (
        <div className={`status_bar bg-[${color}]`}>
            <select 
                name="" id="" 
                className={`w-[80%] m-1 text-black text-center rounded-lg outline-none`}
                onChange={handleChange}
            >
                {props.value == "NaN"? 
                    <>
                        <option selected hidden color="var(--dark)">----</option>
                        <option selected={props.value == "0"} value="0" color="var(--green)">Keldi</option>
                        <option selected={props.value == "1"} value="1" color="var(--red)">Kelmadi</option>
                        <option selected={props.value == "2"} value="2" color="var(--yellow)">Kech qoldi</option>
                        <option selected={props.value == "3"} value="3" color="[#505050]">Darsi yo'q</option>
                    </>
                    :
                    <>
                        <option selected={props.value == "0"} value="0" color="var(--green)">Keldi</option>
                        <option selected={props.value == "1"} value="1" color="var(--red)">Kelmadi</option>
                        <option selected={props.value == "2"} value="2" color="var(--yellow)">Kech qoldi</option>
                        <option selected={props.value == "3"} value="3" color="[#505050]">Darsi yo'q</option>
                    </>

                }

            </select>
        </div>
    )
};
