import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import axios from "axios";

export default function PaymentReports(params) {
  const [payments, setPayments] = useState([]);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);

  
  const [commentType, setCommentType] = useState(null)
  const [CSid, setCSid] = useState(null);
  const [comments, setComments] = useState([])


  useEffect(() => {
    axios.get("main/no-attended/students/").then((response) => {
      setPayments(response.data);
      setFilter(response.data);
    });
  }, [useLocation()]);

  useEffect(() => {
    if(date){
      console.log(`${date[1]}, ${date[0]}`)
      axios.get("main/no-attended/students/",
      {params:{
        "month": `${date[1]},${date[0]}`
      }}
      ).then((response) => {
        setPayments(response.data);
        setFilter(response.data);
      });
    }
  }, [date]);


  function allSelect(e) {
    if (e.target.checked) {
      setList(payments);
    } else {
      setList([]);
    }
  }
  function listChange(e, student) {
    if (e.target.checked) {
      setList([...list, student]);
    } else {
      setList(list.filter((item) => item.id !== student.id));
    }
  }


  function getComments(id) {
    axios.get(`main/comments/get/${id}/`).then((response) => {
      setComments(response.data)
      setModal(true);
      setModalType(5);
      setCSid(id)
    });
  }

  function AddComment(e){
    e.preventDefault();
    let data = new FormData(e.target);
    console.log(CSid, commentType, data.get("comment"))

    axios
    .post(
      `main/comments/get/${CSid}/`,
      {
        type_comment: `${commentType.id}`,
        comment: data.get("comment")
      }
    )
    .then((response) => {
      e.target.reset()
      setComments(response.data)
      setModal(true);
      setModalType(5);
    })
  }


  return (
    <>
      <div className="bc-card flex gap-5 flex-wrap justify-between">
        <div className="flex flex-wrap md:flex-nowrap  gap-5 items-center">
          <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                  views={["year", "month"]}
                  onChange={(e) => {
                  setDate([e.$M + 1, e.$y]);
                  }}
                  slotProps={{
                  textField: {
                      class: "input w-full",
                      name: "date",
                      required: true,
                      size: "small",
                      style: { width: "250px" },
                      variant: "standard",
                  },
                  }}
              />
              </LocalizationProvider>
          </div>
        </div>
        <div>
        <button
              onClick={function () {
                setModal(true);
                setModalType(0);
              }}
              className={`button text-[var(--yellow)]`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 7L16 18L4 7"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8184 16L4.30859 24.7174"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.6916 24.7175L18.1816 16"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
        </div>

      </div>
      <div className="bc-card">
        <div className="table-container bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
          <div className="table-horizontal-container">
            <table className="table-border td-min-width">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" onClick={allSelect} />
                  </th>
                  <th>&#8470;</th>
                  <th>Ism familya</th>
                  <th>Guruhi</th>
                  <th>Holati</th>
                  <th>Telefon raqami</th>
                  <th>Ota Onasi telefon raqami</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filter?.map((item, index) => (
                  <tr key={`payment-${index}`}>
                    <td>
                      <input
                        type="checkbox"
                        checked={list.includes(item)}
                        onClick={(e) => listChange(e, item)}
                      />
                    </td>
                    <td>{index + 1})</td>
                    <td>{item?.student?.full_name}</td>
                    <td>
                      <NavLink
                        to={`/groups/${item?.group?.id}`}
                        className="text-[var(--theme)] font-bold"
                      >
                        {item?.group?.title}
                      </NavLink>
                    </td>
                    <td>
                      <strong>
                        {item?.uncoming_days}
                      </strong>
                    </td>
                    <td>
                      <a href={`tel:${item?.student?.phone}`}>
                        {item?.student?.phone}
                      </a>
                    </td>
                    <td>
                      <a href={`tel:${item?.student?.parent_phone}`}>
                        {item?.student?.parent_phone}
                      </a>
                    </td>
                    <td>
                    <button
                          className="bg-[var(--green)] mx-1  text-white h-[40px] w-[40px] rounded-full"
                          onClick={(e) => getComments(item?.student?.id)}
                        >
                          <i className="fa-solid fa-comment"></i>
                        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={`modal ${modal && "active"}`}>
        {
          {
            // XABAR YOZISH
            0: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">
                    Tanlangan foydalanuvchilar: {list.length} ta{" "}
                  </h3>
                  <div className="flex gap-3">
                    <button className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--yellow)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <MultiAutocomplete
                    sx={{
                      border: "1px solid var(--yellow)",
                      outline: "var(--yellow)",
                    }}
                    color="var(--yellow)"
                    placeholder="O'quvchilar"
                    HandleChange={(event, value) => setList(value)}
                    value={list}
                    options={payments}
                  />
                  <textarea
                    name=""
                    rows={7}
                    className="textarea"
                    placeholder="Xabar yozish (majburiy)"
                    defaultValue="Assalomu alaykum [oquvchi] siz ushbu [guruh] uchun to'lov qilishni unutman"
                  ></textarea>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </div>
            ),
            5: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi uchun izohlar</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content block mt-5">
                  <div className="block">
                    <div>
                     
                    </div>
                  </div>

                </div>
                <br />
                <div className="h-full overflow-y-auto">
                  <div className="table-horizontal-container">
                    <table className="table-information">
                      <tbody>
                        {
                          comments?.map((comment, index) => {
                            const type = ["payment","punishment", "bonus", "advance", "outcome" ]
                            const type_color = ["bg-[var(--yellow)]", "bg-[var(--red)]", "bg-[var(--purple)]", "bg-[var(--green)]","bg-[var(--oppocity-bg)]"]
                            const text = ["Qabulxona","Davomat", "To'lov", "Taklif", "Shikoyat"]
                            return(
                              <tr key={`comment-${index}`} className={`${type[comment.type_comment]}`}>
                                <td className="w-[20px]">{index +1}</td>
                                <td>{comment.comment}</td>
                                <td><div className={`status_bar ${type_color[comment.type_comment]}`}>{text[comment.type_comment]}</div></td>
                              </tr>
                            )
                          })
                        }
                        
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="flex">
                  <button
                    title="xabar berish"
                    onClick={()=>{
                      setModal(true);
                      setModalType(6)
                    }}
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    boshqa izoh qo'shish
                  </button>
                </div>
              </div>
            ),
            6: (
              <form className="content" action="" onSubmit={AddComment}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Izoh qo'shish</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid">
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setCommentType(newValue);
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        {
                          id: 0,
                          title: "Qabulxona"
                        },
                        {
                          id: 1,
                          title: "Davomat"
                        },
                        {
                          id: 2,
                          title: "To'lov"
                        },
                        {
                          id: 3,
                          title: "Taklif"
                        },
                        {
                          id: 4,
                          title: "Shikoyat"
                        },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required label="Izoh turi" />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={4}
                      placeholder="Izoh"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Izohni qo'shish
                  </button>
                </div>
              </form>
            ),
          }[modalType]  
        }
      </div>
    </>
  );
}

const options = [
    {id:0, "title": "Chala To'lov"},
    {id:1, "title": "To'lov Qilmagan"}
]

const MultiAutocomplete = (props) => {
  return (
    <Autocomplete
      sx={{ m: 1 }}
      style={{ "--set-color": props.color, maxHeight: "250px", overflowY: 'auto' }}
      value={props.value}
      onChange={(event, value) => props.HandleChange(event, value)}
      multiple
      id="tags-standard"
      options={props.options}
      getOptionLabel={(option) => option.student.full_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) =>  (
        <MenuItem
          {...props}
          key={`sms-${option.id}}`}
          value={option.id}
          sx={{ justifyContent: "space-between" }}
        >
          {option.student.full_name}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.placeholder}
          placeholder={props.placeholder}
          fullWidth
        />
      )}
    />
  );
};