import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Notification from "../../components/alert";
import ErrorPage from "../../pages/error-page";
import { TextField } from "@mui/material";
import logo from "../../assets/img/braincore.png";
import Loader from "../../components/loader";
import Resizer from "react-image-file-resizer";
import { rootPath } from "../../utils/main"

export default function Settings(params) {


  const [subjects, setSubjects] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [settings, setSettings] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [superCreator, setSuperCreator] = useState(false);
  const [companies, setcompanies] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [factory, setFactory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resizedImage, setResizedImage] = useState('')

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const ResizeImage = async (event) => {
    console.log(event.target.files[0])
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      setResizedImage(image)
    } catch (err) {
      console.log(err);
    }
  };

  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(/\s/g, "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }
  function visiblephoneKeyup(value) {
    var y = value.replaceAll("+998", "").replaceAll(/\s/g, "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      return "";
    }
  }

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .all([
        axios.get("main/subject/"),
        axios.get("accounts/company/get"),
        axios.get("accounts/users/me/"),
      ])
      .then((res) => {
        setSubjects(res[0].data);
        setSettings(res[1].data);
        setSuperCreator(res[2].data.is_creator);
        if (!res[2].data.is_creator) {
          setIsLoading(true);
        }
      });
  }, [useLocation()]);

  useEffect(() => {
    if (superCreator) {
      axios
        .all([
          axios.get("accounts/company/serializer/"),
          axios.get("accounts/factory/serializer/"),
        ])
        .then((res) => {
          setcompanies(res[0].data);
          setFactory(res[1].data);
          setIsLoading(true);
        });
    }
  }, [superCreator]);

  function companyCreate(e) {
    if (superCreator) {
      e.preventDefault();
      let data = new FormData(e.target);
      let form = {
        title: data.get("title"),
        adress: data.get("adress"),
        contact_number: data.get("phone"),
        iframe: data.get("iframe"),
      };
      console.log(form);
      axios
        .post("accounts/company/serializer/", form)
        .then((res) => {
          setcompanies([...companies, res.data]);
          e.target.reset();
          Notification(
            "Mufaqiyatli amalga oshdi",
            "Filial Qo'shildi",
            "var(--green)",
            5000
          );
        })
        .catch((error) => {
          Notification(
            "Nimadir xato ketdi",
            "Iltimos keyiniroq urinib ko'ring",
            "var(--red)",
            5000
          );
        });
    }
  }
  function companyUpdate(e) {
    if (superCreator) {
      e.preventDefault();
      let data = new FormData(e.target);
      let form = {
        id: updateData.id,
        title: data.get("title"),
        adress: data.get("adress"),
        contact_number: data.get("phone"),
        iframe: data.get("iframe"),
      };
      console.log(form);
      axios
        .put("accounts/company/serializer/", form)
        .then((res) => {
          // setcompanies([...companies, res.data]);
          let list = companies;
          console.log(list);
          for (let i = 0; i < list.length; i++) {
            if (list[i].id === res.data.id) {
              list[i]["title"] = res.data.title;
              list[i]["phone"] = res.data.phone;
              list[i]["adress"] = res.data.adress;
              list[i]["iframe"] = res.data.iframe;
              console.log(list[i]);
            }
          }
          setcompanies(list);
          e.target.reset();
          Notification(
            "Mufaqiyatli amalga oshdi",
            "Filial yangilandi",
            "var(--green)",
            5000
          );
          setModal(false);
          setModalType(0);
        })
        .catch((error) => {
          Notification(
            "Nimadir xato ketdi",
            "Iltimos keyiniroq urinib ko'ring",
            "var(--red)",
            5000
          );
        });
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
  
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  }
  function factoryUpdate(e){
    e.preventDefault();
    let data = new FormData(e.target);
    const formData = new FormData();
    if(resizedImage){
      const file = dataURLtoFile(resizedImage, data.get('name') +'.png');
      formData.append('logo', file);
    }
    formData.append('name', data.get('name'));
    
    
    axios.put('/accounts/factory/serializer/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      setModal(false)
      setModalType(0)
      setFactory(response.data)
      Notification(
        "Mufaqiyatli amalga oshdi",
        "Kampaniya yangilandi",
        "var(--green)",
        5000
      );
      // Handle the response
    })
  }
  function changeSettings(e, type) {
    console.log(type);
    let obj = {};
    switch (type) {
      case 1:
        obj = {
          ...settings,
          penalty_for_attendance: e.target.checked,
        };
        setSettings({
          ...settings,
          penalty_for_attendance: e.target.checked,
        });
        break;
      case 2:
        obj = {
          ...settings,
          notifications_journal: e.target.checked,
        };
        setSettings({
          ...settings,
          notifications_journal: e.target.checked,
        });
        break;
      case 3:
        obj = {
          ...settings,
          notifications_attendance: e.target.checked,
        };
        setSettings({
          ...settings,
          notifications_attendance: e.target.checked,
        });
        break;
      case 4:
        obj = {
          ...settings,
          birthday_congratulations: e.target.checked,
        };
        setSettings({
          ...settings,
          birthday_congratulations: e.target.checked,
        });
        break;
      case 5:
        obj = {
          ...settings,
          sms_for_teacher: e.target.checked,
        };
        setSettings({
          ...settings,
          sms_for_teacher: e.target.checked,
        });
        break;
    }
    axios
      .put("accounts/company/get/", obj)
      .then((res) => {
        Notification(
          "Mufaqiyatli amalga oshdi",
          "Sozlamalar yangilandi",
          "var(--green)",
          5000
        );
      })
      .catch(function (error) {
        console.log(error);
        Notification(
          "Internet bilan muomo",
          "Nimadir ishlamayapdi",
          "var(--yellow)",
          5000
        );
      });
  }

  if (!errorPage) {
    const handleDelete = (e) => {
      setSelectedSubject(e);
      setModal(true);
      setModalType(0);
    };

    const deleteAgree = () => {
      console.log("deleteAgree");
      if (selectedSubject !== null) {
        axios
          .delete("main/subject/" + selectedSubject + "/")
          .then((res) => {
            console.log(res);
            Notification(
              "Mufaqiyatli amalga oshdi",
              "Ushbu element o'chirildi",
              "var(--green)",
              5000
            );
          })
          .catch((err) => {
            Notification(
              "Nimadir hato ketdi",
              "Keyinroq urinib ko'ring",
              "var(--red)",
              5000
            );
          });
        setSelectedSubject(null);
        setModal(false);
        setSubjects(subjects.filter((i) => i.id !== selectedSubject));
      }
    };

    return (
      <>
        {!isLoading ? (
          <Loader />
        ) : (
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <div>
              {superCreator && (
                <div className="bg-[var(--theme-sidebar)] mt-5 rounded-3xl gap-3 items-start p-6 flex flex-wrap">
                  <div className="flex w-full justify-between">
                    <h1 className="text-xl md:text-2xl">
                      O'quv markaz ma'lumotlari
                    </h1>
                    <button
                      onClick={() => {
                        setModal(true);
                        setModalType(3);
                      }}
                      className="button"
                    >
                      <svg
                        className="md:w-[28px] md:h-[28px] w-[24px] h-[24px]"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                          stroke="#4FB286"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.875 7L21 13.125"
                          stroke="#4FB286"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.4441 23.5691L4.43066 17.5557"
                          stroke="#4FB286"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="h-px bg-gray-200 border-0 dark:bg-gray-700 w-full">
                    {" "}
                  </div>
                  <div className="flex w-full flex-wrap items-center justify-between">
                    <div className="flex gap-5 items-center">
                      <img
                        src={`${
                          factory?.logo
                            ? rootPath + factory?.logo
                            : logo
                        }`}
                        alt=""
                        width={"60px"}
                      />
                      <div>
                        <h1 className="text-xl">{factory?.name}</h1>
                        <h1 className="text-xl">{factory?.expire_date}</h1>
                      </div>
                    </div>
                    <div>
                      <h1 className="text-xl">{factory?.country}</h1>
                      <h1 className="text-xl">{factory?.region}</h1>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className="bg-[var(--theme-sidebar)] rounded-3xl items-start gap-3 p-6 flex flex-wrap">
                <NavLink
                  to="/settings/deleted"
                  className="bg-[var(--red)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--red)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid fa-trash-can mx-2"></i>
                  Chiqitdon
                </NavLink>
                <NavLink
                  to="/settings/reports/receptions/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid fa-bullhorn  mx-2"></i>
                  Qabulxona hisoboti
                </NavLink>
                <NavLink
                  to="/settings/payments/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-sharp fa-solid fa-sack mx-2"></i>
                  Oylik to'lov
                </NavLink>
                <NavLink
                  to="/settings/not-attend-students/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-sharp fa-regular fa-calendar-circle-user mx-2"></i>
                  Kelmayotgan o'quvchilar
                </NavLink>
                <NavLink
                  to="/settings/todays/lessons/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid fa-face-sleeping mx-2"></i>
                  Bugungi davomatlar
                </NavLink>
                <NavLink
                  to="/settings/reports/promocodes/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid fa-square-terminal mx-2"></i>
                  Promokodlar
                </NavLink>
                <NavLink
                  to=""
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid fa-message-sms mx-2"></i>
                  Avto Xabarlar
                </NavLink>
                <NavLink
                  to="/settings/allcomments/"
                  className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                >
                  <i className="fa-solid mx-2 fa-comments"></i>
                  Fikrlar
                </NavLink>
                {superCreator && (
                   <NavLink
                   to="/settings/report/budgets/"
                   className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] mt-2 hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full"
                 >
                   <i class="fa-solid  mx-2  fa-burger-soda"></i>
                   barcha filiallarni budget hisoboti
                 </NavLink>
                )}
              </div>
              {/* <div className="bg-[var(--theme-sidebar)] mt-5 rounded-3xl gap-3 items-start p-6 flex flex-wrap">
                <div className="flex w-full justify-between">
                  <h1 className="text-xl md:text-3xl">Sozlamalar</h1>
                </div>
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-2xl">
                    <i className="fa-solid fa-face-sleeping mx-2"></i>
                    Davomat jarimalari
                  </h1>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings?.penalty_for_attendance}
                      className="sr-only peer"
                      onClick={(e) => changeSettings(e, 1)}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[var(--theme)] dark:peer-focus:ring-[var(--theme)] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--theme)]"></div>
                  </label>
                </div>
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-2xl">
                    <i className="fa-duotone fa-marker mx-2"></i>
                    Jurnal xabar berish
                  </h1>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings?.notifications_journal}
                      className="sr-only peer"
                      onClick={(e) => changeSettings(e, 2)}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[var(--theme)] dark:peer-focus:ring-[var(--theme)] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--theme)]"></div>
                  </label>
                </div>
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-2xl">
                    <i className="fa-solid fa-bell-on mx-2"></i>
                    Davomatni xabar berish
                  </h1>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings?.notifications_attendance}
                      className="sr-only peer"
                      onClick={(e) => changeSettings(e, 3)}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[var(--theme)] dark:peer-focus:ring-[var(--theme)] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--theme)]"></div>
                  </label>
                </div>
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-2xl">
                    <i className="fa-solid fa-cake-candles mx-2"></i>
                    Tug'ilgan kun tabriklari
                  </h1>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings?.birthday_congratulations}
                      className="sr-only peer"
                      onClick={(e) => changeSettings(e, 4)}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[var(--theme)] dark:peer-focus:ring-[var(--theme)] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--theme)]"></div>
                  </label>
                </div>
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-2xl">
                    <i className="fa-solid fa-envelope mx-2"></i>
                    O'qituvchi xabar jonatishi
                  </h1>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      defaultChecked={settings?.sms_for_teacher}
                      className="sr-only peer"
                      onClick={(e) => changeSettings(e, 5)}
                    />

                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[var(--theme)] dark:peer-focus:ring-[var(--theme)] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--theme)]"></div>
                  </label>
                </div>
              </div> */}
            </div>
            <div>
              {superCreator && (
                <>
                  <div className="bg-[var(--theme-sidebar)] rounded-3xl gap-3 items-start p-6 flex flex-wrap">
                    <div className="flex w-full justify-between">
                      <h1 className="text-xl md:text-3xl">
                        Filiallar Ro'yhati
                      </h1>
                      <button
                        onClick={() => {
                          setModal(true);
                          setModalType(1);
                        }}
                        className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full items-center flex"
                      >
                        <i className="fa-regular text-xl md:text-3xl fa-circle-plus mx-2"></i>
                        <span className="hidden md:inline-block">
                          Filiallar Qo'sish
                        </span>
                      </button>
                    </div>
                    <div className="max-h-[200px] w-full overflow-x-auto mt-5">
                      {companies.map((item, index) => (
                        <div
                          key={`factory-${index}`}
                          className="flex w-full justify-between items-center"
                        >
                          <h1 className="text-xl md:text-2xl ">
                            <i className="fa-solid fa-school text-xl md:text-2xl m-2"></i>
                            {item.title}
                          </h1>
                          <div className="flex px-5 py-2 gap-5 justify-center">
                            <button
                              onClick={() => {
                                setModal(true);
                                setModalType(2);
                                setUpdateData(item);
                              }}
                              className="button"
                            >
                              <svg
                                className="md:w-[28px] md:h-[28px] w-[24px] h-[24px]"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.875 7L21 13.125"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.4441 23.5691L4.43066 17.5557"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div className="bg-[var(--theme-sidebar)] rounded-3xl gap-3 items-start p-6 flex flex-wrap">
                <div className="flex w-full justify-between">
                  <h1 className="text-xl md:text-3xl">Fanlar Ro'yhati</h1>
                  <NavLink
                    to="/settings/subject/create"
                    className="bg-[var(--theme)] hover:bg-[var(--theme-bg)] hover:text-[var(--theme)] text-white text-lg py-2 px-4 rounded-full items-center flex"
                  >
                    <i className="fa-regular text-xl md:text-3xl fa-circle-plus mx-2"></i>
                    <span className="hidden md:inline-block">Fan qo'shish</span>
                  </NavLink>
                </div>
                <div className="max-h-[300px] w-full overflow-x-auto mt-5">
                  {subjects?.map((subject) => {
                    return (
                      <div
                        key={subject.id}
                        className="flex w-full justify-between items-center"
                      >
                        <h1 className="text-xl md:text-2xl ">
                          <i className="fa-solid text-xl md:text-3xl fa-flask m-2"></i>
                          {subject.title}
                        </h1>
                        <div className="flex px-5 py-2 gap-5 justify-center">
                          <NavLink
                            to={`/settings/subject/edit/${subject.id}`}
                            className="button"
                          >
                            <svg
                              className="md:w-[28px] md:h-[28px] w-[24px] h-[24px]"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                                stroke="#4FB286"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.875 7L21 13.125"
                                stroke="#4FB286"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.4441 23.5691L4.43066 17.5557"
                                stroke="#4FB286"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </NavLink>
                          <button
                            className="button"
                            onClick={() => handleDelete(subject.id)}
                          >
                            <svg
                              className="md:w-[28px] md:h-[28px] w-[24px] h-[24px]"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.625 6.125L4.375 6.12501"
                                stroke="var(--red)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.375 11.375V18.375"
                                stroke="var(--red)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16.625 11.375V18.375"
                                stroke="var(--red)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                                stroke="var(--red)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                                stroke="var(--red)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={`modal ${modal && "active"}`}>
              {
                {
                  0: (
                    <div className="content">
                      <div className="body-content flex flex-col items-center">
                        <svg
                          width="237"
                          height="235"
                          viewBox="0 0 237 235"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                            stroke="#FFCC00"
                            strokeWidth="4"
                          />
                          <path
                            d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                            stroke="#2D2D2B"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M119.5 73.4375V124.844"
                            stroke="#FFCC00"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                            fill="#FFCC00"
                            stroke="#FFCC00"
                            strokeWidth="10"
                          />
                          <path
                            d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                            stroke="#FFCC00"
                            strokeWidth="3"
                          />
                          <path
                            d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                            stroke="#FFCC00"
                            strokeWidth="3.5"
                          />
                        </svg>
                        <h1 className="text-4xl">Ogohlantirish</h1>
                        <br />
                        <h3 className="text-2xl width-50 text-center">
                          Siz rostan ham shuni o'chirmoqchimisiz?
                        </h3>
                      </div>
                      <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                        <button
                          onClick={() => setModal(false)}
                          className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                          Ortga qaytish
                        </button>
                        <button
                          onClick={deleteAgree}
                          className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                          O'chirish
                        </button>
                      </div>
                    </div>
                  ),
                  1: (
                    <form className="content" onSubmit={companyCreate}>
                      <div className="header flex justify-between align-center">
                        <h3 className="text-2xl ml-3">Filial qo'shish</h3>
                        <div className="flex gap-3">
                          <button
                            className="button"
                            type="reset"
                            onClick={() => {
                              setModal(false);
                              setModalType(0);
                            }}
                          >
                            <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                          </button>
                        </div>
                      </div>
                      <div className="body-content">
                        <div className="grid grid-cols-2 gap-5">
                          <div className="input">
                            <TextField
                              className="w-full"
                              type="text"
                              label="nomi"
                              size="small"
                              name="title"
                              autoComplete="off"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full "
                              type="text"
                              label="Adress"
                              size="small"
                              name="adress"
                              autoComplete="off"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full "
                              onChange={(e) => phoneKeyup(e)}
                              type="text"
                              label="Telefon raqami"
                              size="small"
                              autoComplete="off"
                              name="phone"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full"
                              multiline
                              rows={2}
                              type="text"
                              label="IFRAME"
                              size="small"
                              autoComplete="off"
                              name="iframe"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          title="xabar berish"
                          className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                          xabar berish
                        </button>
                      </div>
                    </form>
                  ),
                  2: (
                    <form className="content" onSubmit={companyUpdate}>
                      <div className="header flex justify-between align-center">
                        <h3 className="text-2xl ml-3">Filial qo'shish</h3>
                        <div className="flex gap-3">
                          <button
                            className="button"
                            type="reset"
                            onClick={() => {
                              setModal(false);
                              setModalType(0);
                            }}
                          >
                            <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                          </button>
                        </div>
                      </div>
                      <div className="body-content">
                        <div className="grid grid-cols-2 gap-5">
                          <div className="input">
                            <TextField
                              className="w-full"
                              type="text"
                              label="nomi"
                              size="small"
                              name="title"
                              defaultValue={updateData?.title}
                              autoComplete="off"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full "
                              type="text"
                              label="Adress"
                              size="small"
                              name="adress"
                              defaultValue={updateData?.adress}
                              autoComplete="off"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full "
                              onChange={(e) => phoneKeyup(e)}
                              type="text"
                              label="Telefon raqami"
                              defaultValue={visiblephoneKeyup(
                                `${updateData?.contact_number}`
                              )}
                              size="small"
                              autoComplete="off"
                              name="phone"
                              required
                            />
                          </div>
                          <div className="input">
                            <TextField
                              className="w-full"
                              multiline
                              rows={2}
                              type="text"
                              label="IFRAME"
                              defaultValue={updateData?.iframe}
                              size="small"
                              autoComplete="off"
                              name="iframe"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          title="xabar berish"
                          className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                          xabar berish
                        </button>
                      </div>
                    </form>
                  ),
                  3: (
                    <form className="content" onSubmit={factoryUpdate}>
                      <div className="header flex justify-between align-center">
                        <h3 className="text-2xl ml-3">Kampaniyani tahrirlash</h3>
                        <div className="flex gap-3">
                          <button
                            className="button bg-[var(--theme-bg)]"
                            type="reset"
                            onClick={() => {
                              setModal(false);
                              setModalType(0);
                            }}
                          >
                            <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                          </button>
                        </div>
                      </div>
                      <div className="body-content">
                        <div className="flex justify-center">
                          <input 
                          type="file" 
                          name="logo" 
                          accept="image/*" 
                          className="hidden"
                          id="id_logo"
                          onChange={ResizeImage}
                          />
                          <label htmlFor="id_logo">
                            <center>
                            <div
                              className="bg_image"
                              style={{
                                backgroundImage: `url(${
                                  resizedImage !== ""
                                    ? resizedImage
                                    :  factory?.logo ? rootPath + factory?.logo :  logo
                                })`,
                              }}></div>
                              <h3>Rasmni o'zgartirish uchun bosing</h3>

                            </center>
                          </label>
                        </div>
                        <div className="grid ">
                          <div className="input">
                            <TextField
                              className="w-full"
                              type="text"
                              label="nomi"
                              size="small"
                              name="name"
                              defaultValue={factory?.name}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          title="xabar berish"
                          className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                          xabar berish
                        </button>
                      </div>
                    </form>
                  ),
                }[modalType]
              }
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <ErrorPage />;
  }
}
