import React, {useEffect, useState} from 'react';

export default function AttendanceDrop(props) {

    const [color, setColor] = useState('var(--green)')
    const [value, setValue] = useState(null)

    function handleChange(e){
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('color');
        setColor(option)
        props.getID(e.target.value)
    }
    useEffect(() => {
        const colors = ["var(--green)", "var(--red)", "var(--yellow)"]
        if (props.value !== null) {
            setColor(colors[parseInt(props.value)])
            console.log(props.value)
        }
        else{
            setColor("")
        }
        
    }, [props.value])

    return (
        <div className={`status_bar bg-[${color}]`}>
            <select 
                name="" id="" 
                className={`w-[80%] m-1 text-black text-center rounded-lg outline-none`}
                onChange={handleChange}
            >
                {props.value === null &&
                    <option selected  hidden color="var(--dark)">----</option>
                }
                    <>
                        <option selected={props.value === "0"} value="0" color="var(--green)">Keldi</option>
                        <option selected={props.value === "1"}  value="1" color="var(--red)">Kelmadi</option>
                        <option selected={props.value === "2"} value="2" color="var(--yellow)">Sababli kelmadi</option>
                    </>

            </select>
        </div>
    )
};
