import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDate, visibleNumber } from "../../../utils/main";
import axios from "axios";

export default function Promocodes() {
  const [promocodes, setPromocodes] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [editedObj, setEditedObj] = useState(null);
  const [deletedObj, setDeletedObj] = useState(null);
  
  useEffect(() => {
    axios.get("budget/promocodes/serializer/").then((response) => {
      setPromocodes(response.data);
    });
  }, [useLocation()]);
  function changePrice(e){
    if(e.target.value){
      if(e.target.value.includes('%')){
        e.target.value = e.target.value.replace('%', '') + '%';
        if(e.target.value.length > 3 ){
            e.target.value = e.target.value?.toLocaleString().slice(0, -1);
        }
      }    
      else  if (e.target.value.length < 12) {
          console.log()
          var n = parseInt(e.target.value.replaceAll(/\D/g, ''), 10);
          if (n) {
              e.target.value = n.toLocaleString().replaceAll(',', ' ' );
          } else {
              e.target.value = ""
          }
      } else {
          e.target.value = e.target.value.toLocaleString().slice(0, -1);
      }
    }
  }

  function createPromocode(e){
    e.preventDefault();
    let data = new FormData(e.target);
    let sender = {
        title: data.get('title'),
        slug: data.get('slug'),
        who: data.get('who'),
        to_date: data.get('to_date'),
        discount_absolute:  data.get('discount').includes("%") ? 0 : parseInt(data.get('discount').replaceAll(' ', '')),
        discount_percent: data.get('discount').includes("%") ? data.get('discount').replace('%', '') : 0

    }
    axios
        .post("budget/promocodes/serializer/", sender)
        .then(res => {
            e.target.reset()
            setModal(false)
            
            setPromocodes([...promocodes, res.data])
        })
  }
  function updatePromocode(e){
    e.preventDefault();
    let data = new FormData(e.target);
    let sender = {
        id: editedObj.id,
        title: data.get('title'),
        slug: data.get('slug'),
        who: data.get('who'),
        to_date: data.get('to_date'),
        discount_absolute:  data.get('discount').includes("%") ? 0 : parseInt(data.get('discount').replaceAll(' ', '')),
        discount_percent: data.get('discount').includes("%") ? data.get('discount').replace('%', '') : 0

    }
    console.log(sender)
    axios
        .put("budget/promocodes/serializer/", sender)
        .then(res => {
            e.target.reset()
            setModal(false)
            let list = promocodes;
            for (let i = 0; i < list.length; i++) {
            if (list[i]["id"] === res.data.id) {
                list[i]= res.data;
            }
            }
            
            setPromocodes(list)
        })
  }

  function deletePromocode() {
    axios
      .delete("budget/promocodes/serializer/", { data: { id: deletedObj } })
      .then((res) => {
        setPromocodes(promocodes.filter((item) => item.id !== deletedObj));
        setModal(false);
        setModalType(0);
      });
  }

  return (
    <>
      <div className="bg-[var(--theme-sidebar)] p-5  rounded-[25px]">
        <br />
        <button 
            onClick={() => {setModalType(0); setModal(true)}}
            className="bg-[var(--theme)] text-white text-xl font-bold py-2 px-4 rounded-full">
          <i className="fa-solid fa-folder-plus mx-3"></i>
          Yangi Promokod qo'shish
        </button>
        <br />
        <br />
        <div className="w-full overflow-x-auto">
          <div className="table-horizontal-container">
            <table className="table-border td-min-width">
              <thead>
                <tr>
                  <th></th>
                  <th>&#8470;</th>
                  <th>Kodi</th>
                  <th>Nomi</th>
                  <th>Chegirma</th>
                  <th>Yaratilgan sana</th>
                  <th>Amal qilish muddati</th>
                  <th>Kim tomonidan berilgan</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {promocodes?.map((item, index) => (
                  <tr>
                    <td></td>
                    <td>{index + 1}</td>
                    <td>{item.slug}</td>
                    <td>{item.title}</td>
                    <td>
                      {item.discount_percent
                        ? item.discount_percent + " %"
                        : visibleNumber(item.discount_absolute ? item.discount_absolute : 0)}
                    </td>
                    <td>{item.created_at && getDate(item?.created_at)}</td>
                    <td>{item.to_date && getDate(item?.to_date, false)}</td>
                    <td>
                      {
                        {
                          0: "O'qituvchi tomonidan",
                          1: "O'quv markaz tomonidan",
                          2: "O'rtada",
                        }[item.who]
                      }
                    </td>
                    <td> 
                        <i
                            onClick={() => {
                                setEditedObj(item)
                                setModal(true);
                                setModalType(1);
                            }}
                            className="fa-solid fa-pen text-2xl cursor-pointer text-[var(--green)] mx-3"
                        ></i>
                        <i
                            onClick={() => {
                                setDeletedObj(item.id)
                                setModal(true);
                                setModalType(2);
                            }}
                            className="fa-solid fa-trash-can mx-3 text-2xl cursor-pointer text-[var(--red)]"
                        ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <form className="content" onSubmit={createPromocode}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Yangi Promokod qo'shish</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                    <div className="grid lg:grid-cols-2">
                        <div>
                            <input
                                type="text"
                                name="title"
                                required
                                placeholder="Promokod nomi"
                                className="input"
                            />
                            <p></p>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="slug"
                                maxLength={10}
                                placeholder="Promokod kodi (Majburiy emas )"
                                className="input"
                            />
                            <p className="text-[12px] mx-2 text-[var(--red)]">Agar bo'sh bolsa o'zi avtomatik ravishga yaratadi</p>
                        </div>
                        <input
                            type="text"
                            name="discount"
                            placeholder="Chegirma"
                            onChange={changePrice}
                            className="input"
                            required
                        />
                        <input 
                            type="date"
                            name="to_date"
                            placeholder="Sana"
                            className="input"
                            />

                        <select name="who" className="input" id="">
                            <option value="1">O'quv markaz tomonidan</option>
                            <option value="0">O'qituvchi tomonidan</option>
                            <option value="2">O'rtada</option>
                        </select>

                    </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </form>
            ),
            1: (
              <form className="content" onSubmit={updatePromocode}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Promokodni Tahrirlash</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                        setEditedObj(null)
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                    <div className="grid lg:grid-cols-2">
                        <div>
                            <input
                                type="text"
                                name="title"
                                placeholder="Promokod nomi"
                                className="input"
                                defaultValue={editedObj?.title}
                                required
                            />
                            <p></p>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="slug"
                                placeholder="Promokod kodi (Majburiy emas )"
                                className="input"
                                maxLength={10}
                                defaultValue={editedObj?.slug}
                            />
                            <p className="text-[12px] mx-2 text-[var(--red)]">Agar bo'sh bolsa o'zi avtomatik ravishga yaratadi</p>
                        </div>
                        <input
                            type="text"
                            name="discount"
                            placeholder="Chegirma"
                            onChange={changePrice}
                            className="input"
                            required
                            defaultValue={editedObj?.discount_percent
                                ? editedObj?.discount_percent + " %"
                                : visibleNumber(`${editedObj?.discount_absolute}`)}
                        />
                        <input 
                            type="date"
                            name="to_date"
                            placeholder="Sana"
                            className="input"
                            defaultValue={editedObj?.to_date}
                            />

                        <select name="who" className="input" id="">
                            <option value="1">O'quv markaz tomonidan</option>
                            <option value="0">O'qituvchi tomonidan</option>
                            <option value="2">O'rtada</option>
                        </select>

                    </div>
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </form>
            ),
            2: (
                <div className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz rotan ham ushbu toplamni ochiqmoqchimisiz
                    <br />
                    <strong>Unutmang! keyin qaytarib bo'lmaydi</strong>
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={function () {
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    onClick={deletePromocode}
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </div>
            )
          }[modalType]
        }
      </div>
    </>
  );
}
