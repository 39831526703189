import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { visibleNumber } from "../../utils/main";
import { Autocomplete, TextField } from "@mui/material";
import Notification from "../../components/alert"
import WeekDays from "../../components/teacher/weekdays";
import { rootPath } from "../../utils/main"
import axios from "axios";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
  multilineColor: {
    color: "red",
  },
});

export default function GetPayment(params) {
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);

  const [detail, setDetail] = useState(null);
  const [detail_group, setDetail_group] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [history, setHistory] = useState([]);
  const [promocodeActive, setPromocodeActive] = useState(false);
  const [promocodeDetail, setPromocodeDetail] = useState(null);
  const [whoGives, setWhoGives] = useState(null);
  const [discount, setDiscount] = useState("0");
  const [payed, setPayed] = useState(null);
  const [total_sum, setTotal_sum] = useState(null);
  const [type, setType] = useState(null);
  const [date, setDate] = useState([]);
  const [lessonDays, setLessonDays] = useState(null);
  const [canPay, setCanPay] = useState(null);
  const [reciept, setReciept] = useState(null);
  const [deletedElement ,setDeletedElement] = useState(null);

  const routerParams = useParams();

  useEffect(() => {
    axios.get("budget/payment/get/student/" + routerParams.slug).then((res) => {
      setDetail(res.data.student);
      setGroupOptions(res.data.groups);
    });
  }, [useLocation()]);
  

  useEffect(() => {
    if (detail_group) {
      setTotal_sum(
        numberChange(
          `${parseInt(
            detail_group.student_must_pay_days !== null
              ? (detail_group.group.grade.price *
                  detail_group.student_must_pay_days) /
                  detail_group.lesson_days
              : 0
          )}`
        )
      );
      axios
        .get("budget/payment/get/student/" + routerParams.slug, {
          params: {
            group_id: detail_group?.group?.id,
          },
        })
        .then((res) => {
          setDetail(res.data.student);
          setGroupOptions(res.data.groups);
          setHistory(res.data.history);
        });
    }
  }, [detail_group]);

  useEffect(() => {
    if (discount && total_sum) {
      if (discount.includes("%")) {
        let discount1 = parseInt(discount.replaceAll(/\s/g, ""));
        let sum = parseInt(total_sum.replaceAll(/\s/g, ""));
        setPayed(String((sum * (100 - discount1)) / 100));
      } else {
        setPayed(
          String(total_sum.replaceAll(/\s/g, "")) -
            parseInt(discount.replaceAll(/\s/g, ""))
        );
      }
    }
  }, [total_sum, discount]);

  useEffect(() => {
    if (payed) {
      if (total_sum) {
        if (
          parseInt(String(payed).replaceAll(/\s/g, "")) + 10000 >
          parseInt(total_sum.replaceAll(/\s/g, ""))
        ) {
          setPayed(total_sum);
          console.log("i");
        }
      }
      if (payed.length < 12) {
        var n = parseInt(
          String(payed).replaceAll(/\s/g, "").replaceAll(/\D/g, ""),
          10
        );
        console.log(n);
        if (n) {
          setPayed(n.toLocaleString().replaceAll(",", " "));
        } else {
          setPayed("");
        }
      } else {
        setPayed(payed.toLocaleString());
      }
    }
  }, [payed]);

  function changePrice(e) {
    setDiscount(e.target.value);
    if (e.target.value) {
      if (e.target.value.includes("%")) {
        e.target.value = e.target.value.replace("%", "") + "%";
        if (e.target.value.length > 3) {
          e.target.value = e.target.value?.toLocaleString().slice(0, -1);
        }
      } else if (e.target.value.length < 12) {
        console.log();
        var n = parseInt(e.target.value.replaceAll(/\D/g, ""), 10);
        if (n) {
          e.target.value = n.toLocaleString().replaceAll(",", " ");
          setDiscount(n.toLocaleString().replaceAll(",", " "));
        } else {
          e.target.value = "";
        }
      } else {
        e.target.value = e.target.value.toLocaleString().slice(0, -1);
      }
    }
    setDiscount(e.target.value);
  }

  function callKeyup(e) {
    if (e.target.value.length < 12) {
      var n = parseInt(e.target.value.replaceAll(/\D/g, ""), 10);
      if (n) {
        e.target.value = n.toLocaleString().replaceAll(",", " ");
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = e.target.value.toLocaleString().slice(0, -1);
    }
  }

  function CheckPromocode(value) {
    axios
      .get("budget/promocode/check/", {
        params: {
          slug: value,
          student_id: routerParams.slug,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          setPromocodeDetail(res.data);
          setPromocodeActive(true);
          setWhoGives(
            discountType.filter((item) => item.id === res.data.who)[0]
          );

          setDiscount(
            res.data.discount_percent
              ? res.data.discount_percent + " %"
              : visibleNumber(
                  res.data.discount_absolute ? res.data.discount_absolute : 0
                )
          );
        } else {
          setPromocodeActive(false);
          setDiscount("0");
        }
      })
      .catch((err) => {
        setPromocodeActive(false);
        setDiscount("0");
      });
  }
  function changeNumber(num) {
    var n = parseInt(String(num).replaceAll(/\D/g, ""), 10);
    if (n) {
      return n.toLocaleString().replaceAll(",", " ");
    } else {
      return "0";
    }
  }
  function numberChange(value) {
    if (parseInt(value) < 0){
      value = value.replace("-", "")
      console.log(value);
      var n = parseInt(value.replaceAll(/\D/g, ""), 10);
      if (n) {
        return "-" + n.toLocaleString().replaceAll(",", " ");
      } else {
        return "";
      }
    }
    else{
      var n = parseInt(value.replaceAll(/\D/g, ""), 10);
      if (n) {
        return n.toLocaleString().replaceAll(",", " ");
      } else {
        return "";
      }
    }
  }

  function createMoney(e) {
    e.preventDefault();
    let data = new FormData(e.target);

    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let form = {
      date: `${month.indexOf(data.get("date").split(" ")[0]) + 1} ${
        data.get("date").split(" ")[1]
      }`,
      group: detail_group.group.id,
      type: type.id,
      who: whoGives.id,
      money: data.get("money"),
      discount_absolute: discount.includes("%")
        ? null
        : discount.replaceAll(/\s/g, ""),
      discount_percent: 
        discount.includes("%")
          ? discount.replaceAll(/\s/g, "").replace("%", "")
          : 0
      ,
      promocode: promocodeActive && data.get("promocode"),
      money_with_discount: data.get("total_money"),
    };
    axios
      .post("budget/payment/get/student/" + routerParams.slug, form)
      .then((res) => {
        setReciept(res.data);
        setModal(true);
      })
      .catch((err) => {
        if (err.response.data["error_money"]){
          Notification(
            "XATO !",
            err.response.data["error_money"],
            'var(--yellow)',
            5000
        )
        }
      })
    console.log(form);
  }

  function deleteObj(){
    console.log(deletedElement)
    axios
    .delete(`budget/payment/delete/${deletedElement.id}/`)
    .then((respone) => {
      window.location.reload()
    })
    .catch(function (error) {
      Notification(
        "Internet bilan muomo",
        "Nimadir ishlamayapdi",
        "var(--yellow)",
        5000
      );
    });
  }

  useEffect(() => {
    console.log(date);
    if (detail_group && date[0] && date[1]) {
      let year = date[1];
      let month = date[0];
      console.log(year, month);
      axios
        .get("budget/calc/days/", {
          params: {
            month: month,
            year: year,
            group_id: detail_group.group.id,
            student_id: routerParams.slug,
          },
        })
        .then((res) => {
          if (res.data) {
            setLessonDays(res.data);
            console.log(res.data)
            if (res.data.must_payed > 0) {
              setTotal_sum(numberChange(`${parseInt(res.data.must_payed)}`));
              setPayed(numberChange(`${parseInt(res.data.must_payed)}`));
              setCanPay(true);
            } else {
              setCanPay(false);
              setTotal_sum("0");
            }
          } else {
            setTotal_sum("0");
            setLessonDays(null);
          }
        });
    }
  }, [date, detail_group]);

  function PrintReciept(){
    let tag = document.getElementById('reciept')
    if(document.getElementById('link')){
        document.getElementById('link').remove()
    }
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style>.table-print > *{font-size:small}}</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<center>');
    mywindow.document.write(tag.innerHTML);
    mywindow.document.write('</center>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(function() {
        mywindow.print();
        mywindow.close();
    }, 1000);

    setTimeout(function() {
      window.location.reload();
    }, 2000)
  }
  return (
    <>
      <div
        className="grid lg:grid-cols-2 gap-[30px] p-5 lg:p-[40px]"
        style={{ minHeight: "unset" }}
      >
        <div className="bg-[var(--theme-sidebar)] p-5 rounded-[25px]">
          <form
            onSubmit={createMoney}
            className="grid 2xl:grid-cols-2 xl:grid-cols-1 gap-4"
          >
            <LocalizationProvider className="w-full" dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                onChange={(e) => {
                  setDate([e.$M + 1, e.$y]);
                }}
                slotProps={{
                  textField: {
                    class: "input w-full",
                    name: "date",
                    required: true,
                    size: "small",
                    style: { width: "100%" },
                    variant: "standard",
                  },
                }}
              />
            </LocalizationProvider>
            <div className="input">
              <Autocomplete
                onChange={(event, newValue) => {
                  setDetail_group(newValue);
                }}
                value={detail_group}
                disablePortal
                id="combo-box-demo"
                options={groupOptions}
                isOptionEqualToValue={(option, value) => value.id === option.id}
                getOptionLabel={(option) => option.group.title}
                classes={{ paper: classes.paper }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size="small"
                    variant="standard"
                    placeholder="Guruh"
                  />
                )}
              />
            </div>

            {canPay && (
              <>
                <div className="input">
                  <Autocomplete
                    onChange={(event, newValue) => setType(newValue)}
                    value={type}
                    disablePortal
                    id="combo-box-demo"
                    options={moneyType}
                    style={{ width: "100%" }}
                    classes={{ paper: classes.paper }}
                    isOptionEqualToValue={(option, value) =>
                      value.id === option.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        size="small"
                        variant="standard"
                        placeholder="To'lov turi"
                      />
                    )}
                  />
                </div>
                <div className="input">
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setWhoGives(newValue);
                    }}
                    disablePortal
                    disabled={promocodeActive}
                    value={whoGives}
                    isOptionEqualToValue={(option, value) =>
                      value.id === option.id
                    }
                    id="combo-box-demo"
                    options={discountType}
                    style={{ width: "100%" }}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        variant="standard"
                        placeholder="Chegirma kim tomonidan"
                      />
                    )}
                  />
                </div>
                <input
                  className="input"
                  value={total_sum}
                  onChange={(e) => {
                    callKeyup(e);
                    setTotal_sum(e.target.value);
                  }}
                  placeholder="Summa"
                  required
                  name="money"
                  type="text"
                />
                <input
                  className={`input mr-5 border-1  ${
                    promocodeActive
                      ? "border-[var(--green)]"
                      : "border-[var(--red)]"
                  }`}
                  style={{ width: "100%", borderWidth: "2px" }}
                  type="text"
                  name="promocode"
                  onChange={(e) => CheckPromocode(e.target.value)}
                  placeholder="Promokode"
                />
                <input
                  style={{ width: "100%" }}
                  type="text"
                  className="input"
                  disabled={promocodeActive}
                  value={discount}
                  onChange={changePrice}
                  required
                  name="discount"
                  placeholder="Chegirma"
                />

                <input
                  className="input"
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="Olingan pul"
                  name="total_money"
                  value={payed}
                  required
                  onChange={(e) => {
                    setPayed(e.target.value);
                  }}
                />

                <div className="2xl:col-span-2 xl:col-span-1 2xl:flex-nowrap flex-wrap flex gap-5 my-1 items-center justify-between">
                  <button className="bg-[var(--theme)] hover:opacity-50 justify-center flex items-center my-1 rounded-[15px] p-2 px-[20px]">
                    <span className="text-xl text-[var(--theme-sidebar)]">
                      Qabul qilish
                    </span>
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
        <div className="bg-[var(--theme-sidebar)] p-5 rounded-[25px]">
          <div className="grid 2xl:grid-cols-2 xl:grid-cols-1 gap-4">
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i className="fa-duotone fa-user text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Ism familya
                  </span>
                </li>
                <li>
                  <span className="text-lg">{detail?.full_name}</span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i className="fa-solid fa-coins text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Hisobdagi mablag'
                  </span>
                </li>
                <li>
                  <span className="text-lg">{changeNumber(`${detail?.funds}`)}</span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i class="fa-sharp fa-solid fa-file-signature text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Guruh nomi
                  </span>
                </li>
                <li>
                  <span className="text-lg">{detail_group?.group.title}</span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i class="fa-regular fa-graduation-cap text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Guruh darajasi
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                    {detail_group?.group?.grade.title}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i class="fa-regular fa-person-chalkboard text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Guruh O'qituvchisi
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                  {detail_group?.group?.teacher?.name} {detail_group?.group?.teacher?.surname}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]"> 
              <i class="fa-solid fa-clock-desk text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Guruh vaqti
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                    {detail_group?.group?.start_time.slice(0, -3)} : {detail_group?.group?.end_time.slice(0, -3)}
                  </span>
                </li>
              </ul>
            </div>
            <div
              className={`${
                lessonDays?.money ? "bg-[var(--green)]" : "bg-[var(--red)]"
              } flex items-center rounded-[15px] p-4 px-[20px]`}
            >
              <i className="fa-duotone fa-user text-2xl mx-3 text-bold text-white border-r-2 pr-2 border-white"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-white">Guruhdagi puli</span>
                </li>
                <li>
                  <span className="text-lg text-white">
                    {numberChange(`${lessonDays?.money}`)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i className="fa-solid fa-calendar-minus text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Bu oydagi darslar
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                    {lessonDays?.must_pay_study}/{lessonDays?.all_lessons}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i className="fa-sharp fa-regular fa-phone-volume text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Ota onasi telefon raqami
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                    <a href={`tel:${detail?.parent_phone}`}>
                      {detail?.parent_phone}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-4 px-[20px]">
              <i className="fa-sharp fa-regular fa-mobile-phone text-2xl mx-3 text-[var(--theme)] border-r-2 pr-2 border-[var(--theme)]"></i>
              <ul>
                <li>
                  <span className="text-[15px] text-[var(--theme)]">
                    Telefon raqami
                  </span>
                </li>
                <li>
                  <span className="text-lg">
                    <a href={`tel:${detail?.parent_phone}`}>{detail?.phone}</a>
                  </span>
                </li>
              </ul>
            </div>
            <div className="2xl:col-span-2 xl:col-span-1 weekdays">
              <WeekDays days={detail_group?.group.days} />
            </div>
          </div>
        </div>
      </div>
      <div className="br-card">
        <br />
        <br />
        <div className="table-container">
          <div className="table-horizontal-container">
            <table className="table-information">
              <thead>
                <tr>
                  <th>
                    <span className="text-[var(--theme)] text-2xl">
                      &#8470;
                    </span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">To'lov vaqti</span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">To'lov turi</span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">To'lov summasi</span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">Chegirma</span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">Olindi</span>
                  </th>
                  <th>
                    <span className="whitespace-nowrap">Sana</span>
                  </th>
                  <th>
                    <i className="fa-regular fa-ellipsis-vertical"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {history?.map((item, index) => (
                  <tr key={`key-${index}`}>
                    <td>{index + 1})</td>
                    <td>{item.date_view}</td>
                    <td>{item.mode}</td>
                    <td>{numberChange(`${item.money}`)}</td>
                    <td>
                      {item.discount > 0 ? (
                        <strong className="text-[var(--red)]">
                          {item.discount}%
                        </strong>
                      ) : (
                        item.discount_absolute > 0 && (
                          <strong className="text-[var(--red)]">
                            {visibleNumber(`${item.discount_absolute}`)}
                          </strong>
                        )
                      )}
                    </td>
                    <td>{numberChange(`${item.money_with_discount}`)}</td>
                    <td>{item.created_at_visible}</td>
                    <td>
                      <div className="flex p-5 gap-5 justify-center">
                        <div
                          onClick={function () {
                            setModal(true); setModalType(1);
                            setDeletedElement(item)
                          }}
                          className="button">
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.625 6.125L4.375 6.12501"
                              stroke="var(--red)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.375 11.375V18.375"
                              stroke="var(--red)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16.625 11.375V18.375"
                              stroke="var(--red)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                              stroke="var(--red)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                              stroke="var(--red)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={`modal  ${modal && "active"}`}>
        {
          {
            0: (
              <div className="grid w-screen h-screen overflow-scroll place-items-center">
                <div>
                    <div className="StyledReceipt" id="reciept">
                    <div id="print">
                        <div className="flex justify-center">
                            <img
                            src={`${rootPath}${reciept?.log_company}`}
                            width={"180px"}
                            alt=""
                            />
                        </div>
                        <h3 className="text-2xl text-[#000] text-center">
                        {reciept?.factory_name}
                        </h3>
                        <br />
                        <br />
                        <table className="table-print w-full text-[#000]">
                        <tbody>
                            <tr></tr>
                            <tr>
                            <td>
                                <strong>Ism Familya:</strong>{" "}
                            </td>
                            <td>{reciept?.student_full_name}</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Ustoz:</strong>{" "}
                            </td>
                            <td>{reciept?.group_teacher}</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Darajasi:</strong>
                            </td>
                            <td>{reciept?.group_grade}</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Kurs narxi:</strong>
                            </td>
                            <td>
                                {visibleNumber(`${reciept?.grade_price}`)} sum
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <strong>To'landi:</strong>
                            </td>
                            <td>{visibleNumber(`${reciept?.money}`)} sum</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Chegirma</strong>
                            </td>
                            <td>
                                {reciept?.discount > 0 ? (
                                <strong className="text-[var(--red)]">
                                    {reciept?.discount}%
                                </strong>
                                ) : (
                                reciept?.discount_absolute > 0 && (
                                    <strong className="text-[var(--red)]">
                                    {visibleNumber(
                                        `${reciept?.discount_absolute}`
                                    )}{" "}
                                    sum
                                    </strong>
                                )
                                )}
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Chegirmalar bilan:</strong>
                            </td>
                            <td>
                                {visibleNumber(`${reciept?.money_with_discount}`)}{" "}
                                sum
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Oylik to'lov:</strong>
                            </td>
                            <td>{reciept?.month}</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Sana:</strong>
                            </td>
                            <td>{reciept?.created_at}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Vaqt:</strong>
                              </td>
                              <td>{reciept?.time}</td>
                            </tr>
                            <tr>
                            <td>
                                <strong>Qabul qiluvchi:</strong>
                            </td>
                            <td>{reciept?.created_by}</td>
                            </tr>
                        </tbody>
                        </table>

                        <a
                            href={`${rootPath}/static/XPrinterDriver.exe`}  
                            className="text-[var(--theme)] underline hover:no-underline"
                            id="link"
                           >
                                driver yuklab olish
                        </a>
                    </div>
                    </div>
                    <br />
                    <br />
                    <div className="flex gap-5">
                        <button
                        onClick={function () {
                          setModalType(0);
                          setModal(false);
                          setDetail(null);
                          setHistory([]);
                          setDetail_group(null)
                          setDate(null);
                          window.location.reload();
                        }}
                        className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                        Ortga qaytish
                        </button>
                        <button
                            onClick={PrintReciept}
                            className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                        >
                            Chiqarish
                        </button>
                    </div>
                </div>
              </div>
            ),
            1:(
              <div className="content">
              <div className="body-content flex flex-col items-center">
                <svg
                  width="237"
                  height="235"
                  viewBox="0 0 237 235"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                    stroke="#FFCC00"
                    strokeWidth="4"
                  />
                  <path
                    d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                    stroke="#2D2D2B"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M119.5 73.4375V124.844"
                    stroke="#FFCC00"
                    strokeWidth="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                    fill="#FFCC00"
                    stroke="#FFCC00"
                    strokeWidth="10"
                  />
                  <path
                    d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                    stroke="#FFCC00"
                    strokeWidth="3"
                  />
                  <path
                    d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                    stroke="#FFCC00"
                    strokeWidth="3.5"
                  />
                </svg>
                <h1 className="text-4xl">Ogohlantirish</h1>
                <br />
                <h3 className="text-2xl width-50 text-center">
                  Rostan ham ushbu elementni o'chirmoqchimisiz
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                <button
                  onClick={function () {
                    setModalType(0);
                    setModal(false);
                  }}
                  className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                >
                  Ortga qaytish
                </button>
                <button
                  onClick={deleteObj}
                  className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                >
                  O'chirish
                </button>
              </div>
            </div>
            )
          }[modalType]
        }
      </div>
    </>
  );
}

const moneyType = [
  { label: "Naqd", id: 0 },
  { label: "Click", id: 1 },
  { label: "Karta orqali", id: 2 },
  { label: "O'quvchini hisobidan", id: 3 },
];

const discountType = [
  { label: "O'quv markaz tomonidan", id: 1 },
  { label: "O'rtada", id: 0 },
];
