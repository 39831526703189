import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Doughnut, Pie } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Notification from "../../../components/alert"

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const colors = [
  "#FF0000", //Red
  "#0000FF", //Blue
  "#008000", //Green
  "#FFFF00", //Yellow
  "#800080", //Purple
  "#FFA500", //Orange
  "#FFC0CB", //Pink
  "#A52A2A", //Brown
  "#00FFFF", //Cyan
  "#FF00FF", //Magenta
  "#008080", //Teal
  "#E6E6FA", //Lavender
  "#4B0082", //Indigo
  "#40E0D0", //Turquoise
  "#800000", //Maroon
  "#FFD700", //Gold
  "#C0C0C0", //Silver
  "#F5F5DC", //Beige
  "#FF7F50", //Coral
  "#98FB98", //Mint
  "#FFDAB9", //Peach
  "#87CEEB", //Sky Blue
  "#C8A2C8", //Lilac
  "#DC143C", //Crimson
  "#50C878", //Emerald
  "#0F52BA", //Sapphire
  "#FFBF00", //Amber
  "#FFFFF0", //Ivory
  "#36454F", //Charcoal
  "#E0B0FF", //Mauve
  "#808000", //Olive
  "#FFDB58", //Mustard
  "#800020", //Burgundy
  "#708090", //Slate
  "#CCCCFF", //Periwinkle
  "#DA70D6", //Orchid
  "#0047AB", //Cobalt
  "#FFF700", //Lemon
  "#F28500", //Tangerine
  "#FF00FF", //Fuchsia
  "#00FFFF", //Aqua
  "#FF007F", //Rose
  "#40E0D0", //Turquoise
  "#D2691E", //Cinnamon
  "#007FFF", //Azure
  "#C2B280", //Sand
  "#FA8072", //Salmon
  "#DDA0DD", //Plum
  "#ACE1AF", //Celadon
  "#FBCEB1", //Apricot
  "#0047AB", //Cobalt Blue
  "#7FFF00", //Chartreuse
  "#DC143C", //Crimson
  "#FF003F", //Electric Blue
  "#228B22", //Forest Green
  "#DAA520", //Goldenrod
  "#FF69B4", //Hot Pink
  "#00A86B", //Jade
  "#C3B091", //Khaki
  "#FFF0F5", //Lavender Blush
  "#C04000", //Mahogany
  "#000080", //Navy Blue
  "#CC7722", //Ochre
  "#996666", //Puce
  "#E30B5C", //Raspberry
  "#704214", //Sepia
  "#D2B48C", //Tan
  "#120A8F", //Ultramarine
  "#E34234", //Vermilion
  "#F5DEB3", //Wheat
  "#738678", //Xanadu
  "#0014A8", //Zaffre
  "#EFDECD", //Almond
  "#89CFF0", //Baby Blue
  "#FFA6C9", //Carnation Pink
  "#6E7F80", //Denim
  "#614051", //Eggplant
  "#EEDC82", //Flax
  "#DCDCDC", //Gainsboro
  "#DF73FF", //Heliotrope
  "#5A4FCF", //Iris
  "#F0E68C", //Jonquil
  "#4CBB17", //Kelly Green
  "#26619C", //Lapis Lazuli
  "#EAA221", //Marigold
  "#727472", //Nickel
  "#E9D66B", //Oyster
  "#FFEFD5", //Papaya Whip
  "#A9A9A9", //Quicksilver
  "#E30B5C", //Razzmatazz
  "#F4C430", //Saffron
  "#CD5700", //Tawny
  "#8878C3", //Ube
  "#40826D", //Viridian
  "#C9A0DC", //Wisteria
  "#1D2951", //Xanthic
  "#2E5894", //YInMn Blue
  "#39A78E", //Zomp
  "#E52B50", //Amaranth
  "#967117", //Bistre
  "#893F45", //Caput Mortuum
  "#008B8B", //Dark Cyan
  "#96C8A2", //Eton Blue
  "#FF77FF", //French Lilac
  "#E49B0F", //Gamboge
  "#5218FA", //Han Purple
  "#FFD800", //Icterine
  "#F8DE7E", //Jasmine
  "#C79FEF", //Kobi
  "#D6CADD", //Languid Lavender
  "#FFDB58", //Mikado Yellow
  "#F6ADC6", //Nadeshiko Pink
  "#CC7722", //Ochre
  "#00A550", //Persian Green
  "#8E4585", //Quinacridone Magenta
  "#B76E79", //Rose Gold
  "#92000A", //Sangria
  "#F94D00", //Tangelo
  "#8878C3", //Ube
  "#43B3AE", //Verdigris
  "#645452", //Wenge
  "#738678", //Xanadu
  "#2E5894", //YInMn Blue
  "#39A78E", //Zomp
  "#E52B50", //Amaranth
  "#967117", //Bistre
  "#893F45", //Caput Mortuum
  "#008B8B", //Dark Cyan
  "#96C8A2", //Eton Blue
  "#FF77FF", //French Lilac
  "#E49B0F", //Gamboge
  "#5218FA", //Han Purple
  "#FFD800", //Icterine
  "#F8DE7E", //Jasmine
  "#C79FEF", //Kobi
  "#D6CADD", //Languid Lavender
  "#FFDB58", //Mikado Yellow
  "#F6ADC6", //Nadeshiko Pink
  "#CC7722", //Ochre
  "#00A550", //Persian Green
  "#8E4585", //Quinacridone Magenta
  "#B76E79", //Rose Gold
  "#92000A", //Sangria
  "#F94D00", //Tangelo
  "#8878C3", //Ube
  "#43B3AE", //Verdigris
  "#645452", //Wenge
];

export default function OverALLReceptions(params) {
  const [pieData, setPieData] = useState(null);
  const [leads, setLeads] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [deletedObj, setDeletedObj] = useState(null);
  const [editedObj, setEditedObj] = useState(null);

  useEffect(() => {
    axios.get("leads/reports/reception/").then((response) => {
      setLeads(response.data);
      setPieData({
        labels: response.data.map((k) => k.title),
        datasets: [
          {
            label: "Narxi: ",
            data: response.data.map((k) => k.students_count),
            backgroundColor: colors.slice(0, response.data.length),
            borderColor: "#fff",
            borderWidth: 2,
          },
        ],
      });
    });
  }, []);
  function CreateLeads(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    console.log(data.get("title"));
    axios
      .post("leads/where-list/", { title: data.get("title") })
      .then((res) => {
        e.target.reset();
        console.log(res.data);
        let obj = {
          id: res.data.id,
          title: res.data.title,
          created_at: res.data.created_at,
          students_count: 0,
        };
        setLeads([...leads, obj]);
        setModal(false);
        setModalType(0);
      });
  }
  function updateLeads(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    let next_title = data.get("title");
    axios
      .put("leads/where-list/", { id: editedObj.id, title: data.get("title") })
      .then((res) => {
        e.target.reset();
        console.log(res.data);
        let obj = {
          id: res.data.id,
          title: res.data.title,
          created_at: res.data.created_at,
          students_count: 0,
        };
        // setLeads([...leads, obj])
        let list = leads;
        for (let i = 0; i < list.length; i++) {
          if (list[i]["id"] === res.data.id) {
            list[i]["title"] = next_title;
          }
        }
        setLeads(list);

        setModal(false);
        setModalType(0);
      });
  }
  function deleteLeads() {
    axios
      .delete("leads/where-list/", { data: { id: deletedObj } })
      .then((res) => {
        setLeads(leads.filter((item) => item.id !== deletedObj));
        setModal(false);
        setModalType(0);
      });
  }
  return (
    <>
      <div className="grid lg:grid-cols-2 gap-5">
        <div className="bg-[var(--theme-sidebar)] p-5  rounded-[25px]">
          <div className="flex justify-between">
            <h1 className="text-2xl">Topdagi 3ta oqimlar</h1>
            <h1
              onClick={() => {
                setModal(true);
                setModalType(0);
              }}
              className="text-xl cursor-pointer text-[var(--theme)]"
            >
              <i className="fa-regular fa-folder-plus mx-3"></i>
              Yangi oqim qo'shish
            </h1>
          </div>
          <br />
          <br />
          <br />
          <br />
          {leads?.slice(0, 3).map((item) => (
            <div className="flex justify-between border-b-4 border-dashed pb-2 my-5">
              <h1 className="text-2xl">{item.title}</h1>
              <h1 className="text-2xl">{item.students_count}</h1>
            </div>
          ))}
        </div>
        <div className="bg-[var(--theme-sidebar)] p-5  rounded-[25px]">
          <div className="max-h-[400px] flex md:justify-center">
            {pieData && <Doughnut data={pieData} />}
          </div>
        </div>
      </div>
      <div className=" bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
        <br />
        <br />
        <div className="flex justify-between gap-5 flex-wrap md:flex-nowrap">
          <h1 className="text-4xl">Barcha oqimlar</h1>
          <h1
            onClick={() => {
              setModal(true);
              setModalType(0);
            }}
            className="text-3xl cursor-pointer text-[var(--theme)]"
          >
            <i className="fa-regular fa-folder-plus mx-3"></i>
            Yangi oqim qo'shish
          </h1>
        </div>
        <br />
        <br />
        <div className="table-container td-min-width">
            <div className="table-horizontal-container">
            <table className="table-border">
                <thead>
                <tr>
                    <th></th>
                    <th>&#8470;</th>
                    <th>Nomi</th>
                    <th>Ochilgan sana</th>
                    <th>O'quvchilar soni</th>
                    <th>Ulashish</th>
                </tr>
                </thead>
                <tbody>
                {leads?.map((item, index) => {
                    const date = new Date(item.created_at);
                    let month = [
                    "Yan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Iyun",
                    "Iyul",
                    "Avg",
                    "Sen",
                    "Okt",
                    "Noy",
                    "Dekabr",
                    ];
                    const visibledate = `${date.getDate()}  ${
                    month[date.getMonth()]
                    } ${date.getFullYear()} `;
                    return (
                    <tr key={`index-${index}`}>
                        <td></td>
                        <td style={{ width: "unset" }}>{index + 1}</td>
                        <td>
                        <NavLink
                            to={`/settings/reports/receptions/${item.slug}/`}
                            className="truncate text-[var(--theme)]"
                        >
                            {item.title}
                        </NavLink>
                        </td>
                        <td>
                        <span className="truncate">
                            {visibledate}{" "}
                            {date.toLocaleString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                            })}
                        </span>
                        </td>
                        <td>{item.students_count}</td>
                        <td>
                        <i
                            onClick={() => {
                            setEditedObj(item);
                            setModal(true);
                            setModalType(1);
                            }}
                            className="fa-solid fa-pen text-2xl cursor-pointer text-[var(--green)] mx-3"
                        ></i>
                        <i
                            onClick={() => {
                            setModal(true);
                            setModalType(3);
                            setDeletedObj(item.id);
                            }}
                            className="fa-solid fa-trash-can mx-3 text-2xl cursor-pointer text-[var(--red)]"
                        ></i>
                        <i 
                          onClick={() => {
                            navigator.clipboard.writeText(`${window.location.origin}/forms/student/add/${item.slug}?company=${localStorage.getItem("api")}`)
                            Notification(
                              "Muvaffaqiyatli amalga oshdi",
                              "Online qabul manzili nusxalandi",
                              "var(--green)",
                              5000
                            )
                          }}
                         className="fa-solid fa-copy text-2xl cursor-pointer text-[var(--yellow)] mx-3"></i>
                        <a
                        target="_blank"
                            href={`https://telegram.me/share/url?url=${window.location.origin}/forms/student/add/${item.slug}?company=${localStorage.getItem("api")}`}
                        >
                            <i className="fa-brands fa-telegram cursor-pointer text-2xl text-[#37A9E1]"></i>
                        </a>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <form className="content" onSubmit={CreateLeads}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Yangi oqim qo'shish</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <input
                    type="text"
                    name="title"
                    required
                    placeholder="Oqim nomi"
                    className="input"
                  />
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </form>
            ),
            1: (
              <form className="content" onSubmit={updateLeads}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Yangi oqim qo'shish</h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      type="reset"
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <input
                    type="text"
                    name="title"
                    defaultValue={editedObj?.title}
                    placeholder="Oqim nomi"
                    className="input"
                    required
                  />
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </form>
            ),
            3: (
              <div className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz rotan ham ushbu toplamni ochiqmoqchimisiz
                    <br />
                    <strong>Unutmang! keyin qaytarib bo'lmaydi</strong>
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={function () {
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    onClick={deleteLeads}
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </div>
            ),
          }[modalType]
        }
      </div>
    </>
  );
}
