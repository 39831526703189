import React, { useEffect, useState } from "react";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import WeekDays from "../../components/teacher/weekdays";
import AttendanceDrop from "../../components/teacher/attendance_drop";
import MarkDrop from "../../components/teacher/mark_drop";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { formatNum, getDate } from "../../utils/main";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Notification from "../../components/alert";
import { visibleNumber, rootPath } from "../../utils/main";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
});

export default function Detail(params) {
  const navigate = useNavigate();

  const classes = useStyles();
  const [detailType, setDetialType] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState(null);
  const [grades, setGrades] = useState([]);
  const [GradeValue, SetGradeValue] = useState(null);
  const [teachers, setTeachers] = useState([]);

  const [groupTitle, setGroupTitle] = useState();
  const [telegramId, setTelegramId] = useState();
  const [formDays, setFormDays] = useState();
  const [teacher, setTeacher] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [formSubject, setFormSubject] = useState(null);
  const [formGrades, setFormGrades] = useState([]);
  const [formGrade, setFormGrade] = useState(null);

  const [Wherelist, setWherelist] = useState([]);
  const [whereValue, setWhereValue] = useState([]);
  const [who, setWho] = useState(null);

  const [attendanceList, setAttendanceList] = useState([]);

  const routerParams = useParams();

  useEffect(() => {
    setWho(JSON.parse(localStorage.getItem("who")));
    setIsLoading(true);
    axios
      .get(`main/group/${routerParams.slug}/`)
      .then((res) => setDetails(res.data))
      .catch((err) => {
        Notification("Muommo", "Bunday guruh topilmadi", "var(--yellow)", 5000);
        navigate("/groups/");
      });

    const who_local = localStorage.getItem("who");
    if (
      who_local?.includes("0") ||
      who_local?.includes("1") ||
      who_local?.includes("4")
    ) {
      axios.get("/leads/where-list/").then((res) => {
        setWherelist(res.data);
      });
    }
    setIsLoading(false);
  }, [useLocation()]);

  function EditData() {
    axios.get("main/subject/").then((response) => {
      setSubjects(response.data);
    });

    axios.get("accounts/teachers/option/").then((res) => {
      setTeachers(res.data);
    });
    setGroupTitle(details?.title);
    setTelegramId(details?.telegram_id);
    setFormDays(details?.days.map((item) => WeekdaysOption[parseInt(item)]));
    setTeacher(details?.teacher);
    setStartTime(dayjs(`2022-04-17T${details?.start_time?.slice(0, 5)}`));
    setEndTime(dayjs(`2022-04-17T${details?.end_time?.slice(0, 5)}`));
    setFormSubject(details?.subject);
    setFormGrade(details?.grade);
  }

  useEffect(() => {
    SetGradeValue(null);
    if (subject) {
      axios.get("main/grade/" + subject.id + "/").then((response) => {
        setGrades(response.data);
      });
    } else {
      setGrades([]);
    }
  }, [subject]);

  useEffect(() => {
    if (formSubject) {
      axios.get("main/grade/" + formSubject.id + "/").then((response) => {
        setFormGrades(response.data);
        setFormGrade(null);
      });
    } else {
      setFormGrades([]);
    }
  }, [formSubject]);

  // const useStyles = makeStyles({
  //     paper: {
  //         backgroundColor: "var(--theme-bg)"
  //     }
  // });

  // const classes = useStyles();

  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(" ", "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }
  function dateKeyup(e) {
    var y = e.target.value.replaceAll("/", "");
    var x = y.replaceAll(/\D/g, "").match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    if (e.target.value.length) {
      if (+x[2] > 12) {
        x[2] = "12";
      }
      if (x[2] === "00") {
        x[2] = "01";
      }
      var checkDate = new Date(x[1], x[2], 0).getDate();
      if (+x[3] > checkDate) {
        x[3] = checkDate;
      }
      if (x[3] === "00") {
        x[3] = "01";
      }
      e.target.value = `${x[1] ? x[1] : ""}${x[2] ? "-" + x[2] : ""}${
        x[3] ? "-" + x[3] : " "
      }`;
    } else {
      e.target.value = "";
    }
  }

  function handleGroupSubmit(e) {
    e.preventDefault();
    console.log(startTime.$d)
    console.log()
    if (formDays) {
      const formData = {
        id: details.id,
        title: groupTitle,
        telegram_id: telegramId,
        days: formDays.map((day) => day.id),
        teacher: teacher.id,
        start_time:startTime.$d.toLocaleTimeString('en-US', { hour12: false }),
        end_time: endTime.$d.toLocaleTimeString('en-US', { hour12: false }),
        subject: formSubject.id,
        grade: formGrade.id,
      };
      axios
        .put("main/group/", formData)
        .then((res) => {
          Notification(
            "Muvaffaqiyatli amalga oshirildi",
            "Guruh muvaffaqiyatli qo'shildi",
            "var(--green)",
            6000
          );
          setDetails(res.data);
          setGroupTitle("");
          setTelegramId("");
          setFormDays("");
          setTeacher("");
          setStartTime("");
          setEndTime("");
          setFormSubject("");
          setFormGrade("");
          setModal(false);
        })
        .catch((err) => {
          Notification("Xatolik yuz berdi", err.message, "var(--red)", 6000);
        });
    } else {
      Notification(
        "Formani to'ldiring",
        "iltimos hafta kunlarini tanlang",
        "var(--yellow)",
        6000
      );
    }
  }

  function addStudent(e) {
    e.preventDefault();
    let data = new FormData(e.target);

    let form = {
      full_name: data.get("full_name"),
      phone: data.get("phone"),
      parent_phone: data.get("parent_phone"),
      birthday: data.get("birthday"),
      comment: data.get("comment"),
      where: whereValue,
    };
    axios.post(`main/group/${routerParams.slug}/`, form).then((res) => {
      setDetails(res.data);
      e.target.reset();
      Notification(
        "Muvaffaqiyatli amalga oshirildi",
        "Guruhga o'quvchi qo'shildi",
        "var(--green)",
        6000
      );
    });
  }
  function deleteGroups(e) {
    e.preventDefault();
    let data = new FormData(e.target);

    axios
      .delete("main/group/", {
        data: {
          group_ids: [details.id],
          reason: data.get("reason"),
          deleted_with_students: data.get("deleted_with_students"),
        },
      })
      .then((res) => {
        setModalType(0);
        setModal(false);
      });
  }
  function studentDelete(val) {
    let data = details;
    data["students"] = data["students"].filter((item) => item.id !== val);
    setDetails(data);
  }

  const [resualtSearch, setResualtSearch] = useState([]);
  function searchStudent(e) {
    axios
      .get(`main/student/search/${routerParams.slug}/`, {
        params: {
          search: e.target.value,
        },
      })
      .then((res) => {
        setResualtSearch(res.data);
      });
  }

  function AddGroup(id) {
    axios
      .put(`main/student/search/${routerParams.slug}/`, {
        student_id: id,
      })
      .then((res) => {
        setDetails(res.data);
        Notification(
          "Muvaffaqiyatli amalga oshirildi",
          "O'quvchi guruhga qo'shildi",
          "var(--green)",
          6000
        );
      });
  }

  const [excel, setExcel] = useState();

  function handleFile(e) {
    e.preventDefault();
    if (excel) {
      axios
        .post(
          "main/student-add-excel/" + routerParams.slug + "/",
          { file: excel },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          setExcel(null);
          setModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          Notification(
            "Muvaffaqiyatli amalga oshirildi",
            "Guruhga o'quvchilar muvaffaqiyatli qo'shildi",
            "var(--green)",
            5000
          );
        })
        .catch((err) => {
          e.target.reset();
          setExcel(null);
          setModal(false);
          Notification(
            "Hatolik yuz berdi",
            "Fayl orqali malumot yuklashda hatolik!",
            "var(--red)",
            5000
          );
        });
    } else {
      Notification(
        "Malumot hato",
        "Faylni qayta yuklab koring!",
        "var(--red)",
        5000
      );
    }
  }

  function setExcelFile(e) {
    setExcel(e.target.files[0]);
  }

  return (
    <>
      <div className="bc-card">
        {who?.includes("0") || who?.includes("1") || who?.includes("4") ? (
          <div className="flex justify-end flex-wrap gap-5">
            <button
              title="Guruhga qo'shish"
              onClick={() => (setModalType(5), setModal(true))}
              className="button rounded-[12px]  text-[var(--yellow)]"
            >
              <i className="fa-solid fa-magnifying-glass text-2xl m-2 text-[var(--oppocity-bg)]"></i>
            </button>
            <button
              title="Guruhga qo'shish"
              onClick={() => (setModalType(0), setModal(true))}
              className="button rounded-[12px]  text-[var(--yellow)]"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 7H28"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25 4V10"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.87402 26.9988C5.10347 24.8708 6.87129 23.1037 8.99988 21.8752C11.1285 20.6467 13.5429 20 16.0005 20C18.4582 20 20.8726 20.6468 23.0012 21.8754C25.1297 23.1039 26.8975 24.871 28.1269 26.9991"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setModal(true);
                setModalType(7);
              }}
              title="Xabar yozish"
              className="button text-[var(--yellow)]"
            >
              <i className="fa-regular fa-file-excel text-2xl m-2 text-red-500"></i>
            </button>
            <button
              onClick={() => {
                setModal(true);
                setModalType(2);
                EditData();
              }}
              title="Xabar yozish"
              className="button  text-[var(--yellow)]"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3099 28.6869H6.375C6.09321 28.6869 5.82296 28.5749 5.6237 28.3757C5.42444 28.1764 5.3125 27.9061 5.3125 27.6244V21.6895C5.3125 21.5499 5.33998 21.4118 5.39338 21.2829C5.44677 21.1539 5.52504 21.0368 5.6237 20.9382L21.5612 5.00065C21.7605 4.80139 22.0307 4.68945 22.3125 4.68945C22.5943 4.68945 22.8645 4.80139 23.0638 5.00065L28.9987 10.9356C29.198 11.1348 29.3099 11.4051 29.3099 11.6869C29.3099 11.9686 29.198 12.2389 28.9987 12.4382L13.0612 28.3757C12.9625 28.4743 12.8454 28.5526 12.7165 28.606C12.5876 28.6594 12.4494 28.6869 12.3099 28.6869Z"
                  stroke="#4FB286"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.0625 8.5L25.5 15.9375"
                  stroke="#4FB286"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.6819 28.6194L5.37988 21.3174"
                  stroke="#4FB286"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              title="Xabar yozish"
              onClick={() => (setModalType(1), setModal(true))}
              className="button  text-[var(--yellow)]"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 7L16 18L4 7"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8184 16L4.30859 24.7174"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.6916 24.7175L18.1816 16"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setModal(true);
                setModalType(3);
              }}
              title="Xabar yozish"
              className="button text-[var(--yellow)]"
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.6865 7.4375L5.31152 7.43751"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8125 13.8125V22.3125"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.1875 13.8125V22.3125"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ) : (
          <></>
        )}
        <br />
        <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5 gap-2 mt-5">
          <div className="bg-[var(--theme-bg)] p-5 rounded-[25px]">
            <h1 className="text-2xl text-[var(--theme)]">Guruh ma’lumotlari</h1>
            <br />
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Fan:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                {details?.subject?.title}
              </h3>
            </div>
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Daraja:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                {details?.grade?.title}
              </h3>
            </div>
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Guruh nomi:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                {details?.title}
              </h3>
            </div>
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Guruh oylik To'lovi:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                {formatNum(details?.grade?.price)}
              </h3>
            </div>
          </div>
          <div className="bg-[var(--theme-bg)] p-5  rounded-[25px]">
            <h1
              className="text-2xl text-[var(--theme)]"
              style={{ textAlign: "left" }}
            >
              O’qituvchi ma’lumotlari
            </h1>
            <br />
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Ism va Familiya:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate text-[var(--theme)]">
                {details?.teacher?.name} {details?.teacher?.surname}
              </h3>
            </div>
            <div className="flex justify-between m-3  flex-wrap">
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 truncate">
                Dars vaqti:
              </h3>
              <h3 className="text-base sm:text-lg lg:text-2xl my-1 text-xl truncate text-[var(--theme)]">
                {details?.start_time?.slice(0, 5)}-
                {details?.end_time?.slice(0, 5)}
              </h3>
            </div>
            <br />
            <WeekDays days={details?.days} bg={true} />
          </div>
        </div>
      </div>
      <br />
      <div className="bc-card">
        <br />
        <div className="flex flex-wrap gap-5">
          <div
            onClick={(e) => setDetialType(0)}
            className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
              detailType === 0 && "active"
            }`}
          >
            <i className="fa-regular fa-list mx-2 text-xl"></i>
            Guruh O'quvchilari
          </div>
          <div
            onClick={(e) => setDetialType(1)}
            className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
              detailType === 1 && "active"
            }`}
          >
            <i className="fa-regular fa-clipboard-user mx-2 text-xl"></i>
            Davomat
          </div>
          <div
            onClick={(e) => setDetialType(2)}
            className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
              detailType === 2 && "active"
            }`}
          >
            <i className="fa-regular fa-book-blank mx-2 text-xl"></i>
            Jurnal
          </div>
          <div
            onClick={(e) => setDetialType(3)}
            className={`button text-[var(--theme-color)] hover:text-[var(--theme-bg)] ${
              detailType === 3 && "active"
            }`}
          >
            <i className="fa-regular fa-money-check-dollar mx-2 text-xl"></i>
            Oylik To'lovlar
          </div>
        </div>
        <br />
        <br />
        {
          {
            0: (
              <GroupInformation
                students={details?.students}
                studentDelete={(val) => studentDelete(val)}
                studentUpdate={(value) =>
                  setDetails({ ...details, students: value })
                }
                groupId={routerParams.slug}
              />
            ),
            1: (
              <GroupAttendance details={details} groupId={routerParams.slug} />
            ),
            2: <GroupJournal details={details} groupId={routerParams.slug} />,
            3: <GroupPayment details={details} groupId={routerParams.slug} />,
          }[detailType]
        }
      </div>
      <br />
      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <form className="content" action="" onSubmit={addStudent}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input
                      type="text"
                      className="input"
                      name="full_name"
                      placeholder="Ism familya"
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="phone"
                      className="input"
                      placeholder="nomer"
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="parent_phone"
                      className="input"
                      placeholder="Ota Onasini nomeri"
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => dateKeyup(e)}
                      className="input"
                      name="birthday"
                      placeholder="Tug'ilgan kuni (Yil/Oy/Kun)"
                      required
                    />
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setWhereValue(newValue.id);
                        } else {
                          setWhereValue("");
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={Wherelist}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Qayerdan" />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={2}
                      placeholder="Izoh"
                    ></textarea>
                  </div>
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'quvchi qo'shish
                  </button>
                </div>
              </form>
            ),
            1: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">
                    Tanlangan foydalanuvchilar: 10 ta{" "}
                  </h3>
                  <div className="flex gap-3">
                    <button
                      className="button"
                      onClick={() => {
                        setModalType(0);
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--yellow)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <textarea
                    name=""
                    rows={7}
                    className="textarea"
                    placeholder="Xabar yozish (majburiy)"
                    value="Assalomu alaykum [oquvchi] siz ushbu [guruh] uchun to'lov qilishni unutman"
                  ></textarea>

                  <h3 className="flex m-1 mt-3 text-base items-center">
                    SMS orqali habar berish
                    <input
                      type="checkbox"
                      className="ml-2 accent-[var(--yellow)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                    />
                  </h3>
                  <h3 className="flex m-1 text-base items-center">
                    Tizim orqali habar berish
                    <input
                      type="checkbox"
                      className="ml-2 accent-[var(--yellow)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                    />
                  </h3>
                </div>
                <div className="flex">
                  <button className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                    xabar berish
                  </button>
                </div>
              </div>
            ),
            2: (
              <form onSubmit={handleGroupSubmit} className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <button type="reset" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content h-100 overflow-auto">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="input">
                      <TextField
                        className="w-full"
                        size="small"
                        value={groupTitle || ""}
                        onChange={(e) => setGroupTitle(e.target.value)}
                        name="groupName"
                        label="Guruh nomi"
                        required
                      />
                    </div>
                    <div className="input">
                      <TextField
                        className="w-full"
                        size="small"
                        value={telegramId || ""}
                        onChange={(e) => setTelegramId(e.target.value)}
                        name="telegramId"
                        label="Telegram Id"
                        required
                      />
                    </div>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setFormDays(newValue);
                        } else {
                          setFormDays("");
                        }
                      }}
                      disablePortal
                      value={formDays || []}
                      id="combo-box-demo"
                      multiple
                      name="weekDays"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      className=" input"
                      options={WeekdaysOption}
                      style={{ minWidth: "200px" }}
                      classes={{ paper: classes.paper }}
                      required
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Hafta kuni"
                        />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setTeacher(newValue);
                        } else {
                          setTeacher("");
                        }
                      }}
                      value={teacher || null}
                      disablePortal
                      id="combo-box-demo"
                      options={teachers}
                      getOptionLabel={(option) => {
                        return option.name + " " + option.surname;
                      }}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          label="O'qituvchi"
                        />
                      )}
                    />
                    <div className="input">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          label="Boshlananish vaqti"
                          className="w-full"
                          required
                          value={startTime || null}
                          onChange={(newValue) => {
                            setStartTime(newValue);
                          }}
                          format="HH:mm"
                          size="small"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="input">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          label="Tugash vaqti"
                          className="w-full"
                          required
                          value={endTime || null}
                          onChange={(newValue) => setEndTime(newValue)}
                          format="HH:mm"
                          size="small"
                        />
                      </LocalizationProvider>
                    </div>
                    <Autocomplete
                      className=" input"
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setFormSubject(newValue);
                        } else {
                          setFormSubject(null);
                          setFormGrade(null);
                        }
                      }}
                      disablePortal
                      value={formSubject || null}
                      id="combo-box-demo"
                      options={subjects}
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          label="Fan"
                        />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setFormGrade(newValue);
                        } else {
                          setFormGrade(null);
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={formGrades}
                      value={formGrade || null}
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          label="Daraja"
                        />
                      )}
                    />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="flex">
                  <button className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                    Guruh qo'shish
                  </button>
                </div>
              </form>
            ),
            3: (
              <form onSubmit={deleteGroups} className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="150"
                    height="150"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-lg width-50 text-center">
                    Siz hozirda Ushbu guruhni ochirmoqdasiz va bularni qaytarib
                    bolmaydi
                  </h3>
                  <h3 className="text-lg width-50 text-center">
                    Agar oquvchilarni ham o'chirmoqchi bo'lsangiz ushbu tugmani
                    bosingiz mumkin
                    <br />
                    <strong className="text-[var(--red)]">
                      Unutmang! bu o'quvchilar boshqa guruhga ham o'qishi mumkin
                    </strong>
                  </h3>
                </div>
                <div className="flex mx-4 items-center content-start">
                  <input
                    type="checkbox"
                    name="deleted_with_students"
                    value={true}
                    id="delete-student"
                    className="ml-2 accent-[var(--red)] mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                  />
                  <label htmlFor="delete-student">
                    O'quvchilarni ham o'chirish
                  </label>
                </div>
                <input
                  type="text"
                  name="reason"
                  maxLength={125}
                  required={true}
                  className="input"
                  placeholder="Sabab"
                />

                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={() => {
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </form>
            ),
            5: (
              <div className="content" action="">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content flex-1 pt-4">
                  <input
                    type="text"
                    name="search"
                    maxLength={125}
                    required={true}
                    className="input"
                    placeholder="Qidiruv"
                    onChange={searchStudent}
                  />
                  <br />
                  <br />
                  <br />
                  <div className="w-full">
                    <div className="table-horizontal-container max-h-[400px] overflow-auto">
                      <table className="table-border td-min-width">
                        <thead>
                          <tr>
                            <th>&#8470;</th>
                            <th>Ism Familya</th>
                            <th>Nomer</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {resualtSearch?.map((item, index) => (
                            <tr>
                              <td>{index + 1})</td>
                              <td>{item.full_name}</td>
                              <td>
                                <ul>
                                  <li>{item.phone}</li>
                                  <li>{item?.parent_phone}</li>
                                </ul>
                              </td>
                              <td>
                                <button
                                  onClick={() => AddGroup(item.id)}
                                  className="button"
                                >
                                  <i className="fa-solid fa-cloud-plus mx-1"></i>
                                  qo'shish
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ),
            7: (
              <form onSubmit={handleFile} className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <span
                      className="button pr-2 pl-3"
                      onClick={() => setModal(false)}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content flex-1 pt-4">
                  <div className="flex h-[300px] flex-wrap items-center justify-center">
                    <input
                      name="excel_file"
                      onChange={setExcelFile}
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <a
                      href={rootPath + "static/excel_template.xlsx"}
                      className="text-[lightblue!important]"
                    >
                      Yordamchi excel faylini yuklab olish.
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="bg-[--green] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Yuklash
                  </button>
                </div>
              </form>
            ),
          }[modalType]
        }
      </div>
    </>
  );
}

const GroupInformation = (props) => {
  const [students, setStudents] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [listStudent, setListStudent] = useState([]);

  const [studentComment, setStudentComment] = useState("");
  const [commentType, setCommentType] = useState(null)
  const [CSid, setCSid] = useState(null);
  const [comments, setComments] = useState([])

  function getComments(id) {
    axios.get(`main/comments/get/${id}/`).then((response) => {
      setComments(response.data)
      setModal(true);
      setModalType(5);
      setCSid(id)
    });
  }

  function AddComment(e){
    e.preventDefault();
    let data = new FormData(e.target);
    console.log(CSid, commentType, data.get("comment"))

    axios
    .post(
      `main/comments/get/${CSid}/`,
      {
        type_comment: `${commentType.id}`,
        comment: data.get("comment")
      }
    )
    .then((response) => {
      e.target.reset()
      setComments(response.data)
      setModal(true);
      setModalType(5);
    })
  }

  useEffect(() => {
    setStudents(props.students);
  }, [props.students]);

  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(" ", "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }
  function visiblephone(value) {
    var y = value.replaceAll("+998 ", "").replaceAll(" ", "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      return;
    }
  }
  function dateKeyup(e) {
    var y = e.target.value.replaceAll("/", "");
    var x = y.replaceAll(/\D/g, "").match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    if (e.target.value.length) {
      if (+x[2] > 12) {
        x[2] = "12";
      }
      if (x[2] === "00") {
        x[2] = "01";
      }
      var checkDate = new Date(x[1], x[2], 0).getDate();
      if (+x[3] > checkDate) {
        x[3] = checkDate;
      }
      if (x[3] === "00") {
        x[3] = "01";
      }
      e.target.value = `${x[1] ? x[1] : ""}${x[2] ? "-" + x[2] : ""}${
        x[3] ? "-" + x[3] : " "
      }`;
    } else {
      e.target.value = "";
    }
  }

  const routerParams = useParams();
  function deleteStudent(e) {
    e.preventDefault();
    let arr = listStudent.map((item) => item.id);
    let data = new FormData(e.target);
    axios
      .post(`/accounts/students/list/`, {
        ids: arr,
        reason: data.get("reason"),
      })
      .then(function (response) {
        setModal(false);
        setModalType(0);
        Notification("muvaffaqiyatli", "O'quvchilar o'chirildi", 5000);
        props.studentDelete(arr[0]);
        setStudents(students.filter((item) => item.id !== arr[0]));
      })
      .catch(function (error) {
        Notification(
          "Internet bilan muomo",
          "Nimadir ishlamayapdi",
          "var(--yellow)",
          5000
        );
      });
  }
  function removeGroups(e) {
    e.preventDefault();
    let list = listStudent;
    axios
      .put(`main/group/${routerParams.slug}/`, {
        student_id: list[0].id,
      })
      .then(function (response) {
        setModal(false);
        setModalType(0);
        Notification("muvaffaqiyatli", "O'quvchi o'chirildi", 5000);
        props.studentDelete(list[0].id);
        setStudents(students.filter((item) => item.id !== list[0].id));
      })
      .catch(function (error) {
        Notification(
          "Internet bilan muomo",
          "Nimadir ishlamayapdi",
          "var(--yellow)",
          5000
        );
      });
  }
  function editStudent(e) {
    e.preventDefault();
    let data = new FormData(e.target);

    let form = {
      student_id: listStudent[0].id,
      full_name: data.get("full_name"),
      phone: data.get("phone"),
      parent_phone: data.get("parent_phone"),
      birthday: data.get("birthday"),
    };
    let arr = students;
    axios.put(`main/student/update/teacher/`, form).then((res) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === listStudent[0].id) {
          arr[i].full_name = data.get("full_name");
          arr[i].phone = data.get("phone");
          arr[i].parent_phone = data.get("parent_phone");
          arr[i].birthday = data.get("birthday");
        }
      }
      setStudents(arr);
      setModal(false);
      setModalType(0);
      Notification("muvaffaqiyatli", "O'quvchi Tahrirlandi", 5000);
      props.studentUpdate(arr);
      // setDetails(res.data)
    });
  }

  return (
    <>
      <h1 className="text-2xl">Guruh O'quvchilari</h1>
      <br />
      <div className="table-container">
        <div className="table-horizontal-container">
          <table className="table-information">
            <thead>
              <tr>
                <th>
                  <span className="text-[var(--theme)] text-2xl">&#8470;</span>
                </th>
                <th>Ism va Familiya</th>
                <th>Tel raqam (oziniki)</th>
                <th>Tel raqam (ota onasiniki)</th>
                <th>tug'ilgan kuni</th>
                <th>Sozlamalar</th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student, idx) => {
                return (
                  <tr key={`infor-${idx}`}>
                    <td>{idx + 1})</td>
                    <td>
                      <span>{student.full_name}</span>
                    </td>
                    <td>
                      <a href={student.phone}>{student.phone}</a>
                    </td>
                    <td>
                      <a href={student.parent_phone}>{student.parent_phone}</a>
                    </td>
                    <td>{getDate(student.birthday, false)}</td>
                    <td>
                      <div className="flex p-5 gap-5 justify-center">
                        <div
                          onClick={(e) => {
                            setModal(true);
                            setModalType(2);
                            setListStudent([student]);
                          }}
                          className="button"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                              stroke="#4FB286"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.875 7L21 13.125"
                              stroke="#4FB286"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.4441 23.5691L4.43066 17.5557"
                              stroke="#4FB286"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            setModal(true);
                            setListStudent([student]);
                            setModalType(0);
                          }}
                          className="button"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.625 6.125L4.375 6.12501"
                              stroke="#7C90DB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.375 11.375V18.375"
                              stroke="#7C90DB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16.625 11.375V18.375"
                              stroke="#7C90DB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                              stroke="#7C90DB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                              stroke="#7C90DB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <button
                          onClick={() => {
                            setModal(true);
                            setListStudent([student]);
                            setModalType(1);
                          }}
                          className="button"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z"
                              stroke="#FC3F1D"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M17.5 10.5L10.5 17.5"
                              stroke="#FC3F1D"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.5 17.5L10.5 10.5"
                              stroke="#FC3F1D"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          className="button"
                          onClick={(e) => getComments(student.id)}
                        >
                          <i className="fa-solid fa-comment text-[var(--green)]"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <form onSubmit={deleteStudent} className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz hozirda{" "}
                    <strong className="text-[var(--red)]">
                      {listStudent[0]?.full_name}
                    </strong>
                    ni ochirmoqdasiz va bularni qaytarib bolmaydi
                  </h3>
                </div>
                <input
                  type="text"
                  name="reason"
                  maxLength={125}
                  required={true}
                  className="input"
                  placeholder="Sabab"
                />
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={() => {
                      setModalType(0);
                      setListStudent([]);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </form>
            ),
            1: (
              <form onSubmit={removeGroups} className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="235"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz rostan ham ushbu o'quvchini (
                    <strong>{listStudent[0]?.full_name}</strong>) chiqarib
                    yubormoqchimisiz
                  </h3>
                </div>

                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={() => {
                      setListStudent([]);
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Chiqarib yuborish
                  </button>
                </div>
              </form>
            ),
            2: (
              <form className="content" action="" onSubmit={editStudent}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input
                      type="text"
                      className="input"
                      name="full_name"
                      placeholder="Ism familya"
                      defaultValue={listStudent[0]?.full_name}
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="phone"
                      className="input"
                      placeholder="nomer"
                      defaultValue={visiblephone(`${listStudent[0]?.phone}`)}
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="parent_phone"
                      className="input"
                      defaultValue={visiblephone(
                        `${listStudent[0]?.parent_phone}`
                      )}
                      placeholder="Ota Onasini nomeri"
                    />
                    <input
                      type="date"
                      // onKeyUp={(e) => phoneKeyup(e)}
                      name="birthday"
                      className="input"
                      defaultValue={`${listStudent[0]?.birthday}`}
                      placeholder="tug'ilgan kuni"
                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'quvchi Tahrirlash
                  </button>
                </div>
              </form>
            ),
            5: (
                <div className="content">
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">O'quvchi uchun izohlar</h3>
                    <div className="flex gap-3">
                      <span
                        className="button"
                        onClick={function () {
                          setModal(false);
                        }}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="body-content block mt-5">
                    <div className="block">
                      <div>
                        <div className="bg-[var(--theme-bg)] max-w-[100%] text-xl w-fit p-2 rounded-xl">
                          <strong>
                            
                            <i className="fa-solid fa-user-vneck text-[var(--theme)]"></i>
                            O'quvchini izohi:
                          </strong>
                          <span>{studentComment}</span>
                        </div>
                      </div>
                    </div>
  
                  </div>
                  <br />
                  <div className="h-full overflow-y-auto">
                    <div className="table-horizontal-container">
                      <table className="table-information">
                        <tbody>
                          {
                            comments?.map((comment, index) => {
                              const type = ["payment","punishment", "bonus", "advance", "outcome" ]
                              const type_color = ["bg-[var(--yellow)]", "bg-[var(--red)]", "bg-[var(--purple)]", "bg-[var(--green)]","bg-[var(--oppocity-bg)]"]
                              const text = ["Qabulxona","Davomat", "To'lov", "Taklif", "Shikoyat"]
                              return(
                                <tr key={`comment-${index}`} className={`${type[comment.type_comment]}`}>
                                  <td className="w-[20px]">{index +1}</td>
                                  <td>{comment.comment}</td>
                                  <td><div className={`status_bar ${type_color[comment.type_comment]}`}>{text[comment.type_comment]}</div></td>
                                </tr>
                              )
                            })
                          }
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
  
                  <div className="flex">
                    <button
                      title="xabar berish"
                      onClick={()=>{
                        setModal(true);
                        setModalType(6)
                      }}
                      className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      boshqa izoh qo'shish
                    </button>
                  </div>
                </div>
              ),
            6: (
              <form className="content" action="" onSubmit={AddComment}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Izoh qo'shish</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid">
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setCommentType(newValue);
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        {
                          id: 1,
                          title: "Davomat",
                        },
                        {
                          id: 2,
                          title: "To'lov",
                        },
                        {
                          id: 3,
                          title: "Taklif",
                        },
                        {
                          id: 4,
                          title: "Shikoyat",
                        },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          required
                          label="Izoh turi"
                        />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={4}
                      placeholder="Izoh"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Izohni qo'shish
                  </button>
                </div>
              </form>
            ),
          }[modalType]
        }
      </div>
    </>
  );
};

const GroupAttendance = (props) => {
  const [studentMap, setStudentMap] = useState(null);
  const [allChecked, setAllChecked] = useState(null);
  const [groupAttendances, setGroupAttendances] = useState([]);

  function isCome(student, toDate) {
    let obj = groupAttendances.filter(
      (item) => item.to_date === toDate && item.student === student
    )[0];
    return obj?.come;
  }
  useEffect(() => {
    axios
      .get(`attendance/group/attendances/${props.groupId}/`)
      .then((res) => setGroupAttendances(res.data));

    if (props.details.students) {
      setStudentMap(
        props?.details?.students.map((item) => {
          return { ...item, checkedType: 0 };
        })
      );
    }
  }, [props.details.students]);

  function changeAttend(id, value) {
    let student = studentMap;
    for (let i = 0; i < student.length; i++) {
      if (student[i].id === id) {
        student[i] = { ...student[i], checkedType: value };
      }
    }
    setAllChecked(null);
    setStudentMap(student);
  }
  function allChangeValue(value) {
    setAllChecked(value);
    setStudentMap(
      studentMap.map((item) => {
        return { ...item, checkedType: value };
      })
    );
  }

  function sendQuery() {
    const student_attendance = studentMap.map((item) => {
      return { student_id: item.id, checkedType: item.checkedType };
    });

    axios
      .post(
        "attendance/accept-attendance/" + props.groupId + "/",
        student_attendance
      )
      .then((res) => {
        Notification(
          "Muvaffaqiyatli amalga oshirildi",
          "Davomat qabul qilindi (Sahifa yangilanadi)",
          "var(--green)",
          6000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 6000);
      })
      .catch((err) => {
        return Notification(
          "Hatolik yuz berdi",
          err.response.data,
          "var(--green)",
          6000
        );
      });
  }

  return (
    <>
      <h1 className="text-2xl">Guruh O'quvchilari</h1>
      <br />
      <div className="table-container">
        <div className="table-horizontal-container">
          <table className="table-border">
            <thead>
              <tr>
                <td className="md:hidden"></td>
                <th>
                  <span className="text-[var(--theme)] text-2xl">&#8470;</span>
                </th>
                <th>ISM & Familya</th>
                {props.details.has_lesson_today ? (
                  <th>
                    <AttendanceDrop
                      value={allChecked}
                      getID={(value) => allChangeValue(value)}
                    />
                  </th>
                ) : (
                  ""
                )}
                {props.details.passed_time.map((p, idx) => {
                  return <th key={`id-${idx}`}>{p}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {studentMap?.map((item, idx) => {
                const colors = [
                  "bg-[var(--green)]",
                  "bg-[var(--red)]",
                  "bg-[var(--yellow)]",
                  "bg-[var(--oppocity-bg)]",
                ];
                const texts = ["Keldi", "Kelmadi", "Sababli", "---"];
                return (
                  <tr key={`item-${idx}`} className="">
                    <td className="md:hidden"></td>
                    <td>{idx + 1}</td>
                    <td className="w-[200px]">
                      <span>{item.full_name}</span>
                    </td>
                    {props.details.has_lesson_today ? (
                      <td>
                        <AttendanceDrop
                          value={item.checkedType}
                          getID={(value) => changeAttend(item.id, value)}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    {props.details?.passed_time.map((p, idx) => {
                      const element = isCome(item.id, p);
                      return (
                        <td key={`k-${idx}`}>
                          {
                            <div
                              className={`status_bar ${
                                colors[parseInt(element)]
                              }`}
                            >
                              {texts[parseInt(element)]}
                            </div>
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {props.details.has_lesson_today && (
                <tr>
                  <td className="md:hidden"></td>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      onClick={sendQuery}
                      className="bg-[var(--theme)] hover:opacity-80 text-md text-white font-bold py-2 px-4 rounded-xl"
                    >
                      Yuborish
                    </button>
                  </td>
                  {props.details.passed_time.map((p, idx) => {
                    return <td key={`k1-${idx}`}></td>;
                  })}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const GroupJournal = (props) => {
  const [groupMark, setGroupMark] = useState([]);
  useEffect(() => {
    axios
      .get(`attendance/group/mark/${props.groupId}/`)
      .then((res) => setGroupMark(res.data));
  }, []);
  var passed_time = [];
  if (props?.details?.has_mark_checked) {
    passed_time = props.details?.passed_time?.slice(1);
  } else {
    passed_time = props.details?.passed_time;
  }
  function getMark(student, toDate) {
    let obj = groupMark.filter(
      (item) => item.to_date === toDate && item.student === student
    )[0];
    return obj?.come;
  }
  function sendMark(mark, student_id) {
    const formData = {
      mark: mark,
      student_id: student_id,
    };
    axios
      .post("attendance/send-mark/" + props.groupId + "/", formData)
      .then((res) => {
        return Notification(
          "Muvaffaqiyatli amalga oshirildi",
          "O'quvchiga baho qo'yildi",
          "var(--green)",
          5000
        );
      })
      .catch((err) => {
        return Notification(
          "Hatolik yuz berdi",
          "Baho qo'yib bo'lmadi",
          "var(--red)",
          5000
        );
      });
  }

  return (
    <>
      <h1 className="text-2xl">Guruh O'quvchilari</h1>
      <br />
      <div className="table-container">
        <div className="table-horizontal-container">
          <table className="table-border">
            <thead>
              <tr>
                <td className="md:hidden"></td>
                <th>
                  <span className="text-[var(--theme)] text-2xl">&#8470;</span>
                </th>
                <th>ISM & Familya {props.details.has_mark_checked}</th>
                {props.details.has_mark_checked ? (
                  <th>{new Date().toJSON().slice(0, 10)}</th>
                ) : (
                  ""
                )}
                {passed_time?.map((p, idx) => {
                  return <th key={`id-${idx}`}>{p}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {props?.details?.students?.map((item, idx) => {
                const colors = [
                  "",
                  "bg-[var(--red)]",
                  "bg-[#D2488A]",
                  "bg-[var(--yellow)]",
                  "bg-[var(--purple)]",
                  "bg-[var(--green)]",
                ];
                const marks = ["", "1", "2", "3", "4", "5"];
                return (
                  <tr key={`item-${idx}`} className="">
                    <td className="md:hidden"></td>
                    <td>{idx + 1}</td>
                    <td className="w-[200px]">
                      <span>{item.full_name}</span>
                    </td>
                    {props.details.has_mark_checked ? (
                      <td>
                        <MarkDrop
                          value={getMark(
                            item.id,
                            new Date().toJSON().slice(0, 10)
                          )}
                          getID={(e) => {
                            sendMark(e, item.id);
                          }}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    {passed_time?.map((p, idx) => {
                      const mark = getMark(item.id, p);
                      return (
                        mark !== 0 && (
                          <td key={`k-${idx}`}>
                            <div
                              className={`status_bar px-3 truncate ${
                                colors[parseInt(mark)]
                              }`}
                            >
                              {marks[mark]}
                            </div>
                          </td>
                        )
                      );
                    })}
                  </tr>
                );
              })}
              {/* <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <button className="bg-[var(--theme)] hover:opacity-80 text-md text-white font-bold py-2 px-4 rounded-xl">Yuborish</button>
                                </td>
                            </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const GroupPayment = (props) => {
  const [groupPayments, setGroupPayments] = useState([]);

  useEffect(() => {
    axios
      .get("budget/group-payments/" + props.groupId + "/")
      .then((response) => {
        setGroupPayments(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getGroupPayment = (studentId, paymentMonth) => {
    if(studentId === 260){
      console.log('h')
    }
    let obj = groupPayments.filter(
      (item) => item.student === studentId && item.month === paymentMonth
    )[0];
    return obj;
  };

  return (
    <>
      <h1 className="text-2xl">Guruh O'quvchilari</h1>
      <br />
      <div className="table-container">
        <div className="table-horizontal-container">
          <table className="table-border">
            <thead>
              <tr>
                <td className="md:hidden"></td>
                <th>
                  <span className="text-[var(--theme)] text-2xl">&#8470;</span>
                </th>
                <th>ISM & Familya</th>
                {props?.details?.payment_months?.map((month, idx) => {
                  return <th key={"th-month" + idx}>{month}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {props?.details?.students?.map((item, idx) => {
                return (
                  <tr key={"student_payment" + idx}>
                    <td className="md:hidden"></td>
                    <td>{idx + 1}</td>
                    <td className="w-[200px]">
                      <span>{item.full_name}</span>
                    </td>
                    {props?.details?.payment_months?.map((month, index) => {
                      const obj = getGroupPayment(item.id, month);
                      if (obj) {
                        return obj?.is_payed && obj?.is_finished ? (
                          <td key={"p" + index}>
                            <div className="status_bar bg-[var(--green)]">
                              {visibleNumber(obj.summ)}
                            </div>
                          </td>
                        ) : obj?.is_payed === true &&
                          obj?.is_finished === false ? (
                          <td key={"p" + index}>
                            <div className="status_bar bg-[var(--yellow)]">
                              {visibleNumber(obj.summ)}
                            </div>
                          </td>
                        ) : (
                          <td key={"p" + index}>
                            <div className="status_bar bg-[var(--red)]">
                              Bermadi
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td key={"p" + index}>
                            <div className="status_bar py-2 px-2"></div>
                          </td>
                        );
                      }
                    })}
                    {/* <td><div className='status_bar bg-[var(--green)]'>220 000</div></td> */}
                    {/* <td><div className='status_bar bg-[var(--yellow)]'>180 000</div></td> */}
                    {/* <td><div className='status_bar bg-[var(--red)]'>Bermadi</div></td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const WeekdaysOption = [
  { label: "Dushanba", id: 0 },
  { label: "Seshanba", id: 1 },
  { label: "Chorshanba", id: 2 },
  { label: "Payshanba", id: 3 },
  { label: "Juma", id: 4 },
  { label: "Shanba", id: 5 },
  { label: "Yakshanba", id: 6 },
];
