import { useState, useEffect } from "react";
import studentstyles from "../../assets/css/reception.module.css";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import axios from "axios";

import { MenuItem, Autocomplete, TextField } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import Notification from "../../components/alert";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
});

export function SendAPIToCollections(id, obj) {
  axios
    .post(`/leads/collection-student/${id}/`, {
      students: obj.map((obj) => obj.id),
    })
    .then(function (response) {
      // console.log(response.request.status);
    })
    .catch(function (err) {
      Notification(
        "nimadir hato ketdi",
        "bazi obyektlar alloqachon qo'shilgan ",
        "var(--yellow)",
        5000
      );
    });
}
export function SendAPIToStudentSet(id, obj) {
  axios
    .post(`/leads/student-sets-students/${id}/`, {
      students: obj.map((obj) => obj.id),
    })
    .then(function (response) {})
    .catch(function (error) {
      Notification(
        "Internet bilan muomo",
        "Nimadir ishlamayapdi",
        "var(--yellow)",
        5000
      );
    });
}
export default function Reception() {
  const [students, setStudent] = useState([]);
  const [collection, setCollection] = useState([]);
  const [bundles, setBundles] = useState([]);

  const [listStudent, setlistStudent] = useState([]);
  const [listCollection, setListCollec] = useState([]);
  const [listBundles, setListBundles] = useState([]);
  const [filterStudent, setFilterStudent] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [whereClick, setWhereClick] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subject, setSubject] = useState(null);
  const [GradeValue, SetGradeValue] = useState(null);

  const [collectionOptions, setCollectionOptions] = useState([]);
  const [selectedCol, setSelectedCol] = useState(null);
  const [setsOption, setSetsOption] = useState([]);
  const [valueSets, setValueSets] = useState(null);

  const [Wherelist, setWherelist] = useState([]);
  const [whereValue, setWhereValue] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [sms, setSms] = useState("Assalomu alaykum @oquvchi");
  

  useEffect(() => {
    axios
      .all([
        axios.get("main/subject/"),
        axios.get("leads/student-collections/"),
        axios.get("leads/student-set/"),
        axios.get("/leads/where-list/"),
      ])
      .then((response) => {
        setSubjects(response[0].data);
        setCollectionOptions(response[1].data);
        setSetsOption(response[2].data);
        setWherelist(response[3].data);
      })
      .catch((error) => {
        setErrorPage(true)
        console.log(error);
      });
    setIsLoading(false);
  }, [useLocation()]);

  useEffect(() => {
    if (selectedCol !== null) {
      axios.get("leads/student-collections/" + selectedCol.id).then((response) => {
        setCollection(response.data.students);
      })    
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
    } else {
      setCollection([]);
    }
  }, [selectedCol]);

  useEffect(() => {
    if (valueSets) {
      axios.get("leads/student-set/" + valueSets.id).then((response) => {
        setBundles(response.data.students);
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
    } else {
      setBundles([]);
    }
  }, [valueSets]);

  useEffect(() => {

    if (subject !== null) {
      axios.get("main/grade/" + subject.id + "/").then((response) => {
        setGrades(response.data);
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
      axios.get("accounts/students/" + subject.id).then((response) => {
        setStudent(response.data);
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
      SetGradeValue(null);
    } else {
      setGrades([]);
      SetGradeValue(null);
    }
  }, [subject]);

  useEffect(() => {
    if (GradeValue && GradeValue !== null) {
      axios
        .get(`accounts/students/${subject.id}/${GradeValue.id}/`)
        .then((response) => {
          setStudent(response.data);
        })
        .catch((error) => {
          Notification(
            "Tog'ri to'ldiring",
            "Yoki keyinroq urinib koring",
            "var(--red)",
            5000
          );
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        });
    } else {
      SetGradeValue(null);
      if (subject !== null) {
        axios.get("main/grade/" + subject.id + "/").then((response) => {
          setGrades(response.data);
        })
        .catch((error) => {
          Notification(
            "Tog'ri to'ldiring",
            "Yoki keyinroq urinib koring",
            "var(--red)",
            5000
          );
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        });
        axios.get("accounts/students/" + subject.id).then((response) => {
          setStudent(response.data);
        })
        .catch((error) => {
          Notification(
            "Tog'ri to'ldiring",
            "Yoki keyinroq urinib koring",
            "var(--red)",
            5000
          );
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        });
      }
    }
  }, [GradeValue]);

  useEffect(() => {
    setFilterStudent(students)
  },[students])

  useEffect(() => {
    if (collection !== null && selectedCol) {
      SendAPIToCollections(selectedCol.id, collection);
    }
  }, [collection]);
  useEffect(() => {
    if (bundles.length !== null && valueSets) {
      SendAPIToStudentSet(valueSets.id, bundles);
    }
  }, [bundles]);
  const classes = useStyles();


  if (!errorPage){
    const handleCheckStudentChange = (e) => {
      if (e.target.checked) {
        const allstudents = students.map((c) => c);
        setlistStudent(allstudents);
      } else {
        setlistStudent([]);
      }
    };
    const handleCheckCollecChange = (e) => {
      if (e.target.checked) {
        const allstudents = collection.map((c) => c);
        setListCollec(allstudents);
      } else {
        setListCollec([]);
      }
    };
    const handleCheckBundlesChange = (e) => {
      if (e.target.checked) {
        const allbundles = bundles.map((c) => c);
        setListBundles(allbundles);
      } else {
        setListBundles([]);
      }
    };
  
    const handleStudentChange = (e, student) => {
      if (e.target.checked) {
        setlistStudent([...listStudent, student]);
      } else {
        setlistStudent(listStudent.filter((item) => item !== student));
      }
    };
    const handleCollectionChange = (e, student) => {
      if (e.target.checked) {
        setListCollec([...listCollection, student]);
      } else {
        setListCollec(listCollection.filter((item) => item !== student));
      }
    };
    const handleBoundleChange = (e, student) => {
      if (e.target.checked) {
        setListBundles([...listBundles, student]);
      } else {
        setListBundles(listBundles.filter((item) => item !== student));
      }
    };  
    function FormSubmit(e) {
      e.preventDefault();
      let data = new FormData(e.target);
      let full_name = data.get("full_name");
      let phone = data.getAll("phone");
      let parent_phone = data.getAll("parent_phone");
      let birthday = data.getAll("birthday");
      let add_where = data.getAll("add_where");
      let comment = data.getAll("comment");
      if (phone[0]?.length === 17 ) {
        if (full_name.trim().length > 4) {
          if (birthday[0]?.length === 10) {
            if (subject && whereValue && GradeValue && e.target.checkValidity()) {
              let form = {
                full_name: full_name,
                phone: phone[0],
                parent_phone: parent_phone[0],
                birthday: birthday[0],
                where: whereValue,
                subject: subject.id,
                grade: GradeValue.id,
                comment: comment[0],
              };
              axios
                .post("accounts/students/create/", form)
                .then(function (response) {
                  if (response.status === 201) {
                    e.target.reset();
                    let obj = {
                      id: response.data.id,
                      full_name: full_name,
                      phone: phone[0],
                      parent_phone: phone[0],
                    };
                    setStudent([...students, obj]);
                    switch (add_where[0]) {
                      case "1":
                        if (selectedCol) {
                          setCollection([...collection, obj]);
                        }
                        break;
                      case "2":
                        if (valueSets) {
                          setBundles([...bundles, obj]);
                        }
                        break;
                      default:
                      // code block
                    }
                    Notification(
                      "Qo'shildi",
                      "Mufaqiyatli amalga oshdi",
                      "var(--green)",
                      5000
                    );
                  }
                })
                .catch((error) => {
                  Notification(
                    "Tog'ri to'ldiring",
                    "Yoki keyinroq urinib koring",
                    "var(--red)",
                    5000
                  );
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 3000);
                });
            } else {
              Notification(
                "XATO !!!",
                "Hamma joyni to'g'ri to'ldiring",
                "var(--red)",
                5000
              );
            }
          } else {
            Notification("XATO !!!", "Sana xato yozildi", "var(--red)", 5000);
          }
        } else {
          Notification(
            "XATO !!!",
            "Ism familya xato yozildi",
            "var(--red)",
            5000
          );
        }
      } else {
        Notification(
          "XATO !!!",
          "Hamma Telefon raqamni tog'ri to'ldiring",
          "var(--red)",
          5000
        );
      }
    }
    function addCollection(e){
      e.preventDefault();
      let data = new FormData(e.target);
      axios
      .post("leads/collections-create/", {
        title: data.get("title"),
        time_alert: data.get('datetime'),
      })
      .then(function (response) {
        e.target.reset()
        Notification(
          "Qo'shildi",
          "Mufaqiyatli amalga oshdi",
          "var(--green)",
          5000
        );
        setCollectionOptions([...collectionOptions, response.data])
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
    }
    function addStudentSets(e){
      e.preventDefault();
      let data = new FormData(e.target);
      axios
      .post("leads/student-set-create/", {
        title: data.get("title"),
        time_alert: data.get('datetime'),
      })
      .then(function (response) {
        e.target.reset()
        Notification(
          "Qo'shildi",
          "Mufaqiyatli amalga oshdi",
          "var(--green)",
          5000
        );
        setSetsOption([...setsOption, response.data])
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
    }
  
    function phoneKeyup(e) {
      var y = e.target.value.replaceAll("+998 ", "").replaceAll(" ", "");
      var x = y.replaceAll(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
      if (y > 0) {
        e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
          x[3] ? " " + x[3] : ""
        }${x[4] ? " " + x[4] : ""}`;
      } else {
        e.target.value = "";
      }
    }
    function dateKeyup(e) {
      var y = e.target.value.replaceAll("/", "");
      var x = y.replaceAll(/\D/g, "").match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
      if (e.target.value.length) {
        if (+x[2] > 12) {
          x[2] = "12";
        }
        if (x[2] === "00") {
          x[2] = "01";
        }
        var checkDate = new Date(x[1], x[2], 0).getDate();
        if (+x[3] > checkDate) {
          x[3] = checkDate;
        }
        if (x[3] === "00") {
          x[3] = "01";
        }
        e.target.value = `${x[1] ? x[1] : ""}${x[2] ? "-" + x[2] : ""}${
          x[3] ? "-" + x[3] : " "
        }`;
      } else {
        e.target.value = "";
      }
    }
  
    function dragstart_handler(e) {
      // Add the target element's id to the data transfer object
      e.dataTransfer.setData("text", e.target.id);
      e.dataTransfer.setData("parent", e.target.parentElement.id);
      e.dataTransfer.effectAllowed = "move";
    }
    function dragover_handler(e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    }
    function drop_handler(e) {
      e.preventDefault();
      try {
        var data = parseInt(e.dataTransfer.getData("text"));
        var from = e.target.parentElement.parentElement.id;
        var to = e.dataTransfer.getData("parent");
        var obj = [];
        if (to !== "" && from !== "") {
          if (to === "receptions") {
            if (from === "bundles") {
              if (valueSets) {
                var detect = listStudent.filter((item) => item.id === data);
                if (detect.length > 0) {
                  let sorted_arr = listStudent.filter(
                    (listItem) =>
                      !bundles.some((vendor) => vendor.id === listItem.id)
                  );
                  if (sorted_arr.length !== listStudent.length) {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
  
                  setlistStudent(
                    listStudent.filter((item) => !sorted_arr.includes(item))
                  );
                  setBundles([...bundles, ...sorted_arr]);
                } else {
                  let sorted_arr = !bundles.some((vendor) => vendor.id === data);
                  if (sorted_arr) {
                    obj = students.filter((item) => !data || item.id === data);
  
                    setlistStudent(
                      listStudent.filter((item) => item.id !== data)
                    );
                    setBundles([...bundles, obj[0]]);
                  } else {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                }
              } else {
                Notification(
                  "Tog'ri Bajaring",
                  'Avval "Tayyor"ni tanlang"',
                  "var(--yellow)",
                  5000
                );
              }
            }
            if (from === "collection") {
              if (selectedCol) {
                detect = listStudent.filter((item) => item.id === data);
                if (detect.length > 0) {
                  let sorted_arr = listStudent.filter(
                    (listItem) =>
                      !collection.some((vendor) => vendor.id === listItem.id)
                  );
                  if (sorted_arr.length !== listStudent.length) {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                  setlistStudent(
                    listStudent.filter((item) => !sorted_arr.includes(item))
                  );
                  setCollection([...collection, ...sorted_arr]);
                } else {
                  let sorted_arr = !collection.some(
                    (vendor) => vendor.id === data
                  );
                  if (sorted_arr) {
                    obj = students.filter((item) => !data || item.id === data);
  
                    setlistStudent(
                      listStudent.filter((item) => item.id !== data)
                    );
                    setCollection([...collection, obj[0]]);
                  } else {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                }
              } else {
                Notification(
                  "Tog'ri Bajaring",
                  'Avval "Toplanmi" tanlang"',
                  "var(--yellow)",
                  5000
                );
              }
            }
          }
  
          if (to === "collection") {
            if (from === "bundles") {
              if (valueSets) {
                let detect = listCollection.filter((item) => item.id === data);
                if (detect.length > 0) {
                  let list = listCollection;
                  let sorted_arr = list.filter(
                    (listItem) =>
                      !bundles.some((vendor) => vendor.id === listItem.id)
                  );
  
                  if (sorted_arr.length !== list.length) {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
  
                  var sender_arr = collection.filter(
                    (item) => !sorted_arr.includes(item)
                  );
                  axios
                    .all([
                      axios.post(`leads/collection-student/${selectedCol.id}/`, {
                        students: sender_arr.map((item) => item.id),
                      }),
  
                      axios.post(`leads/student-sets-students/${valueSets.id}/`, {
                        students: [...bundles, ...sorted_arr].map(
                          (item) => item.id
                        ),
                      }),
                    ])
                    .then(function (response) {
                      setListCollec(
                        listCollection.filter(
                          (item) => !sorted_arr.includes(item)
                        )
                      );
                      setCollection(
                        collection.filter((item) => !sorted_arr.includes(item))
                      );
                      setBundles([...bundles, ...sorted_arr]);
                    });
                } else {
                  let check = bundles.filter((item) => !data || item.id === data);
                  obj = collection.filter((item) => !data || item.id === data);
                  if (check.length === 0) {
                    sender_arr = collection.filter((item) => item.id !== data);
                    setListCollec(
                      listCollection.filter((item) => item.id !== data)
                    );
                    setCollection(collection.filter((item) => item.id !== data));
                    setBundles([...bundles, obj[0]]);
                  } else {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                }
              } else {
                Notification(
                  "Tanlanmadi",
                  'avval qaysi "Tayyor" to\'plamligini tanlang',
                  "var(--yellow)",
                  5000
                );
              }
            }
            if (from === "receptions") {
              let detect = listCollection.filter((item) => item.id === data);
              if (detect.length > 0) {
                let sorted_arr = listCollection;
  
                setListCollec(
                  listCollection.filter((item) => !sorted_arr.includes(item))
                );
                setCollection(
                  collection.filter((item) => !sorted_arr.includes(item))
                );
              } else {
                obj = collection.filter((item) => !data || item.id === data);
                setListCollec(listCollection.filter((item) => item.id !== data));
                setCollection(collection.filter((item) => item.id !== data));
              }
            }
          }
  
          if (to === "bundles") {
            if (from === "collection") {
              if (selectedCol) {
                let detect = listBundles.filter((item) => item.id === data);
                if (detect.length > 0) {
                  let sorted_arr = listBundles.filter(
                    (listItem) =>
                      !collection.some((vendor) => vendor.id === listItem.id)
                  );
                  if (sorted_arr.length !== listBundles.length) {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                  sender_arr = bundles.filter(
                    (item) => !sorted_arr.includes(item)
                  );
  
                  obj = bundles.filter((item) => sorted_arr.includes(item));
                  setListBundles(
                    listBundles.filter((item) => !sorted_arr.includes(item))
                  );
                  setBundles(
                    bundles.filter((item) => !sorted_arr.includes(item))
                  );
                  setCollection([...collection, ...sorted_arr]);
                } else {
                  let check = collection.filter((item) => item.id === data);
                  obj = bundles.filter((item) => item.id === data);
                  if (check.length === 0) {
                    setCollection([...collection, ...obj]);
                    setBundles(bundles.filter((item) => item.id !== data));
                  } else {
                    Notification(
                      "nimadir hato ketdi",
                      "bazi obyektlar alloqachon qo'shilgan ",
                      "var(--yellow)",
                      5000
                    );
                  }
                }
              } else {
                Notification(
                  "Tanlanmadi",
                  "avval qaysi to'plamligini tanlang",
                  "var(--yellow)",
                  5000
                );
              }
            }
            if (from === "receptions") {
              let detect = listBundles.filter((item) => item.id === data);
              if (detect.length > 0) {
                let sorted_arr = listBundles;
  
                setListCollec(
                  listBundles.filter((item) => !sorted_arr.includes(item))
                );
                setBundles(bundles.filter((item) => !sorted_arr.includes(item)));
              } else {
                sender_arr = bundles.filter((item) => item.id !== data);
  
                setListBundles(listBundles.filter((item) => item.id !== data));
                setBundles(bundles.filter((item) => item.id !== data));
              }
            }
          }
        }
      } catch (err) {}
  
      // if((!data.includes("tel")) && e.target.tagName != "A"){
      //     e.target.parentElement.parentElement.appendChild(document.getElementById(data))
      // }
    }

    function DeleteCollection(e) {
      e.preventDefault();
      let arr = listCollection.map((item) => item.id);
      let data = new FormData(e.target);
      axios
        .post(`/accounts/students/list/`, {
          ids: arr,
          reason: data.get('reason')
        })
        .then(function (response) {
          setCollection(collection.filter((item) => !arr.includes(item.id)));
          setListCollec(listCollection.filter((item) => !arr.includes(item.id)));
          setModal(false);
          setModalType(0);
          Notification("muvaffaqiyatli", "O'quvchilar o'chirildi", 5000);
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--yellow)",
            5000
          );
        });
    }
    function BundlesDelete(e) {
      e.preventDefault();
      let arr = listBundles.map((item) => item.id);
      let data = new FormData(e.target);
      axios
        .post(`/accounts/students/list/`, {
          ids: arr,
          reason: data.get('reason')
        })
        .then(function (response) {
          setBundles(bundles.filter((item) => !arr.includes(item.id)));
          setListBundles(listBundles.filter((item) => !arr.includes(item.id)));
          setModal(false);
          setModalType(0);
          Notification("muvaffaqiyatli", "O'quvchilar o'chirildi", 5000);
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--yellow)",
            5000
          );
        });
    }
    function StudentDelete(e) {
      e.preventDefault();
      let arr = listStudent.map((item) => item.id);
      let data = new FormData(e.target);
      axios
        .post(`accounts/students/list/`, {
          ids: arr,
          reason: data.get('reason')
        })
        .then(function (response) {
          setStudent(students.filter((item) => !arr.includes(item.id)));
          setlistStudent(listStudent.filter((item) => !arr.includes(item.id)));
          setModal(false);
          setModalType(0);
          Notification("muvaffaqiyatli", "O'quvchilar o'chirildi", 5000);
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--yellow)",
            5000
          );
        });
    }
    
    function SortStudent(e){
      let simple = []
      for (let item of students) {
        if (item.full_name.toLowerCase().includes(e.target.value.toLowerCase())
            || item.parent_phone.includes(e.target.value)
            || item.phone.includes(e.target.value)
            ){
              simple.push(item)
        }
      }
      setFilterStudent(simple)
    }
    function sendToCollection(){
      if (!selectedCol){
        Notification(
          "Xato bajarildi",
          "Qaysi to'plamligini tanlang",
          "var(--yellow)",
          5000
        );
      }
      if (!listStudent.length){
        Notification(
          "Xato bajarildi",
          "Tanlangan o'quvchilar bo'm bo'sh",
          "var(--yellow)",
          5000
        );
      }
      if(selectedCol && listStudent.length){
        axios.post(`leads/collection-student/${selectedCol.id}/`, {
          my_field: 'update',
          students: listStudent.map((item) => item.id)
        })
        .then((response) => {
            Notification(
              "muvaffaqiyatli",
              "O'quvchilar To'plamga qo'shildi",
              "var(--green)",
              5000
            );
            let sorted_arr = listStudent.filter(
              (listItem) =>
                !collection.some((vendor) => vendor.id === listItem.id)
            );
            if (sorted_arr.length !== listStudent.length) {
              Notification(
                "nimadir hato ketdi",
                "bazi obyektlar alloqachon qo'shilgan ",
                "var(--yellow)",
                5000
              );
            }
            setlistStudent(
              listStudent.filter((item) => !sorted_arr.includes(item))
            );
            setCollection([...collection, ...sorted_arr]);
            
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--red)",
            5000
          );
        });
      }
    }
    function sendToStudentSets(){
      if (!valueSets){
        Notification(
          "Xato bajarildi",
          "Qaysi 'Tayyor To'plamligi' tanlang",
          "var(--yellow)",
          5000
        );
      }
      if (!listStudent.length){
        Notification(
          "Xato bajarildi",
          "Tanlangan o'quvchilar bo'm bo'sh",
          "var(--yellow)",
          5000
        );
      }
      if(valueSets && listStudent.length){
        axios.post(`leads/student-sets-students/${valueSets.id}/`, {
          my_field: 'update',
          students: listStudent.map((item) => item.id)
        })
        .then((response) => {
          setBundles(listStudent)
            Notification(
              "muvaffaqiyatli",
              "O'quvchilar To'plamga qo'shildi",
              "var(--green)",
              5000
            );
            let sorted_arr = listStudent.filter(
              (listItem) =>
                !bundles.some((vendor) => vendor.id === listItem.id)
            );
            if (sorted_arr.length !== listStudent.length) {
              Notification(
                "nimadir hato ketdi",
                "bazi obyektlar alloqachon qo'shilgan ",
                "var(--yellow)",
                5000
              );
            }
  
            setlistStudent(
              listStudent.filter((item) => !sorted_arr.includes(item))
            );
            setBundles([...bundles, ...sorted_arr]);
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--red)",
            5000
          );
        });
      }
    }

    const handleSmsList = () => {
      var studentIds = [];
      listCollection.forEach((item) => {studentIds.push(item.id)})
      const formData = {
        students: studentIds,
        message: sms
      }
      axios
        .post("sms/send/", formData)
        .then(res => {
          return Notification("Muvaffaqiyatli amalga oshirildi", "Sms habarlar yuborildi", "var(--green)", 5000)
        }).catch(err => Notification("Xatolik", "Sms habarlar yuborilmadi", "var(--red)", 5000))
    }
    
    const handListBundles = () => {
      var studentIds = [];
      listBundles.forEach((item) => {studentIds.push(item.id)})
      const formData = {
        students: studentIds,
        message: sms
      }
      axios
        .post("sms/send/", formData)
        .then(res => {
          return Notification("Muvaffaqiyatli amalga oshirildi", "Sms habarlar yuborildi", "var(--green)", 5000)
        }).catch(err => Notification("Xatolik", "Sms habarlar yuborilmadi", "var(--red)", 5000))
    }
  
    return (
      <>
        <div className={studentstyles.main_page} id="main">
          <br />
          <div className="grid 2xl:grid-cols-3 xl:grid-cols-2  gap-4">
            <div className="br-card">
              <h2 className="text-3xl m-3 font-bold">
                
                <NavLink
                  to="/reception/student"
                  // style={{ color: "var(--theme)" }}
                  className={"underline hover:no-underline"}
                >
                  O'quvchilar. &#160;
                </NavLink>
              </h2>
              <br />
              <div className={studentstyles.header_content}>
                <div className="flex gap-4 flex-wrap my-4">
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSubject(newValue);
                      } else {
                        setSubject(null);
                      }
                    }}
                    disablePortal
                    id="combo-box-demo"
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={subjects}
                    getOptionLabel={(option) => option.title}
                    style={{ width: "200px" }}
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Fan" />
                    )}
                  />
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        SetGradeValue(newValue);
                      } else {
                        SetGradeValue(null);
                      }
                    }}
                    value={GradeValue}
                    getOptionLabel={(option) => option.title || ""}
                    disablePortal
                    id="combo-box-demo"
                    options={grades}
                    style={{ width: "200px" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    classes={{ paper: classes.paper }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Daraja" />
                    )}
                  />
                    <TextField
                      style={{ width: "200px" }}
                      onChange={(e) => SortStudent(e)}
                      type="text"
                      label="Qidiruv"
                      size="small"
                    />
                </div>
                <div className="flex gap-4 items-center flex-wrap my-4">
                  <button
                    className={`${studentstyles.button}  text-[var(--purple)]`}
                    onClick={function () {
                      setModalType(7);
                      setWhereClick(0);
                      setModal(true);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 7H28"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25 4V10"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                  onClick={function(){
                    if(listStudent.length > 0){
                      setModalType(8); setModal(true)
                    }
                    else{
                      Notification(
                        "Xato bajarildi",
                        "Avval o'quvchilarni tanlang",
                        "var(--yellow)",
                        5000
                      );
                    }
                  }}
                    className={`${studentstyles.button}  text-[var(--blue)]`}
                  >
                    <i className="fa-regular fa-grid-round-2-plus text-xl"></i>
                  </button>
                  <button
                    className={`${studentstyles.button}`}
                    onClick={function () {
                      setModalType(4);
                      setWhereClick(0);
                      setModal(true);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.687 7.4375L5.31201 7.43751"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8125 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.1875 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button className={`${studentstyles.button}`}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.75 13.7515L16 19.0001L21.25 13.7515"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 5V18.9963"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className={studentstyles.table_cap}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className={studentstyles.checkbox}
                          checked={
                            students.length === listStudent.length &&
                            students.length !== 0
                          }
                          onChange={(e) => handleCheckStudentChange(e)}
                        />
                      </th>
                      <th>Ism & familya</th>
                      <th>Telefon raqami </th>
                    </tr>
                  </thead>
                  <tbody
                    id="receptions"
                    onDrop={(e) => drop_handler(e)}
                    onDragOver={(e) => dragover_handler(e)}
                  >
                    {filterStudent.length ? (
                      filterStudent.map((student, index) => (
                        <tr
                          draggable="true"
                          id={student.id}
                          onDragStart={(e) => dragstart_handler(e)}
                          className={studentstyles.row}
                          key={`recep-${index}`}
                        >
                          <td>
                            <input
                              type="checkbox"
                              id="checkbox"
                              className={studentstyles.checkbox}
                              checked={listStudent.includes(student)}
                              onChange={(e) => handleStudentChange(e, student)}
                            />
                          </td>
                          <td>
                            <span>{student.full_name}</span>
                          </td>
                          <td>
                            <ul>
                              <li>
                                <a href={`tel:${student.phone}`}>
                                  {student.phone}
                                </a>
                              </li>
                              <li>
                                <a href={`tel:${student.parent_phone}`}>
                                  {student.parent_phone}
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <th colSpan={5}>Bu jild bo'sh</th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <br />
            </div>
            <div className="br-card">
              <h2 className="text-3xl m-3 font-bold">
                <NavLink to='collection' className={"underline hover:no-underline"}>
                  To'plamlar. &nbsp;
                </NavLink>
                <sub>
                  <span
                    onClick={function () {
                      setModal(true);
                      setModalType(9)
                    }}
                    style={{ color: "var(--theme)" }}
                    className={"underline hover:no-underline cursor-pointer text-base"}
                  >
                    To'plam qo'shish
                  </span>
                </sub>
              </h2>
              <br />
              <div className={`${studentstyles.header_content} flex items-center`}>
                <Autocomplete
                  className="my-4"
                  onChange={(event, newValue) => {setSelectedCol(newValue); }}
                  value={selectedCol}
                  disablePortal
                  id="combo-box-demo"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.title}
                  options={collectionOptions}
                  style={{ width: "200px" }}
                  classes={{ paper: classes.paper }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={`seleccol-${option.id}`}>
                        {option.title}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="To'plamlar" />
                  )}
                />
                <div className="flex gap-4 items-center flex-wrap my-4">
                  <button
                    className={`${studentstyles.button}  text-[var(--purple)]`}
                    onClick={function () {
                      if (selectedCol != null) {
                        setModalType(7);
                        setWhereClick(1);
                        setModal(true);
                      } else {
                        Notification(
                          "Tog'ri tanlang",
                          'Avval "Toplanmi tanlang"',
                          "var(--yellow)",
                          5000
                        );
                      }
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 7H28"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25 4V10"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={function () {
                      setModalType(0);
                      setModal(true);
                    }}
                    className={`${studentstyles.button} text-[var(--yellow)]`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28 7L16 18L4 7"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8184 16L4.30859 24.7174"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27.6916 24.7175L18.1816 16"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={function () {
                      setModalType(2);
                      setModal(true);
                    }}
                    className={`${studentstyles.button}`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.687 7.4375L5.31201 7.43751"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8125 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.1875 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button className={`${studentstyles.button}`}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.75 13.7515L16 19.0001L21.25 13.7515"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 5V18.9963"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className={studentstyles.table_cap}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className={studentstyles.checkbox}
                          checked={
                            collection?.length === listCollection?.length &&
                            collection?.length !== 0
                          }
                          onChange={handleCheckCollecChange}
                        />
                      </th>
                      <th>Ism & familya</th>
                      <th>Telefon raqami </th>
                    </tr>
                  </thead>
                  <tbody
                    id="collection"
                    onDrop={(e) => drop_handler(e)}
                    onDragOver={(e) => dragover_handler(e)}
                  >
                    {collection?.length ? (
                      collection?.map((student, index) => (
                        <tr
                          draggable="true"
                          id={student.id}
                          onDragStart={(e) => dragstart_handler(e)}
                          className={studentstyles.row}
                          key={`collection-${index}`}
                        >
                          <td draggable="false">
                            <input
                              type="checkbox"
                              id="checkbox"
                              className={studentstyles.checkbox}
                              checked={listCollection.includes(student)}
                              onChange={(e) => handleCollectionChange(e, student)}
                            />
                          </td>
                          <td>
                            <span>{student.full_name}</span>
                          </td>
                          <td draggable="false">
                            <ul draggable="false">
                              <li>
                                <a href="tel:+998972361677">{student.phone}</a>
                              </li>
                              <li>
                                <a href="tel:+998972361677">
                                  {student.parent_phone}
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Bu jild bo'sh</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="br-card">
              <h2 className="text-3xl m-3 font-bold">
                
                <NavLink to="students-sets/" className={"underline hover:no-underline"}>Tayyor.</NavLink>&nbsp;
                <sub>
                  <span
                    onClick={function () {
                      setModal(true);
                      setModalType(10)
                    }}
                    style={{ color: "var(--theme)" }}
                    className={"underline hover:no-underline cursor-pointer text-base"}
                  >
                    Tayyor to'plam qo'shish
                  </span>
                </sub>
              </h2>
              <br />
              <div className={studentstyles.header_content}>
                <Autocomplete
                  className="my-4"
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setValueSets(newValue);
                    } else {
                      setValueSets(null);
                    }
                  }}
                  value={valueSets}
                  disablePortal
                  id="combo-box-demo"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.title || ""}
                  options={setsOption}
                  style={{ width: "250px" }}
                  classes={{ paper: classes.paper }}
                  renderInput={(params) => (
                    <TextField {...params} label="To'plamlar" />
                  )}
                />
                <div className="flex gap-4 items-center flex-wrap my-4">
                  <button
                    className={`${studentstyles.button}   text-[var(--purple)]`}
                    onClick={function () {
                      if (valueSets !== null) {
                        setModalType(7);
                        setWhereClick(2);
                        setModal(true);
                      } else {
                        Notification(
                          "Tog'ri tanlang",
                          'Avval "Tayyorni" tanlang"',
                          "var(--yellow)",
                          5000
                        );
                      }
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 7H28"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25 4V10"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                        stroke="#7C90DB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={function () {
                      setModalType(1);
                      setModal(true);
                    }}
                    className={`${studentstyles.button} text-[var(--yellow)]`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28 7L16 18L4 7"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8184 16L4.30859 24.7174"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27.6916 24.7175L18.1816 16"
                        stroke="#FFCC00"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={function () {
                      setModalType(6);
                      setModal(true);
                    }}
                    className={`${studentstyles.button}`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.687 7.4375L5.31201 7.43751"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.8125 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.1875 13.8125V22.3125"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                        stroke="#FC3F1D"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button className={`${studentstyles.button}`}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.75 13.7515L16 19.0001L21.25 13.7515"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 5V18.9963"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
                        stroke="#4FB286"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className={studentstyles.table_cap}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className={studentstyles.checkbox}
                          checked={
                            bundles?.length === listBundles?.length &&
                            bundles?.length !== 0
                          }
                          onChange={(e) => handleCheckBundlesChange(e)}
                        />
                      </th>
                      <th>Ism & familya</th>
                      <th>Telefon raqami </th>
                    </tr>
                  </thead>
                  <tbody
                    id="bundles"
                    onDrop={(e) => drop_handler(e)}
                    onDragOver={(e) => dragover_handler(e)}
                  >
                    {bundles?.length ? (
                      bundles.map((student, index) => (
                        <tr
                          draggable="true"
                          id={student.id}
                          onDragStart={(e) => dragstart_handler(e)}
                          className={studentstyles.row}
                          key={`boundles-${index}`}
                        >
                          <td>
                            <input
                              type="checkbox"
                              id="checkbox"
                              className={studentstyles.checkbox}
                              checked={listBundles.includes(student)}
                              onChange={(e) => handleBoundleChange(e, student)}
                            />
                          </td>
                          <td>
                            <span>{student.full_name}</span>
                          </td>
                          <td>
                            <ul>
                              <li>
                                <a href={`tel:${student.phone}`}>
                                  {student.phone}
                                </a>
                              </li>
                              <li>
                                <a href={`tel:${student.parent}`}>
                                  {student.parent_phone}
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>Bu jild bo'sh</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal ${modal && "active"}`}>
          {
            {
              // XABAR YOZISH
              0: (
                <div className="content">
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {listCollection.length} ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--yellow)]"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{
                        border: "1px solid var(--yellow)",
                        outline: "var(--yellow)",
                      }}
                      color="var(--yellow)"
                      placeholder="O'quvchilar"
                      HandleChange={(event, value) => setListCollec(value)}
                      value={listCollection}
                      options={collection}
                    />
                    <textarea
                      name=""
                      rows={7}
                      value={sms}
                      onChange={e => setSms(e.target.value)}
                      className="textarea"
                      placeholder="Xabar yozish (majburiy)"
                    ></textarea>
                  </div>
                  <h3 className="text-base">
                    @oquvchi - Oquvchining ism familyasi
                  </h3>
                  <div className="flex">
                    <button
                      onClick={handleSmsList}
                      title="xabar berish"
                      className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      xabar berish
                    </button>
                  </div>
                </div>
              ),
              1: (
                <div className="content">
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {listBundles.length} ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--yellow)]"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{
                        border: "1px solid var(--yellow)",
                        outline: "var(--yellow)",
                      }}
                      color="var(--yellow)"
                      placeholder="O'quvchilar"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      HandleChange={(event, value) => setListBundles(value)}
                      options={bundles}
                      value={listBundles}
                    />
                    <textarea
                      name=""
                      rows={7}
                      className="textarea"
                      placeholder="Xabar yozish (majburiy)"
                      value={sms}
                      onChange={e => setSms(e.target.value)}
                    ></textarea>
                  </div>
                  <h3 className="text-base">
                    @oquvchi - Oquvchining ism familyasi
                    <br />
                    @guruh - Guruh nomi
                  </h3>
                  <div className="flex">
                    <button
                      onClick={handListBundles}
                      title="xabar berish"
                      className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      xabar berish
                    </button>
                  </div>
                </div>
              ),
              // Ochirish
              2: (
                <form onSubmit={DeleteCollection} className="content">
                  <div className="body-content flex flex-col items-center">
                    <svg
                      width="237"
                      height="235"
                      viewBox="0 0 237 235"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                        stroke="#FFCC00"
                        strokeWidth="4"
                      />
                      <path
                        d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                        stroke="#2D2D2B"
                        strokeWidth="10"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M119.5 73.4375V124.844"
                        stroke="#FFCC00"
                        strokeWidth="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                        fill="#FFCC00"
                        stroke="#FFCC00"
                        strokeWidth="10"
                      />
                      <path
                        d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                        stroke="#FFCC00"
                        strokeWidth="3"
                      />
                      <path
                        d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                        stroke="#FFCC00"
                        strokeWidth="3.5"
                      />
                    </svg>
                    <h1 className="text-4xl">Ogohlantirish</h1>
                    <br />
                    <h3 className="text-2xl width-50 text-center">
                      Siz hozirda {listCollection.length} ta Oq'uvchini
                      ochirmoqdasiz va bularni qaytarib bolmaydi
                    </h3>
                  </div>
                  <input
                  type="text" 
                  name="reason"
                  maxLength={125}
                  required={true}
                  className="input" placeholder="Sabab" />
                  <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                    <button
                    type="reset"
                      onClick={function () {
                        setModalType(0);
                        setModal(false);
                      }}
                      className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Ortga qaytish
                    </button>
                    <button
                      type="submit"
                      className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      O'chirish
                    </button>
                  </div>
                </form>
              ),
              4: (
                <form onSubmit={StudentDelete} className="content">
                  <div className="body-content flex flex-col items-center">
                    <svg
                      width="237"
                      height="235"
                      viewBox="0 0 237 235"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                        stroke="#FFCC00"
                        strokeWidth="4"
                      />
                      <path
                        d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                        stroke="#2D2D2B"
                        strokeWidth="10"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M119.5 73.4375V124.844"
                        stroke="#FFCC00"
                        strokeWidth="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                        fill="#FFCC00"
                        stroke="#FFCC00"
                        strokeWidth="10"
                      />
                      <path
                        d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                        stroke="#FFCC00"
                        strokeWidth="3"
                      />
                      <path
                        d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                        stroke="#FFCC00"
                        strokeWidth="3.5"
                      />
                    </svg>
                    <h1 className="text-4xl">Ogohlantirish</h1>
                    <br />
                    <h3 className="text-2xl width-50 text-center">
                      Siz hozirda {listStudent.length} ta Oq'uvchini ochirmoqdasiz
                      va bularni qaytarib bolmaydi
                    </h3>
                  </div>
                  <input
                  type="text" 
                  name="reason"
                  maxLength={125}
                  required={true}
                  className="input" placeholder="Sabab" />
                  <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                    <button
                    type="reset"
                      onClick={function () {
                        setModalType(0);
                        setModal(false);
                      }}
                      className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Ortga qaytish
                    </button>
                    <button 
                      type="submit" 
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                      O'chirish
                    </button>
                  </div>
                </form>
              ),
              6: (
                <form onSubmit={BundlesDelete} className="content">
                  <div className="body-content flex flex-col items-center">
                    <svg
                      width="237"
                      height="235"
                      viewBox="0 0 237 235"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                        stroke="#FFCC00"
                        strokeWidth="4"
                      />
                      <path
                        d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                        stroke="#2D2D2B"
                        strokeWidth="10"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M119.5 73.4375V124.844"
                        stroke="#FFCC00"
                        strokeWidth="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                        fill="#FFCC00"
                        stroke="#FFCC00"
                        strokeWidth="10"
                      />
                      <path
                        d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                        stroke="#FFCC00"
                        strokeWidth="3"
                      />
                      <path
                        d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                        stroke="#FFCC00"
                        strokeWidth="3.5"
                      />
                    </svg>
                    <h1 className="text-4xl">Ogohlantirish</h1>
                    <br />
                    <h3 className="text-2xl width-50 text-center">
                      Siz hozirda {listBundles.length} ta Oq'uvchini ochirmoqdasiz
                      va bularni qaytarib bolmaydi
                    </h3>
                  </div>
                  <input
                    type="text" 
                    name="reason"
                    maxLength={125}
                    required={true}
                    className="input"
                    placeholder="Sabab" />
                  <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                    <button
                      onClick={function () {
                        setModalType(0);
                        setModal(false);
                      }}
                      className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Ortga qaytish
                    </button>
                    <button 
                    type="submit"
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                      O'chirish
                    </button>
                  </div>
                </form>
              ),
              7: (
                <form className="content" action="" onSubmit={FormSubmit}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                    <div className="flex gap-3">
                      <span className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <input type="hidden" name="add_where" value={whereClick} />
                      <input
                        type="text"
                        className="input"
                        name="full_name"
                        placeholder="Ism familya"
                        required
                      />
                      <input
                        type="text"
                        onKeyUp={(e) => phoneKeyup(e)}
                        name="phone"
                        className="input"
                        placeholder="nomer"
                        required
                      />
                      <input
                        type="text"
                        onKeyUp={(e) => phoneKeyup(e)}
                        name="parent_phone"
                        className="input"
                        placeholder="Ota Onasini nomeri"
                      />
                      <input
                        type="text"
                        onKeyUp={(e) => dateKeyup(e)}
                        className="input"
                        name="birthday"
                        placeholder="Tug'ilgan kuni (Yil/Oy/Kun)"
                        required
                      />
                      <Autocomplete
                        className=" input"
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            setSubject(newValue);
                          } else {
                            setSubject(null);
                          }
                        }}
                        value={subject}
                        disablePortal
                        id="combo-box-demo"
                        options={subjects}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.title}
                        classes={{ paper: classes.paper }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="Fan" />
                        )}
                      />
                      <Autocomplete
                        className="input"
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            SetGradeValue(newValue);
                          } else {
                            SetGradeValue(null);
                          }
                        }}
                        value={GradeValue}
                        disablePortal
                        id="combo-box-demo"
                        options={grades}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.title}
                        classes={{ paper: classes.paper }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="Daraja" />
                        )}
                      />
                      <Autocomplete
                        className=" input"
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            setWhereValue(newValue.id);
                          } else {
                            setWhereValue("");
                          }
                        }}
                        disablePortal
                        id="combo-box-demo"
                        options={Wherelist}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.title}
                        classes={{ paper: classes.paper }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="Qayerdan" />
                        )}
                      />
                      <textarea
                        name="comment"
                        className="input"
                        id=""
                        rows={2}
                        placeholder="Izoh"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      title="xabar berish"
                      type="submit"
                      className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      O'quvchi qo'shish
                    </button>
                  </div>
                </form>
              ),
              8:(
                <div className="content">
                  <div className="header  flex justify-between align-center">
                    <h3 className="text-2xl ml-3">
                      <strong>To'plamga</strong> Yoki <strong>Tayyorga</strong>{" "}
                      qo'shish
                    </h3>
                    <div className="flex gap-3">
                      <span className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="">
                      <h1 className="text-2xl">To'plamga Qoshish</h1>
                      <Autocomplete
                        className=" input"
                        onChange={(event, newValue) => {setSelectedCol(newValue);}}
                        value={selectedCol}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={collectionOptions}
                        getOptionLabel={(option) => option.title}
                        classes={{ paper: classes.paper }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="To'plam" />
                        )}
                      />
                      <br />
                      <button
                        onClick={sendToCollection}
                        className="bg-[--green] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                      >
                        To'plamga qoshish
                      </button>
                      <br />
                      <br />
                      <h1 className="text-2xl">Tayyorga Qoshish</h1>
                      <Autocomplete
                        className=" input"
                        onChange={(event, newValue) => {
                          setValueSets(newValue);
                        }}
                        value={valueSets}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={setsOption}
                        getOptionLabel={(option) => option.title}
                        classes={{ paper: classes.paper }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" label="Tayyor" />
                        )}
                      />
                      <br />
                      <button
                        onClick={sendToStudentSets }
                        className="bg-[--red] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                      >
                        To'plamga qoshish
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                   
                  </div>
                </div>
              ),
              9: (
                <form className="content" action="" onSubmit={addCollection}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">To'plam qoshish</h3>
                    <div className="flex gap-3">
                      <span className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <input
                        type="text"
                        className="input col-span-1 md:col-span-2 "
                        name="title"
                        placeholder="To'plam nomi"
                        required
                      />     
                      <div></div>       
                      <h1 className="col-span-1 md:col-span-2 text-2xl mt-5 text-[var(--theme)]">Qayta ogohlantarish sanasi</h1>        
                      <input
                        type="datetime-local"
                        className="input col-span-1 md:col-span-2 "
                        name="datetime"
                        placeholder="Qo'shilgan payti"
                        required
                      />                 
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      title="xabar berish"
                      type="submit"
                      className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      To'plamga qo'shish
                    </button>
                  </div>
                </form>
              ),
              10: (
                <form className="content" action="" onSubmit={addStudentSets}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">Tayyor to'plam qo'shish</h3>
                    <div className="flex gap-3">
                      <span className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <input
                        type="text"
                        className="input col-span-1 md:col-span-2 "
                        name="title"
                        placeholder="To'plam nomi"
                        required
                      />     
                      <div></div>       
                      <h1 className="col-span-1 md:col-span-2 text-2xl mt-5 text-[var(--theme)]">Qayta ogohlantarish sanasi</h1>        
                      <input
                        type="datetime-local"
                        className="input col-span-1 md:col-span-2 "
                        name="datetime"
                        placeholder="Qo'shilgan payti"
                        required
                      />                 
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      title="xabar berish"
                      type="submit"
                      className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      To'plamga qo'shish
                    </button>
                  </div>
                </form>
              ),
            }[modalType]
          }
        </div>
        
      </>
    );
  }
  else{
    return <ErrorPage />
  }
}
const MultiAutocomplete = (props) => {
  return (
    <Autocomplete
      sx={{ m: 1 }}
      style={{ "--set-color": props.color }}
      value={props.value}
      onChange={(event, value) => props.HandleChange(event, value)}
      multiple
      id="tags-standard"
      options={props.options}
      getOptionLabel={(option) => option.full_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.id}
          value={option}
          sx={{ justifyContent: "space-between" }}
        >
          {option.full_name}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.placeholder}
          placeholder={props.placeholder}
          fullWidth
        />
      )}
    />
  );
};
