import React, { useEffect, useState} from "react";
import {useLocation} from "react-router-dom"
import WeekDays from '../../../components/teacher/weekdays';

import axios from "axios";

export default function PaymentReports(params) {
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .get("attendance/todays-attendance/")
      .then((res)=>{
        console.log(res)
        setList(res.data)
      })
  },[useLocation()])

  return (
    <>
      <div className="bc-card">
        <div className="table-container bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
          <div className="table-horizontal-container">
            <table className="table-border td-min-width">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th >&#8470;</th>
                  <th>Guruh nomi</th>
                  <th>Kunlari</th>
                  <th>Vaqti</th>
                  <th>O'qituvchisi</th>
                  <th>Holati</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>&#8470;</td>
                      <td><span className="min-w-[450px]">{item?.group.title}</span></td>
                      <td>
                          <div className="min-w-[350px] d-flex justify-center" >
                            <WeekDays days={item?.group.days} />
                          </div>
                      </td>
                      <td>{item?.group.start_time.substring(0, item?.group.start_time.length - 3)} - {item?.group.end_time.substring(0, item?.group.start_time.length - 3)} </td>
                      <td>{item?.teacher.name} {item?.teacher.surname}</td>
                      <td width="350px">
                        {item?.has_checked ? 
                          <div className='status_bar bg-[var(--green)] px-5 w-[150px]'>Belgilandi</div>
                          :
                          <div className='status_bar bg-[var(--red)] px-5 w-[150px]'>Belgilanmadi</div>
                        }
                      </td>
                  </tr>
                )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}
