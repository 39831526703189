import React, { useEffect, useState } from "react";
import { useLocation, useParams,NavLink } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Notification from "../../components/alert"
// import {
//   Chart as ChartJS,
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// import { Doughnut, Line } from "react-chartjs-2";
// import style from "../../assets/css/give_slary.module.css";
import { TextField } from "@mui/material";

import axios from "axios";

// ChartJS.register(
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const data = {
//   labels: ["Hisobga qolgan mablag'", "Jarima", "Avans", "Mukofat pullari"],
//   datasets: [
//     {
//       label: "Narxi: ",
//       data: [12, 8, 3, 5],
//       backgroundColor: ["#F7AE01", "#D23F3F", "#7C90DB", "#7CBBA1"],
//       borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
//       borderWidth: 1,
//     },
//   ],
// };

function visibleNumber(value) {
  console.log(value);
  if (parseInt(value) < 0 || value.includes("-")) {
    value = value.replace("-", "");
    var n = parseInt(value.replaceAll(/\D/g, ""), 10);
    if (n) {
      return `${"-" + n.toLocaleString().replaceAll(",", " ")}`;
    } else {
      return "-";
    }
  } else {
    var n = parseInt(value.replaceAll(/\D/g, ""), 10);
    if (n) {
      return `${n.toLocaleString().replaceAll(",", " ")}`;
    } else {
      return "";
    }
  }
}
// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Chart.js Line Chart",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data1 = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [15, 14, 13, 12, 11, 10],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: [10, 11, 12, 13, 14, 15],
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };

export default function Give_salary(params) {
  const [details, setDetails] = useState(null);
  const [salary, setSalary] = useState(null);
  const [punishment, setPunishments] = useState(null);
  const [date, setDate] = useState(null);
  const [filter, setFilter] = useState([]);
  
  const routerParams = useParams();

  useEffect(() => {
    axios.get(`budget/give/salary/${routerParams.slug}/`).then((res) => {
      setDetails(res.data);
      setSalary(visibleNumber(`${res?.data?.calc_salary}`))
      setFilter(res?.data?.student_payments)
      setPunishments(visibleNumber(`${res?.data?.punishments + res?.data?.advance}`))
    });
  }, [useLocation()]);

  useEffect(() => {
    if (date){
      axios
      .get(`budget/give/salary/${routerParams.slug}/`,
        {params: {
          month: date[0],
          year: date[1]
        }}
      )
      .then((res) => {
        setDetails(res.data);
        setSalary(visibleNumber(`${res?.data?.calc_salary}`))
        console.log((`${res['calc_salary']}`))
        setPunishments(visibleNumber(`${res?.data?.punishments + res?.data?.advance}`))
        setFilter(res?.data?.student_payments)
      });
    }
  },[date])

  function SalaryCreate(e){
    e.preventDefault()
    let form = {
      month: date[0],
      year: date[1],
      salary: salary.replaceAll(" ", ""),
      punishment: punishment.replaceAll(" ", "")
    }
    axios
    .postForm(`budget/give/salary/${routerParams.slug}/`,
    form
    )
    .then(res => {
      Notification(
        "Muvaffaqiyatli !",
        "Oylik berildi",
        'var(--green)',
        2000
        )
      setTimeout(() => {
        window.location.reload();
      },1000)
    })

  }
  return (
    <>
      <div className="grid  lg:mx-5 grid-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5">
        {details?.is_given ?
        (
        <form onSubmit={SalaryCreate} className="bg-[var(--theme-sidebar)]  rounded-3xl px-3 pt-5">
          <div className="bg-[var(--theme-bg)] flex items-center my-3 w-[80%]  rounded-[15px] p-4 px-[20px]">
            <i className="fa-solid fa-calendar-range text-2xl mx-3"></i>
            <LocalizationProvider className="w-full" dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month"]}
                  onChange={(e) => {
                    setDate([e.$M + 1, e.$y]);
                  }}
                  slotProps={{
                    textField: {
                      class: "input w-full",
                      name: "date",
                      required: true,
                      size: "small",
                      style: { width: "100%" },
                      variant: "standard",
                    },
                  }}
                />
              </LocalizationProvider>
          </div>
          <div className="bg-[var(--theme-bg)] flex items-center my-3 w-[80%]  rounded-[15px] p-4 px-[20px]">
            <i className="fa-solid fa-sack-dollar text-2xl mx-3"></i>
            <TextField
              style={{ width: "100%" }}
              onChange={(e) => setSalary(`${visibleNumber(e.target.value)}`)}
              value={salary}
              id="standard-basic"
              label="Summa"
              variant="standard"
              required
              focused
            />
          </div>
          <br />
          <div className="bg-[var(--theme-bg)] flex items-center my-3 w-[80%]  rounded-[15px] p-4 px-[20px]">
            <i className="fa-light fa-circle-minus text-2xl mx-3 text-[var(--red)]"></i>
            <TextField
              onChange={(e) => setPunishments(`${visibleNumber(e.target.value)}`)}
              value={punishment}
              style={{ width: "100%" }}
              id="standard-basic"
              label="Jarima"
              variant="standard"
              required
              focused
            />
          </div>
          <br />

          <div className="flex gap-5">
            <button 
              type="submit"
              className="bg-[var(--theme-bg)] hover:bg-[var(--green)] text-[var(--green)] text-xl px-5 font-semibold hover:text-white py-2 px-4 hover:border-transparent rounded-3xl">
              Oylik berish
            </button>
            <NavLink to="/teacher/" className="bg-[var(--theme-bg)] hover:bg-[var(--red)] text-[var(--red)] text-xl px-5 font-semibold hover:text-white py-2 px-4 hover:border-transparent rounded-3xl">
              Bekor qilish
            </NavLink>
          </div>
          <br />
          <br />
        </form>
        )
        :
        (
          <div className="bg-[var(--theme-sidebar)]  rounded-3xl px-3 pt-5"> 
          <br /><br />
          <div className="bg-[var(--theme-bg)] flex items-center my-3 w-[80%]  rounded-[15px] p-4 px-[20px]">
            <i className="fa-solid fa-calendar-range text-2xl mx-3"></i>
              <LocalizationProvider className="w-full" dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month"]}
                    onChange={(e) => {
                      setDate([e.$M, e.$y]);
                    }}
                    slotProps={{
                      textField: {
                        class: "input w-full",
                        name: "date",
                        required: true,
                        size: "small",
                        style: { width: "100%" },
                        variant: "standard",
                      },
                    }}
                  />
                </LocalizationProvider>
            </div>
            <h1 className="text-4xl text-[var(--red)] mx-5">Oylik berilgan</h1>
          </div>
        )
        }
        <div
          className={` mt-0 bg-[var(--theme-sidebar)]  rounded-3xl p-5 row-span-1 xl:row-span-1 lg:row-span-2`}
        >
          <h1 className="text-4xl mt-4">Ma'lumot</h1>
          <br />
          <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-5 ">
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 px-[20px]">
              <i className="fa-solid fa-user text-xl mx-3"></i>
              <ul>
                <li>Ism & familya</li>
                <li>
                  <strong className="text-[var(--theme)] text-xl">
                    {details?.full_name}
                  </strong>
                </li>
              </ul>
            </div>
            {details?.percent ? (
              <>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-solid fa-money-simple-from-bracket text-2xl mx-3"></i>
                  <ul>
                    <li>To'lov yig'ildi</li>
                    <li>
                      <span className="text-xl">
                        {visibleNumber(`${details?.all_students_payments}`)}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-solid fa-percent text-2xl mx-3"></i>
                  <ul>
                    <li>Ishlash Foizi</li>
                    <li>
                      <span className="text-xl">{details?.percent}</span>
                    </li>
                  </ul>
                </div>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-solid fa-bag-shopping text-2xl mx-3"></i>
                  <ul>
                    <li>To'lovlar qismi</li>
                    <li>
                      <span className="text-xl">
                        {visibleNumber(`${details?.this_month}`)} /{" "}
                        {visibleNumber(`${details?.other_payments}`)}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-solid fa-graduation-cap text-2xl mx-3"></i>
                  <ul>
                    <li>Oylik davamoat jarimasi bilan</li>
                    <li>
                      <span className="text-xl">
                        {visibleNumber(`${details?.with_attendance_penalty}`)}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-solid fa-graduation-cap text-2xl mx-3"></i>
                  <ul>
                    <li>Davomatlar</li>
                    <li>
                      <span className="text-xl">
                        <strong className="text-[var(--green)]">
                          {details?.checked_attendance}
                        </strong>{" "}
                        /{" "}
                        <strong className="text-[var(--red)]">
                          {details?.checked_attendance +
                            details?.unchecked_attendance}
                        </strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
                  <i className="fa-duotone fa-percent text-2xl mx-3"></i>

                  <ul>
                    <li>Oylik Puli</li>
                    <li>
                      <span className="text-xl">
                        {visibleNumber(`${details?.absolute_salary}`)}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}

            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
              <i className="fa-sharp fa-solid fa-hands-holding-dollar text-3xl mx-3 text-[var(--purple)]"></i>
              <ul>
                <li className="text-[var(--purple)]">Avans</li>
                <li>
                  <span className="text-xl">
                    {visibleNumber(`${details?.advance}`)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
              <i className="fa-solid fa-octagon-minus text-2xl mx-3 text-[var(--red)]"></i>
              <ul>
                <li className="text-[var(--red)]">Jarima</li>
                <li>
                  <span className="text-xl">
                    {visibleNumber(`${details?.punishments}`)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
              <i className="fa-sharp fa-solid fa-trophy text-2xl mx-3"></i>
              <ul>
                <li>Mukofat puli</li>
                <li>
                  <span className="text-xl">
                    {visibleNumber(`${details?.prize_moneys}`)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-[var(--theme-bg)] flex items-center rounded-[15px] p-2 xl:px-[20px] lg:px-1">
              <i className="fa-solid fa-money-bill-trend-up text-2xl mx-3"></i>
              <ul>
                <li>Jami olishi kerak</li>
                <li>
                  <span className="text-xl">
                    {visibleNumber(`${details?.calc_salary}`)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container bg-[var(--theme-sidebar)] mt-5 rounded-3xl items-start gap-3 p-6">
          <div className="table-horizontal-container">
            <table className="table-border td-min-width">
              <thead>
                <tr>
                  <th>&#8470;</th>
                  <th>Ism familya</th>
                  <th>Guruhi</th>
                  <th>Holati</th>
                  <th>Telefon raqami</th>
                  <th>Ota Onasi telefon raqami</th>
                  <th>Pul summa</th>
                  <th>Chegirma bilan</th>
                </tr>
              </thead>
              <tbody>
                {filter?.map((item, index) => (
                  <tr key={`payment-${index}`}>
                    <td>{index + 1})</td>
                    <td>{item?.student?.full_name}</td>
                    <td>
                      <NavLink
                        to={`/groups/${item?.group?.id}`}
                        className="text-[var(--theme)] font-bold"
                      >
                        {item?.group?.title}
                      </NavLink>
                    </td>
                    {item?.money > 0 ? (
                      <td>
                        <div className="status_bar p-1 bg-[var(--green)]">
                          Tugallangan {item.is_finished}
                        </div>
                      </td>
                    ) : (
                      item?.is_payed ? (
                        <td>
                          <div className="status_bar bg-[var(--yellow)]">
                            chala To'lov
                          </div>
                        </td>
                      ):
                      (
                        <td>
                          <div className="status_bar bg-[var(--red)]">
                            To'lov qilinmagan
                          </div>
                        </td>
                      )
                    )}
                    <td>
                      <a href={`tel:${item?.student?.phone}`}>
                        {item?.student?.phone}
                      </a>
                    </td>
                    <td>
                      <a href={`tel:${item?.student?.parent_phone}`}>
                        {item?.student?.parent_phone}
                      </a>
                    </td>
                    <td>
                      {item?.money}
                    </td>
                    <td>
                      {item?.with_discount}
                    </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </>
  );
}
