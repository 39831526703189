import { createBrowserRouter } from "react-router-dom";
import { Outlet } from "react-router-dom";


import Root from "./pages/Root";
import ErrorPage from "./pages/error-page";

import Reception from './pages/reception/reception2';
import Students from './pages/reception/students' 
import Collection from './pages/reception/collections' 
import Bundles from './pages/reception/bundles' 
import CollectionDetail from './pages/reception/collection_detail.jsx'
import BoundlesDetail from './pages/reception/bundles_detail copy.jsx'

import Teacher from './pages/teacher/home'
import Group1 from './pages/teacher/groups'
import Detail from './pages/teacher/detail'
import GiveSalary from './pages/teacher/give_salary'

import Home from './pages/admin/Home';
import GetPayment from './pages/admin/get_payment'
import GetStudents from './pages/admin/get_student'
import Budget from './pages/admin/Budget'
import Reports from './pages/admin/reports'
import Settings from './pages/admin/settings.jsx'
import SubjectCreate from './pages/admin/subjectCreate'
import SubjectEdit from './pages/admin/subjectEdit'
import AdminReport from './pages/admin/AdminReport'
import DeletedObjects from './pages/admin/deletedObjects.jsx'

import Lessons from './pages/lessons/home.jsx'
import Profile from './pages/profile/profile'
import Failed from './pages/failed_page.jsx'

import Message from './pages/messages/home'
import Chat from './pages/messages/chat'
import ChatGroup from './pages/messages/chatgroups.jsx'

import ReceptionReports from './pages/admin/reports/reception.jsx'
import TodayLessons from './pages/admin/reports/todays_lesson.jsx'
import OverALLReceptions from "./pages/admin/reports/rec_overall.jsx"
import PaymentReports from "./pages/admin/reports/paymentReports.jsx"
import NotAttendStudents from "./pages/admin/reports/notAttendStudents.jsx"
import AllComments from "./pages/admin/reports/allComments.jsx"
import Promocodes from "./pages/admin/reports/promocodes.jsx"
import StudentAddForm from "./pages/forms/student_add";
import LoginForm from "./pages/login.jsx"
import Logout from "./pages/logout.jsx";
import TeacherAttendance from './pages/teacher-attendance.jsx';
import ReportsBudget from "./pages/admin/reports/allcompanyReports"


import './assets/css/index.css';



const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        errorElement: <ErrorPage />,
                        element: <Profile />,
                    },
                    {
                        path: "attendance/",
                        errorElement: <ErrorPage />,
                        element: <TeacherAttendance />
                    },
                    {
                        path: "reception/",
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                errorElement: <ErrorPage />,
                                element: <Reception />,
                            },
                            {
                                path: 'student/',
                                errorElement: <ErrorPage />,
                                element: <Students />,
                            },
                            {
                                path: 'collection/',
                                errorElement: <ErrorPage />,
                                element: <Collection />,
                            },
                            {
                                path: 'collection/:collection_id/',
                                errorElement: <ErrorPage />,
                                element: <CollectionDetail />,
                            },
                            {
                                path: 'students-sets/:collection_id/',
                                errorElement: <ErrorPage />,
                                element: <BoundlesDetail />,
                            },
                            {
                                path: 'students-sets/',
                                errorElement: <ErrorPage />,
                                element: <Bundles />,
                            },

                        ]
                    },
                    {
                        path: "teacher/",
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <Teacher />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: ':slug/salary',
                                element: <GiveSalary />,
                                errorElement: <ErrorPage />,
                            },

                        ]

                    },
                    {
                        path: "groups/",
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <Group1 />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: ':slug',
                                element: <Detail />,
                                errorElement: <ErrorPage />,
                            },
                        ]
                    },
                    {
                        path: "budget/",
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <Budget />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'get-data/',
                                element: <Reports />,
                                errorElement: <ErrorPage />,
                            }
                        ]
                    },
                    {
                        path: 'get-payment',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                errorElement: <ErrorPage />,
                                element: <GetStudents />
                            },
                            {
                                path: ':slug',
                                errorElement: <ErrorPage />,
                                element: <GetPayment />
                            }
                        ]
                    },
                    {
                        path: 'lessons/',
                        errorElement: <ErrorPage />,
                        element: <Lessons />
                    },
                    {
                        path: 'settings',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                errorElement: <ErrorPage />,
                                element: <Settings />
                            },
                            {
                                path: 'subject/create/',
                                errorElement: <ErrorPage />,
                                element: <SubjectCreate />
                            },
                            {
                                path: 'subject/edit/:subjectId',
                                errorElement: <ErrorPage />,
                                element: <SubjectEdit />
                            },
                            {
                                path:'report/',
                                errorElement: <ErrorPage />,
                                element: <AdminReport />
                            },
                            {
                                path:'report/budgets/',
                                errorElement: <ErrorPage />,
                                element: <ReportsBudget />
                            },
                            {
                                path: "deleted/",
                                element: <DeletedObjects />
                            },
                            {
                                path: "reports/receptions/",
                                element: <OverALLReceptions />
                            },
                            {
                                path: "todays/lessons/",
                                element: <TodayLessons />
                            },
                            {
                                path: "reports/receptions/:slug",
                                element: <ReceptionReports />
                            },
                            {
                                path: "reports/promocodes/",
                                element: <Promocodes />
                            },
                            {
                                path: "payments/",
                                element: <PaymentReports />
                            },
                            {
                                path: "not-attend-students/",
                                element: <NotAttendStudents />
                            },
                            {
                                path: "allcomments/",
                                element: <AllComments />
                            }
                        ]
                    },
                    {
                        path:'profile',
                        errorElement: <ErrorPage />,
                        element: <Profile />
                    },
                    {
                        path: 'messages/',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true, 
                                element: <Message />,
                            },
                            {
                                path: 'group/',
                                element: <ChatGroup />,
                            },
                            {
                                path: "room/:id", 
                                element: <Chat />,
                            }
                        ]
                    },
                    {
                        path: 'errors/:errorStatus',
                        errorElement: <ErrorPage />,
                        element: <Failed />
                    },
                    {
                        path: "*",
                        element: <ErrorPage />,
                    }
                ]
            }
        ],
    },
    {
        path: "/forms/student/add/:slug/",
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <StudentAddForm />
            }
        ]
    },
    {
        path: '/login',
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <LoginForm />
            }
        ]
    },
    {
        path: "/logout",
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Logout />
            }
        ]
    }
   ]
);


export default router;
