import React, { useState, useEffect } from "react";
import user_logo from "../../assets/img/avatar.jpg";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/img/braincore.png"
import axios from "axios";
import { rootPath } from "../../utils/main"

export default function Messages(params) {
  const activeClass =
    "inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg text-[var(--theme)] border-0 border-[var(--theme)]  group";
  const activeSvgClass = "w-4 h-4 mr-2 text-[var(--theme)]  dark:text-blue-500";
  const simpelClass =
    "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg text-[var(--theme-color)] hover:text-[var(--theme)] hover:border-[var(--theme)]  group";
  const simpelSvgClass =
    "w-4 h-4 mr-2 text-[var(--theme-color)] group-hover:text-[var(--theme)]";

  const [chat, setChat] = useState(true);
  const [chatUsers, setChatUsers] = useState([]);
  const [factory, setFactory] = useState(null)
  const [responseUsers, setResponseUsers] = useState([]);
  const mountedStyle = { animation: "inAnimation 250ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards",
  };

  function visiblephoneKeyup(value) {
    if (value){
        var y = value.replaceAll("+998", "").replaceAll(" ", "");
        var x = y
          .replaceAll(/\D/g, "")
          .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
        if (y > 0) {
          return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
            x[3] ? " " + x[3] : ""
          }${x[4] ? " " + x[4] : ""}`;
        } else {
          return "";
        }
    }
  }

  useEffect(() => {
    axios.get("chat/").then((res) => {
        setChatUsers(res.data);
        setResponseUsers(res.data)
    });
    axios.get("chat/get/work-on/")
    .then((res) => {
        setFactory(res.data)
    })
  }, []);
  function FilterUsers(e) {
    let value = e.target.value.toLowerCase();
    let sorted = responseUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.surname.toLowerCase().includes(value) ||
        user.phone.toLowerCase().includes(value)
    );
    setChatUsers(sorted);
  }

  return (
    <div className="grid grid-cols-12 gap-5">
      <div className="lg:col-span-5 col-span-12 bg-[var(--theme-sidebar)]  rounded-3xl p-5">
        <NavLink to="/messages" className="flex justify-between">
          <h1 className="text-2xl mt-1">
            <i className="fa-brands fa-discord text-2xl mx-2"></i>
            Chatlar
          </h1>
          <i
            className={`fa-solid fa-angle-down text-3xl lg:hidden ${
              !chat && "d-block rotate-180"
            }`}
            onClick={() => setChat((current) => !current)}
          ></i>
        </NavLink>
        <br />
        <div className={`${!chat ? "hidden lg:block" : "lg:block"}`}>
          <br />
          <form>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-[var(--theme-color)] sr-only "
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none">
                <svg
                  className="w-4 h-4 text-[var(--theme-color)]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-base text-[var(--theme-color)]  rounded-3xl border-2 bg-[var(--theme-bg)] border-[var(--theme-color)] focus:border-[var(--theme)] outline-none"
                placeholder="Qidiruv"
                onChange={FilterUsers}
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-[var(--theme)] hover:opacity-80 font-medium rounded-lg text-sm px-4 py-2 "
              >
                Search
              </button>
            </div>
          </form>

          <br />
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700 pr-5 overflow-y-auto"
            style={{ height: "calc(100vh - 450px)" }}
          >
            <NavLink to={`/messages/group`} className={"m-0 p-0 chat"}>
              <div className="py-3 sm:py-4 hover:bg-[var(--theme-bg)] px-3 rounded-xl">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      className="w-11 h-11 p-1 rounded-full ring-2 ring-[var(--theme)]"
                      src={factory?.factory.logo ? `${rootPath}${factory?.factory.logo}` : Logo}
                      alt="Bordered avatar"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium truncate">
                      {factory?.title} {factory?.factory.name}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {visiblephoneKeyup(factory?.contact_number)}
                    </p>
                  </div>
                </div>
              </div>
            </NavLink>
            <div className="w-[95%] bg-[var(--oppocity-bg)] mx-auto m-5"></div>
            {chatUsers.map((item) => (
              <NavLink to={`/messages/room/${item.id}`} className={"m-3 p-0 chat"}>
                <div className="py-3 sm:py-4 hover:bg-[var(--theme-bg)] px-3 hover:rounded-xl">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        className="w-11 h-11 p-1 rounded-full ring-2 ring-[var(--theme)]"
                        src={`${rootPath}${item.avatar_select_display}`}
                        alt="Bordered avatar"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium truncate">
                        {item.name} {item.surname}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {visiblephoneKeyup(item.phone)}
                      </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
