import React, { useEffect, useState } from "react";
import teacherStyle from "../../assets/css/teacher.module.css";
import checkmark from "../../assets/img/checkmark.png";
import trash from "../../assets/img/trash.png";
import MultiAutocomplete from "../../components/multiselect";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatNum, visibleNumber,rootPath } from "../../utils/main";
import {Autocomplete, TextField } from "@mui/material";
import  Notification from '../../components/alert'
import user_logo from "../../assets/user_avatar/default.png"
import { makeStyles } from "@mui/styles";
import ErrorPage from "../../pages/error-page";



const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-color)",
  },
});
export const whoOptions = [{"id": 0, title: 'Admin'}, {"id": 1, title: 'Manager'}, {"id": 2, title: 'Cashier'}, {"id": 3, title: 'Teacher'}, {"id": 4, title: 'Reception'}, {"id": 5, title: 'Call operator'}]

export default function Teacher() {
  const [worker, setWorker] = useState([]);
  const [sortedWorker, setSortedWorker] = useState([]);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteL, setDeleteL] = useState([]);
  const [modalType, setModalType] = useState(0);
  const [companies, setcompanies] = useState([]);
  const [company, setCompany] = useState([]);
  const [who, setWho] = useState([]);
  const classes = useStyles();

  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(/\s/g, "");
    var x = y
      .replaceAll(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }

  function visiblephoneKeyup(value) {
    var y = value.replaceAll("+998", "").replaceAll(/\s/g, "");
    var x = y.replaceAll(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
        return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      return "";
    }
  }
  function changePrice(e){
      if(e.target.value.includes('%')){
        e.target.value = e.target.value.replace('%', '') + '%';
        if(e.target.value.length > 3 ){
            e.target.value = e.target.value.toLocaleString().slice(0, -1);
        }
      }    
      else  if (e.target.value.length < 12) {
          console.log()
          var n = parseInt(e.target.value.replaceAll(/\D/g, ''), 10);
          if (n) {
              e.target.value = n.toLocaleString().replaceAll(',', ' ' );
          } else {
              e.target.value = ""
          }
      } else {
          e.target.value = e.target.value.toLocaleString().slice(0, -1);
      }
    }

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/accounts/users/`)
      .then((res) => {setWorker(res.data); setSortedWorker(res.data);})
      .catch((err) => {
        setErrorPage(true)
      });
    setIsLoading(false);
  }, [useLocation()]);

  useEffect(() => {
    if(modalType === 6 || modalType === 7){
        axios
        .get('accounts/factory/companies/')
        .then((res) => 
            {
              setcompanies(res.data)
              let list_data = res.data
              setCompany(list_data.filter(item => list[0]?.work_for.includes(item.id)))

            }
        )
        .catch((err) => {
          Notification(
            "Siz bunday qila olmaysiz",
            "Yoki keyinroq urinib koring",
            "var(--red)",
            5000
          );
          setModalType(0)
          setModal(false)
        })
    }
    if(modalType === 7){
      setWho(whoOptions.filter(item => list[0]?.who.some(item2 => item.id == item2 )))
    }
  },[modalType])
  

  function HandleForm(e){
    e.preventDefault();
    let data = new FormData(e.target);
    let salary = data.get('salary');
    let who_form = who.map(item => item.id)
    if(company && who && data.get('phone').length === 17){
        if (!who_form.includes(3) && salary.includes("%") ){
            Notification(
                "XATO !!!",
                "Faqat o'qituvchi foizga ishlay oladi",
                "var(--red)",
                5000
              );
        }
        else{
            let formData = {
                work_for: company.map(item => item.id),
                who: who_form,
                name: data.get('name'),
                surname: data.get('surname'),
                phone: data.get('phone'),
                absolute_salary: salary.includes("%") ? 0 : parseInt(salary.replaceAll(' ', '')),
                percent: salary.includes("%") ? salary.replace('%', '') : 0,
                unique_id: data.get('unique_id'),
            }
            axios
            .post('/accounts/user/create/', formData)
            .then((res) => {
              setWorker([...worker, res.data])
              setSortedWorker([...worker, res.data])
              setModal(false)
              Notification(
                "Ishchi Qo'shildi",
                "Mufaqiyatli amalga oshdi",
                "var(--green)",
                5000
              )
            })
            .catch(err => {
              let response = Object.values(err.response.data)
              for (let i = 0; i < response.length; i++) {
                console.log(response[i])
                Notification(
                  "Xatolik topildi",
                  response[i],
                  "var(--red)",
                  5000
                )
              }
            
            });
        }
    }
    else{
        Notification(
            "XATO !!!",
            "Hamma joyni to'g'ri to'ldiring",
            "var(--red)",
            5000
          );
    }
  }

  function HandleUpdateForm(e){
    e.preventDefault();
    let data = new FormData(e.target);
    let salary = data.get('salary');
    let who_form = who.map(item => item.id)
    if(company && who && list[0]){
        if (!who_form.includes(3) && salary.includes("%") ){
            Notification(
                "XATO !!!",
                "Faqat o'qituvchi foizga ishlay oladi",
                "var(--red)",
                5000
              );
        }
        else{
          setList([])
            let formData = {
                work_for: company.map(item => item.id),
                who: who_form,
                name: data.get('name'),
                surname: data.get('surname'),
                phone: data.get('phone'),
                absolute_salary: salary.includes("%") ? 0 : salary.replaceAll(/\s/g, ""),
                percent: salary.includes("%") ? salary.replace('%', '') : 0,
                unique_id: data.get('unique_id')
            }
            axios
            .put('/accounts/user/create/', {user: list[0].id ,data: formData})
            .then((res) => {
              setModal(false)
              Notification(
                "Ishchi Qo'shildi",
                "Mufaqiyatli amalga oshdi",
                "var(--green)",
                5000
              )
              let list = worker
              for (let i = 0; i < list.length; i++) {
                if(list[i].id === res.data.id) {
                    list[i]['name'] = res.data.name
                    list[i]['surname'] = res.data.surname
                    list[i]["phone"] = res.data.phone
                    list[i]["percent"] = res.data.percent
                    list[i]["absolute_salary"] = res.data.absolute_salary
                    list[i]["who"] = res.data.who
                    list[i]["work_for"] = res.data.work_for
                }
              }
              setWorker(list)
              setSortedWorker(list)
              e.target.reset()
              setList([])
            })
            .catch(err => {
              let response = Object.values(err.response.data)
              for (let i = 0; i < response.length; i++) {
                Notification(
                  "Xatolik topildi",
                  response[i],
                  "var(--red)",
                  5000
                )
              }
            
            });
        }
    }
    else{
        Notification(
            "XATO !!!",
            "Hamma joyni to'g'ri to'ldiring",
            "var(--red)",
            5000
          );
    }
  }

  function deleteWorker(e){
    e.preventDefault();
    let data = new FormData(e.target);

    axios
    .delete('/accounts/user/create/',
    {data: {
        ids: list.map(item => item.id),
        reason: data.get('reason')
    }}
    )
    .then(res => {
      setList([])
      setModalType(0)
      setModal(false)
      setWorker(worker.filter(worker => !list.includes(worker)))
      setSortedWorker(worker.filter(worker => !list.includes(worker)))
      setDeleteL([])
      Notification(
        "Ishchi O'chirildi",
        "Mufaqiyatli amalga oshdi",
        "var(--green)",
        5000
      )
    })
    .catch((err) => {
      Notification(
        "Siz bunday qila olmaysiz",
        "Yoki keyinroq urinib koring",
        "var(--red)",
        5000
      );
    })
  }

  function addTeacherMoney(e,type){
    e.preventDefault();
    const arr = ['Avans', 'Jarima', 'Mukofat puli']
    let data = new FormData(e.target);
    axios
    .post('accounts/worker/money/',{
      users_id: list.map(item => item.id),
      why_given: type,
      money: data.get('money').replaceAll(' ',''),
      money_type: parseInt(data.get('money_type')),
      reason: data.get('reason'), 
      notification_sms: data.get('notification_sms'),
      notification_sytem: data.get('notification_sytem'),
    })
    .then((response) => {
      e.target.reset()
      setModal(false)
      setModalType(0)
      setList([])
      Notification(
        "Mufaqiyatli amalga oshdi",
        `Ischilarga ${arr[type]} berildi`,
        "var(--green)",
        5000
      )

    })
    .catch(err => console.log(err))
  }

  function sortWorkerObject(e){
    let simple = []
    let value_input = e.target.value.toLowerCase()
    for (let item of worker) {
      if (item.name.toLowerCase().includes(value_input)
          || item.surname.includes(value_input)
          || item.phone.replaceAll(' ', '').includes(value_input)
          ){
            simple.push(item)
      }
    }
    setSortedWorker(simple)
  }





  // document.body.ondblclick = (event) => {
  //   setList([]);
  //   setModal(false);
  //   setDeleteL([]);
  //   setModalType(0);
  // };
  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allWorker2 = worker.map((c) => c);
      setList(allWorker2);
    } else {
      setList([]);
    }
  };
  const handleCountryChange = (e, worker) => {
    if (list.includes(worker)) {
      setList(list.filter((item) => item !== worker));
    } else {
      setList([...list, worker]);
    }
  };
  const handleDeleteChange = (e, worker) => {
    if (deleteL.includes(worker)) {
      setDeleteL(deleteL.filter((item) => item !== worker));
    } else {
      setDeleteL([...deleteL, worker]);
    }
  };
  function DeleteSelect() {
    var myArray = list.filter((el) => !deleteL.includes(el));
    setList(myArray);
    setDeleteL([]);
    // setModal(!(myArray === 0))
  }
  function callKeyup(e) {
    if (e.target.value.length < 12) {
      var n = parseInt(e.target.value.replaceAll(/\D/g, ""), 10);
      if (n) {
        e.target.value = n.toLocaleString().replaceAll(",", " ");
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = e.target.value.toLocaleString().slice(0, -1);
    }
  }
  if(!errorPage){
    return (
      <>
        <div className="br-card">
          <br />
          <div
            className="flex flex-wrap w-100 justify-between"
            onDoubleClick={() => setList([])}
          >
            <div className="input-group my-5" style={{ width: "360px" }}>
              <i className="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Qidiruv" onChange={sortWorkerObject}/>
            </div>
            <div className="flex gap-4 items-center flex-wrap my-5">
              <button onClick={e => navigate('/attendance')} className="button px-4">Davomat</button>
              {list.length > 0 && (
                <button
                  onClick={() => setModal(true)}
                  className={`button text-[var(--theme-sidebar)] hover:opacity-80 bg-[var(--oppocity-bg)]`}
                >
                  <i className="fa-solid fa-ellipsis-vertical text-xl w-4"></i>
                </button>
              )}
              {list.length === 1 && (
                <button
                  onClick={() => {setModal(true); setModalType(7)}}
                  className={`button px-4`}
                >
                  <i className="fa-solid fa-pen text-xl w-4 text-[var(--yellow)]"></i>
                </button>
              )}
              <button
                onClick={() => {
                  setModal(true);
                  setModalType(6);
                }}
                className="button  text-[var(--purple)]"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 7H28"
                    stroke="#7C90DB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 4V10"
                    stroke="#7C90DB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
                    stroke="#7C90DB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                    stroke="#7C90DB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="button"
                onClick={() => {
                  setModalType(5);
                  setModal(true);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.687 7.4375L5.31201 7.43751"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.8125 13.8125V22.3125"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.1875 13.8125V22.3125"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button className="button">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.75 13.7515L16 19.0001L21.25 13.7515"
                    stroke="#4FB286"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 5V18.9963"
                    stroke="#4FB286"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
                    stroke="#4FB286"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <br />
          <h3 className="text-xl">
            <input
              type="checkbox"
              defaultChecked={worker.length === list.length}
              onClick={(e) => handleCheckAllChange(e)}
              className="accent-[var(--theme)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
            />{" "}
            Hammasini belgilash
          </h3>
          <br />
          <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-5">
            {sortedWorker.map((item) => (
              <div className={`${teacherStyle.worker_card}`} key={item.id}>
                <div className="flex xl:flex-nowrap lg:flex-wrap justify-center px-6 flex-wrap gap-5">
                  <div
                    className={teacherStyle.bg_image}
                    onClick={(e) => handleCountryChange(e, item)}
                    style={{
                      backgroundImage: `url("${
                        list.includes(item)
                          ? checkmark
                          : item.avatar_select_display  ? rootPath + item.avatar_select_display : user_logo
                      }")`,
                    }}
                  ></div>
                  <ul className="mt-3">
                    <li>
                      <strong>Ism:</strong> {item.name} {item.surname}
                    </li>
                    <li>
                      <strong>Oylik:</strong>{" "}
                      {item.percent
                        ? item.percent + "%"
                        : formatNum(item.absolute_salary)}{" "}
                    </li>
                    <li>
                      <strong className="text-sm">Telefon raqam:</strong>{" "}
                      <div className="text-base truncate">{visiblephoneKeyup(item.phone)}</div>{" "}
                    </li>
                    <li className="my-1 flex">
                      <strong className="w-[100px]">Ish turi:</strong>{" "}
                      <div className="flex items-left justify-left flex-wrap gap-1">
                        {item.who.map((w, index) => {
                          const arr = ["Admin", "Manager", "Cashier", "Teacher", "Manager", "Call operator"]
                          return (
                            <span key={`who-${index}`} className="py-1 px-2 rounded-full bg-[var(--oppocity-bg)] text-[var(--theme-bg)] text-sm">
                              {arr[parseInt(w)]}
                            </span>
                          );
                        })}
                      </div>{" "}
                    </li>
                  </ul>
                </div>
                <div className="flex gap-2 md:flex-nowrap flex-wrap px-5 mt-5">
                  <NavLink
                    to={`/teacher/${item.id}/salary`}
                    className={` ${teacherStyle.button} text-center bg-[--yellow] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full`}
                  >
                    Oylik berish
                  </NavLink>
                  <button
                    onClick={(e) => {
                      setModal(true); setModalType(3); setList([item])
                    }}
                    title="Avans berish"
                    className="bg-[var(--green)] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Avans berish
                  </button>
                  <button
                    onClick={(e) => {
                      setModal(true); setModalType(4); setList([item])
                    }}
                    title="Jarima berish"
                    className="bg-[var(--red)] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Jarima berish
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`modal ${modal && "active"}`}>
          {
            {
              0: (
                <div className="content">
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl">
                      Tanlangan foydalanuvchilar: {list.length} ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button className="button" onClick={DeleteSelect}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.687 7.4375L5.31201 7.43751"
                            stroke="#FC3F1D"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.8125 13.8125V22.3125"
                            stroke="#FC3F1D"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.1875 13.8125V22.3125"
                            stroke="#FC3F1D"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                            stroke="#FC3F1D"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                            stroke="#FC3F1D"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button className="button" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="body-content scroll grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-5">
                    {list.map((item) => (
                      <div
                        className={`${teacherStyle.worker_card} flex items-center max-h-[100px]`}
                        key={`12-${item.id}`}
                      >
                        <div
                          className={`${teacherStyle.bg_image} w-[60px] h-[60px] rounded-[20px]`}
                          onClick={(e) => handleDeleteChange(e, item)}
                          style={{
                            backgroundImage: `url("${
                              deleteL.includes(item) ? trash : item.avatar_select_display  ? rootPath + item.avatar_select_display : user_logo
                            }")`,
                          }}
                        ></div>
                        <span className="text-xl">{item.name}</span>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-2 md:flex-nowrap flex-wrap px-5 mt-5">
                    <button
                      onClick={() => setModalType(1)}
                      className="bg-[--yellow] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      xabar berish
                    </button>
                    <button
                      onClick={() => setModalType(2)}
                      title="Oylik berish"
                      className="bg-[var(--blue)] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Mukofat puli  berish
                    </button>
                    <button
                      onClick={() => setModalType(3)}
                      title="Avans berish"
                      className="bg-[var(--green)] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Avans berish
                    </button>
                    <button
                      onClick={() => setModalType(4)}
                      title="Jarima berish"
                      className="bg-[var(--red)] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Jarima berish
                    </button>
                  </div>
                </div>
              ),
              1: (
                <div className="content">
                  <div className="header flex justify-between align-center">
                    <button className="button text-[var(--yellow)]" onClick={() => setModalType(0)}>
                      <i className="fa-solid fa-arrow-left text-xl w-5"></i>
                    </button>
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {list.length}ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button className="button text-[var(--yellow)]" onClick={() => setModal(false)}>
                        <i className="fa-solid fa-xmark text-xl w-5"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{
                        border: "1px solid var(--yellow)",
                        outline: "var(--yellow)",
                      }}
                      color="var(--yellow)"
                      placeholder="o'qituvchilar"
                      HandleChange={(event, value) => setList(value)}
                      options={worker}
                      value={list}
                    />
                    <textarea
                      name=""
                      rows={7}
                      className="textarea"
                      placeholder="Xabar yozish (majburiy)"
                      value="Assalomu alaykum [oquvchi] siz ushbu [guruh] uchun to'lov qilishni unutman"
                    ></textarea>
  
                    <h3 className="flex m-1 mt-3 text-base items-center">
                      SMS orqali habar berish
                      <input
                        type="checkbox"
                        className="ml-2 accent-[var(--yellow)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                    <h3 className="flex m-1 text-base items-center">
                      Tizim orqali habar berish
                      <input
                        type="checkbox"
                        className="ml-2 accent-[var(--yellow)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => setModalType(1)}
                      className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      xabar berish
                    </button>
                  </div>
                </div>
              ),
              2: (
                <form className="content" onSubmit={(e) => addTeacherMoney(e,2)}>
                  <div className="header flex justify-between align-center">
                    <button
                      className="button text-[var(--blue)]"
                      type="reset"
                      onClick={() => setModalType(0)}
                    >
                      <i className="fa-solid fa-arrow-left text-xl w-5"></i>
                    </button>
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {list.length}ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button
                        className="button text-[var(--blue)]"
                        type="reset"
                        onClick={() => setModal(false)}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{ border: "1px solid var(--blue)" }}
                      color="var(--blue)"
                      placeholder="o'qituvchilar"
                      HandleChange={(event, value) => setList(value)}
                      options={worker}
                      value={list}
                    />
                    <div className="flex">
                      <input
                        type="text"
                        onKeyUp={(e) => callKeyup(e)}
                        name="money"
                        className="input"
                        placeholder="summa kiriting"
                        required
                      />
                      <select name="money_type" className="input" id="">
                        <option value="0">Naqd pul</option>
                        <option value="1">Click</option>
                        <option value="2">Plastik</option>
                      </select>
                    </div>
                    <textarea
                      rows={4}
                      name="reason"
                      className="textarea"
                      placeholder="Izoh yozish (majburiy)"
                      required
                    ></textarea>
                    <h3 className="flex m-1 mt-3 text-base items-center">
                      SMS orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sms"
                        maxLength={10}
                        className="ml-2 accent-[var(--blue)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                    <h3 className="flex m-1 text-base items-center">
                      Tizim orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sytem"
                        className="ml-2 accent-[var(--blue)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                  </div>
                  <div className="flex">
                    <button
                      type="submit"
                      className="bg-[var(--blue)] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      avans berish
                    </button>
                  </div>
                </form>
              ),
              3: (
                <form className="content" onSubmit={(e) => addTeacherMoney(e,0)}>
                  <div className="header flex justify-between align-center">
                    <button
                      className="button text-[var(--green)]"
                      type="reset"
                      onClick={() => setModalType(0)}
                    >
                      <i className="fa-solid fa-arrow-left text-xl w-5"></i>
                    </button>
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {list.length}ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button
                        className="button text-[var(--green)]"
                        type="reset"
                        onClick={() => setModal(false)}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{ border: "1px solid var(--green)" }}
                      color="var(--green)"
                      placeholder="o'qituvchilar"
                      HandleChange={(event, value) => setList(value)}
                      options={worker}
                      value={list}
                    />
                    <div className="flex">
                      <input
                        type="text"
                        onKeyUp={(e) => callKeyup(e)}
                        name="money"
                        className="input"
                        placeholder="summa kiriting"
                        required
                      />
                      <select name="money_type" className="input" id="">
                        <option value="0">Naqd pul</option>
                        <option value="1">Click</option>
                        <option value="2">Plastik</option>
                      </select>
                    </div>
                    <textarea
                      rows={4}
                      name="reason"
                      className="textarea"
                      placeholder="Izoh yozish (majburiy)"
                      required
                    ></textarea>
                    <h3 className="flex m-1 mt-3 text-base items-center">
                      SMS orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sms"
                        maxLength={10}
                        className="ml-2 accent-[var(--green)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                    <h3 className="flex m-1 text-base items-center">
                      Tizim orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sytem"
                        className="ml-2 accent-[var(--green)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                  </div>
                  <div className="flex">
                    <button
                      type="submit"
                      className="bg-[--green] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      avans berish
                    </button>
                  </div>
                </form>
              ),
              4: (
                <form className="content" onSubmit={(e) => addTeacherMoney(e,1)}>
                  <div className="header flex justify-between align-center">
                    <button
                      className="button text-[var(--red)]"
                      onClick={() => setModalType(0)}
                    >
                      <i className="fa-solid fa-arrow-left text-xl w-5"></i>
                    </button>
                    <h3 className="text-2xl ml-3">
                      Tanlangan foydalanuvchilar: {list.length}ta{" "}
                    </h3>
                    <div className="flex gap-3">
                      <button
                        className="button text-[var(--red)]"
                        onClick={() => setModal(false)}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <MultiAutocomplete
                      sx={{ border: "1px solid var(--red)" }}
                      color="var(--red)"
                      placeholder="o'qituvchilar"
                      HandleChange={(event, value) => setList(value)}
                      options={worker}
                      value={list}
                    />
                    <div className="flex">
                      <input
                        type="text"
                        onKeyUp={(e) => callKeyup(e)}
                        name="money"
                        className="input"
                        placeholder="summa kiriting"
                        required
                      />
                      <select name="money_type" className="input" id="">
                        <option value="0">Naqd pul</option>
                        <option value="1">Click</option>
                        <option value="2">Plastik</option>
                      </select>
                    </div>
                    <textarea
                      rows={4}
                      name="reason"
                      className="textarea"
                      placeholder="Izoh yozish (majburiy)"
                      required
                    ></textarea>
                    <h3 className="flex m-1 mt-3 text-base items-center">
                      SMS orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sms"
                        maxLength={10}
                        className="ml-2 accent-[var(--green)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                    <h3 className="flex m-1 text-base items-center">
                      Tizim orqali habar berish
                      <input
                        type="checkbox"
                        name="notification_sytem"
                        className="ml-2 accent-[var(--green)] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full"
                      />
                    </h3>
                  </div>
                  <div className="flex">
                    <button
                      className="bg-[--red] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      jarima berish
                    </button>
                  </div>
                </form>
              ),
              5: (
                <form className="content" onSubmit={deleteWorker}>
                  <div className="body-content flex flex-col items-center">
                    <svg
                      width="237"
                      height="235"
                      viewBox="0 0 237 235"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                        stroke="#FFCC00"
                        stroke-width="4"
                      />
                      <path
                        d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                        stroke="#2D2D2B"
                        stroke-width="10"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M119.5 73.4375V124.844"
                        stroke="#FFCC00"
                        stroke-width="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                        fill="#FFCC00"
                        stroke="#FFCC00"
                        stroke-width="10"
                      />
                      <path
                        d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                        stroke="#FFCC00"
                        stroke-width="3"
                      />
                      <path
                        d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                        stroke="#FFCC00"
                        stroke-width="3.5"
                      />
                    </svg>
                    <h1 className="text-4xl">Ogohlantirish</h1>
                    <br />
                    <h3 className="text-2xl width-50 text-center">
                      Siz hozirda {list.length}ta foydalanuvchini ochirmoqdasiz va
                      bularni qaytarib bolmaydi
                    </h3>
                  </div>
                  <input
                    type="text" 
                    name="reason"
                    maxLength={125}
                    required={true}
                    className="input" placeholder="Sabab" />
                  <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                    <button
                      type="reset"
                      onClick={() => {setList([]); setModalType(0); setModal(false)}}
                      className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Ortga qaytish
                    </button>
                    <button 
                      type="submit"
                      
                      className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full">
                      O'chirish 1
                    </button>
                  </div>
                </form>
              ),
              6: (
                <form className="content" onSubmit={HandleForm}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">O'qituvchi qo'shish</h3>
                    <div className="flex gap-3">
                      <button
                        className="button"
                        type="reset"
                        onClick={() => {
                          setModal(false);
                          setModalType(0);
                        }}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid grid-cols-2 gap-5">
                      <div className="input">
                        <TextField
                          className="w-full"
                          type="text"
                          label="Ism"
                          size="small"
                          name="name"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="input">
                        <TextField
                          className="w-full "
                          type="text"
                          label="Familya"
                          size="small"
                          name="surname"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="input">
                        <TextField
                          className="w-full "
                          onChange={(e) => phoneKeyup(e)}
                          type="text"
                          label="Telefon raqami"
                          size="small"
                          autoComplete="off"
                          name="phone"
                          required
                        />
                      </div>
                      <div>
                      <div className="input">
                          <TextField
                              className="w-full "
                              onChange={(e) => changePrice(e)}
                              type="text"
                              label="Oylik ish haqi"
                              placeholder="Foiz yoki haqiqiy ish haqi"
                              autoComplete="off"
                              size="small"
                              name="salary"
                              required
                          />
                      </div>
                      </div>
                      <div className="input">
                        <Autocomplete
                          onChange={(event, newValue) => setWho(newValue)}
                          options={[{"id": 0, title: 'Admin'}, {"id": 1, title: 'Manager'}, {"id": 2, title: 'Cashier'}, {"id": 3, title: 'Teacher'}, {"id": 4, title: 'Reception'}, {"id": 4, title: 'Call operator'}]}
                          disablePortal
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          classes={{ paper: classes.paper }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField {...params} size="small" label="Ish turi" />
                          )}
                        />
                      </div>
                      <div className="input">
                        <Autocomplete
                          onChange={(event, newValue) => setCompany(newValue)}
                          options={companies}
                          disablePortal
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          classes={{ paper: classes.paper }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField {...params} size="small" label="Ishlash huquqi" />
                          )}
                        />
                      </div>
                    </div>
                    <div className="input">
                        <TextField
                          className="w-full "
                          type="text"
                          label="Maxsus raqam"
                          size="small"
                          autoComplete="off"
                          name="unique_id"
                        />
                      </div>
                  </div>
                  <div className="flex">
                    <button
                      className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      O'qituvchi qo'shish
                    </button>
                  </div>
                </form>
              ),
              7: (
                <form className="content" onSubmit={HandleUpdateForm}>
                  <div className="header flex justify-between align-center">
                    <h3 className="text-2xl ml-3">O'qituvchi qo'shish</h3>
                    <div className="flex gap-3">
                      <button
                        className="button"
                        type="reset"
                        onClick={() => {
                          setModal(false);
                          setModalType(0);
                          setList([])
                        }}
                      >
                        <i className="fa-solid fa-xmark text-xl w-5 text-[var(--blue)]"></i>
                      </button>
                    </div>
                  </div>
                  <div className="body-content">
                    <div className="grid grid-cols-2 gap-5">
                      <div className="input">
                        <TextField
                          className="w-full"
                          type="text"
                          label="Ism"
                          size="small"
                          defaultValue={list[0]?.name}
                          name="name"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="input">
                        <TextField
                          className="w-full "
                          type="text"
                          label="Familya"
                          size="small"
                          name="surname"
                          defaultValue={list[0]?.surname}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="input">
                        <TextField
                          className="w-full "
                          onChange={(e) => phoneKeyup(e)}
                          type="text"
                          label="Telefon raqami"
                          defaultValue={visiblephoneKeyup(`${list[0]?.phone}`)}
                          size="small"
                          autoComplete="off"
                          name="phone"
                          required
                        />
                      </div>
                      <div>
                      <div className="input">
                          <TextField
                              className="w-full "
                              onChange={(e) => changePrice(e)}
                              type="text"
                              label="Oylik ish haqi"
                              placeholder="Foiz yoki haqiqiy ish haqi"
                              autoComplete="off"
                              size="small"
                              defaultValue={list[0]?.absolute_salary ? visibleNumber(list[0].absolute_salary) : list[0]?.percent+'%'}
                              name="salary"
                              required
                          />
                      </div>
                      </div>
                      <div className="input">
                        <Autocomplete
                          onChange={(event, newValue) => setWho(newValue)}
                          options={whoOptions}
                          disablePortal
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          value={who}
                          classes={{ paper: classes.paper }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField {...params} size="small" label="Ish turi" />
                          )}
                        />
                      </div>
                      <div className="input">
                        <Autocomplete
                          onChange={(event, newValue) => setCompany(newValue)}
                          options={companies}
                          disablePortal
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          value={company}
                          classes={{ paper: classes.paper }}
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField {...params} size="small" label="Ishlash huquqi" />
                          )}
                        />
                      </div>
                    </div>
                    <div className="input">
                        <TextField
                          className="w-full "
                          type="text"
                          label="Maxsus raqam"
                          defaultValue={list[0]?.unique_id}
                          size="small"
                          autoComplete="off"
                          name="unique_id"
                        />
                      </div>
                  </div>
                  <div className="flex">
                    <button
                      title="xabar berish"
                      className="bg-[--blue] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Tahrirlash
                    </button>
                  </div>
                </form>
              ),
            }[modalType]
          }
        </div>
      </>
    );
  }
  else{
    return  <ErrorPage />
  }
}
