import React, { useState, useEffect } from "react";
import {useLocation, useParams} from 'react-router-dom'
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import Notification from "../../components/alert"
import { rootPath } from "../../utils/main"

import ErrorPage from "../error-page"

import axios from "axios";

export default function StudentAddForm() {
    const [subjects, setSubjects] = useState([]);
    const [listStudent, setlistStudent] = useState([]);
    const [company, setCompany] = useState(null)
    const [factory, setFactory] = useState(null)
    const [subject, setSubject] = useState(null);
    const [subjectOption, setSubjectOption] = useState([]);
    const [gradeValue, setGradeValue] = useState(null);
    const [gradeOptions, setGradeOptions] = useState([]);
    const [error, setError] = useState(false);

    const location = useLocation();
    const params = useParams();
    const slug = params.slug
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
            axios
            .get("main/online-form/?company=" + searchParams.get('company'))
            .then(res => {
                setSubjectOption(res.data.subject);
                setFactory(res.data.factory_data);
                setCompany(res.data.company_data);
                setError(false)
                console.log(res.data.factory_data);
            })
            .catch(error => {
                setError(true)
            })
    }, [])

    useEffect(() => {
        if (subject !== null){
            axios
            .get("main/online-form-grade/?company=" + searchParams.get('company') + "&subject=" + subject.id)
            .then(res => {
                setGradeOptions(res.data);
                setError(false)
            })
        }
        else{
            setGradeOptions([])
            setGradeValue(null)
        }
    },[subject])
    
    function phoneKeyup(e) {
        var y = e.target.value.replaceAll("+998 ", "").replaceAll(/\s/g, "");
        var x = y.replaceAll(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
        if (y > 0) {
            e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${x[3] ? " " + x[3] : ""
                }${x[4] ? " " + x[4] : ""}`;
        } else {
            e.target.value = "";
        }
    }

    function dateKeyup(e) {
        var y = e.target.value.replaceAll("/", "");
        var x = y.replaceAll(/\D/g, "").match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
        if (e.target.value.length) {
            if (+x[2] > 12) {
                x[2] = "12";
            }
            if (x[2] === "00") {
                x[2] = "01";
            }
            var checkDate = new Date(x[1], x[2], 0).getDate();
            if (+x[3] > checkDate) {
                x[3] = checkDate;
            }
            if (x[3] === "00") {
                x[3] = "01";
            }
            e.target.value = `${x[1] ? x[1] : ""}${x[2] ? "-" + x[2] : ""}${x[3] ? "-" + x[3] : " "
                }`;
        } else {
            e.target.value = "";
        }
    }
    function sendData(e){
        e.preventDefault();
        let data = new FormData(e.target);
        let sender = {
            full_name: data.get('full_name'),
            phone: data.get('phone'),
            perent_phone: data.get('parent_phone'),
            birthday: data.get('birthdate'),
            subject: subject.id,
            grade: gradeValue.id,
            comment: data.get('comment'),
            where_slug: slug,
        }

        axios.post('main/online-form/?company=' + searchParams.get('company'), sender)
        .then(response => {
            setGradeValue(null)
            setSubject(null)
            Notification(
                "Muvaffiqayatli amalga oshdi",
                "Tez orada siz bilan bog'lnamiz",
                "var(--purple)",
                3000
            )
            e.target.reset()
        })
    }


    if(error){
        
        return <div className="w-full h-screen">
            <ErrorPage />
        </div>
    }
    else{
        return (
            <div className="grid place-items-center w-screen h-screen mt-1 overflow-x-auto">
                <div>
                    <div className="bg-[var(--theme-sidebar)] my-3 p-5 lg:p-[50px] flex lg:flex-nowrap flex-wrap items-center justify-between rounded-xl">
                        <div>
                            <h1 className="text-2xl"><strong>{factory?.name}</strong> -- {company?.title}</h1>
                            <h3 className="text-xl mt-3"><a href="tel:+{}">{company?.contact_number}</a></h3>
                        </div>
                        <div>
                            <img src={`${rootPath}${factory?.logo}`} width={"140px"} alt="" />
                        </div>
                    </div>
                    <div className="bg-[var(--theme-sidebar)] p-5 lg:p-[50px] rounded-xl">
                        <h1 className="text-3xl">Ro'yxatdan o'ting</h1>
                        <br />
                        <form onSubmit={sendData} className='grid lg:grid-cols-2 gap-5'>
                            <input
                                type="text"
                                className="input lg:min-w-[450px]"
                                name='full_name'
                                placeholder='Ism familya' 
                                required
                                />
                            <input
                                type="text"
                                className="input"
                                placeholder="Tug'ilgan yili"
                                name="birthdate"
                                onChange={dateKeyup}
                                require
                            />
                            <input
                                type="text"
                                className="input lg:min-w-[450px]"
                                name='phone'
                                onChange={phoneKeyup}
                                placeholder='Telefon raqami'
                                required
                                />
                            <input
                                type="text"
                                className="input lg:min-w-[450px]"
                                name='parent_phone'
                                onChange={phoneKeyup}
                                placeholder='Ota ona telefon raqami'
                                required
                                />
            
                            <div className="input lg:min-w-[450px]">
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                    setSubject(newValue);
                                    }}
                                    value={subject}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    disablePortal
                                    id="combo-box-demo"
                                    className="w-full "
                                    options={subjectOption}
                                    getOptionLabel={(options) => options.title}
                                    renderInput={(params) => (
                                    <TextField {...params} size="small" label="Fan" />
                                    )}
                                />
                            </div>
                            <div className="input lg:min-w-[450px]">
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setGradeValue(newValue);
                                    }}
                                    value={gradeValue}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    disablePortal
                                    id="combo-box-demo"
                                    className="w-full "
                                    options={gradeOptions}
                                    getOptionLabel={(options) => options.title}
                                    renderInput={(params) => (
                                    <TextField {...params} size="small" label="Daraja nomi" />
                                    )}
                                />
                            </div>
                            <textarea name="comment" placeholder="Izoh" className="input" rows="2"></textarea>
                            <div className="flex items-center justify-center">
                                <button 
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-700 mt-4 text-xl min-w-[200px] text-white font-bold py-2 px-4 rounded-xl">
                                Jo'natish
                                </button>
                            </div>
                        </form>
                    </div>  
                </div>
            </div>
        )
    }
};
