import React, { useState, useEffect } from "react";
import studentStyles from "../../assets/css/reception.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Notification from "../../components/alert";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import axios from "axios";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
});
const styles = (theme) => ({
  multilineColor: {
    color: "red",
  },
});
export default function Collection() {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [listCollection, setListCollections] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [update, setUpdate] = useState({});
  const [contactDate, setContactDate] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("leads/student-set/")
      .then((response) => {
        setCollections(response.data);
        setFilteredCollections(response.data);
      })
      .catch((error) => navigate(`/errors/${error.request.status}`));
  }, [useLocation()]);

  useEffect(() => {
    axios
      .get("leads/student-set/", {
        params: {
          time_alert: contactDate
            ? `${contactDate.$y}-${contactDate.$M + 1}-${contactDate.$D}`
            : "",
        },
      })
      .then((response) => {
        setCollections(response.data);
        setFilteredCollections(response.data);
      });
  }, [contactDate]);

  const handleCheckCollecChange = (e) => {
    if (e.target.checked) {
      const allstudents = collections.map((c) => c);
      setListCollections(allstudents);
    } else {
      setListCollections([]);
    }
  };
  const handleCollectionChange = (e, collection) => {
    if (e.target.checked) {
      setListCollections([...listCollection, collection]);
    } else {
      setListCollections(
        listCollection.filter((item) => item.id !== collection.id)
      );
    }
  };

  function addCollection(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    console.log({
      title: data.get("title"),
      time_alert: data.get("datetime"),
    });
    axios
      .post("leads/student-set-create/", {
        title: data.get("title"),
        time_alert: data.get("datetime"),
      })
      .then(function (response) {
        e.target.reset();
        Notification(
          "Qo'shildi",
          "Mufaqiyatli amalga oshdi",
          "var(--green)",
          5000
        );
        setCollections([...collections, response.data]);
        setFilteredCollections([...collections, response.data]);
      })
      .catch((error) => {
        Notification(
          "Tog'ri to'ldiring",
          "Yoki keyinroq urinib koring",
          "var(--red)",
          5000
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  }
  function editObj(obj) {
    console.log(obj);
    setUpdate(obj);
    setModalType(2);
    setModal(true);
  }

  function updateCollection(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    console.log({
      title: data.get("title"),
      time_alert: data.get("datetime"),
      id: data.get("id"),
    });
    axios
      .put("leads/student-set-create/", {
        title: data.get("title"),
        time_alert: data.get("datetime"),
        id: data.get("id"),
      })
      .then(function (response) {
        e.target.reset();
        Notification(
          "Qo'shildi",
          "Mufaqiyatli amalga oshdi",
          "var(--green)",
          5000
        );
        let list = collections;
        console.log(list);
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === response.data.id) {
            list[i]["time_alert"] = response.data.time_alert;
            list[i]["title"] = response.data.title;
            list[i]["created_by"] = response.data.created_by;
          }
          setCollections(list);
          setFilteredCollections(list);
          setModalType(0);
          setModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DeleteCollection() {
    let arr = listCollection.map((item) => item.id);
    console.log(arr);
    axios
      .post(`/leads/delete-students-sets/`, {
        ids: arr,
      })
      .then(function (response) {
        Notification(
          "Mufaqiyatli amalga oshdi",
          "O'quvchilar o'chirildi",
          "var(--red)",
          5000
        );
        setCollections(collections.filter((item) => !arr.includes(item.id)));
        setFilteredCollections(
          collections.filter((item) => !arr.includes(item.id))
        );
        setListCollections(
          listCollection.filter((item) => !arr.includes(item.id))
        );
        setModal(false);
        setModalType(0);
      })
      .catch(function (error) {
        Notification(
          "Internet bilan muomo",
          "Nimadir ishlamayapdi",
          "var(--yellow)",
          5000
        );
      });
  }

  const classes = useStyles();

  function SortCollection(value) {
    let simple = [];
    if (value.trim() !== "") {
      for (let item of collections) {
        if (item.title.toLowerCase().includes(value.toLowerCase())) {
          simple.push(item);
        }
      }
      setFilteredCollections(simple);
    } else {
      setFilteredCollections(collections);
    }
  }
  return (
    <>
      <div className={studentStyles.main_page}>
        <div className="br-card">
          <br />
          <div className={studentStyles.header_content}>
            <div className="flex gap-4 flex-wrap my-4">
              <h2 className="text-3xl m-3 font-bold">Tayyor To'plamlar</h2>
            </div>
            <div className="flex gap-4 items-center flex-wrap my-4">
              <button
                onClick={function () {
                  setModal(true);
                  setModalType(0);
                }}
                className={`${studentStyles.button}  text-[var(--purple)]`}
              >
                <i className="fa-sharp fa-solid fa-plus text-xl"></i>
              </button>
              <button
                onClick={function () {
                  setModal(true);
                  setModalType(1);
                }}
                className={`${studentStyles.button}`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.687 7.4375L5.31201 7.43751"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.8125 13.8125V22.3125"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.1875 13.8125V22.3125"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                    stroke="#FC3F1D"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="d-flex items-center gap-">
            <div className="bg-[var(--theme-bg)] flex items-center my-3  rounded-[15px] p-4 px-[20px]">
              <i className="fa-duotone fa-calendar text-2xl mx-3 text-[var(--theme)]"></i>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month", "day"]}
                  label={"Bog'lanish sanasi"}
                  value={contactDate}
                  onChange={(newValue) => setContactDate(newValue)}
                  slotProps={{
                    textField: {
                      size: "small",
                      style: { width: "100%" },
                      variant: "standard",
                      clearable: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <TextField
              onChange={(e) => SortCollection(e.target.value)}
              label="Qidiruv"
              size="small"
            />
          </div>
          <div className={studentStyles.table_cap}>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className={studentStyles.checkbox}
                      checked={
                        collections?.length === listCollection?.length &&
                        collections?.length !== 0
                      }
                      onChange={handleCheckCollecChange}
                    />
                  </th>
                  <th>Nomi</th>
                  <th>Keyingi bog'lanish sanasi</th>
                  <th>Ochilgan sana</th>
                  <th>O'quvchilar soni</th>
                  <th>Kim tomonidan qo'shilgan</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredCollections.map((collection, index) => (
                  <tr key={index}>
                    <th>
                      <input
                        type="checkbox"
                        id="checkbox"
                        className={studentStyles.checkbox}
                        checked={listCollection.includes(collection)}
                        onChange={(e) => handleCollectionChange(e, collection)}
                      />
                    </th>
                    <td>
                      <NavLink to={`/reception/students-sets/${collection.id}/`}>
                        <strong>{collection.title}</strong>
                      </NavLink>
                    </td>
                    <td>
                      {collection.time_alert &&
                        new Date(collection.time_alert).toLocaleString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                    </td>
                    <td>
                      {new Date(collection.created_at).toLocaleString([], {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </td>
                    <td>{collection.students_count}</td>
                    <td>
                      <NavLink
                        to=""
                        className="bg-[var(--theme)] text-white  p-2 px-3 rounded-full"
                      >
                        <i className="fa-solid fa-user-secret mx-1"></i>
                        {collection?.created_by?.name || "anonim"}
                      </NavLink>
                    </td>
                    <td>
                      <button
                        className="bg-[var(--yellow)] text-white h-[40px] w-[40px] rounded-full"
                        onClick={(e) => editObj(collection)}
                      >
                        <i className="fa-light fa-pen-to-square"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <br />

            <NavLink
              to="/reception/collection"
              style={{ color: "var(--theme)" }}
              className={
                "underline hover:no-underline d-flex text-xl justify-center"
              }
            >
              Yana ko'rish uchun bosing
            </NavLink>
          </div>
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
        {
          {
            0: (
              <form className="content" action="" onSubmit={addCollection}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Tayyor to'plam qo'shish</h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input
                      type="text"
                      className="input col-span-1 md:col-span-2 "
                      name="title"
                      placeholder="To'plam nomi"
                      required
                    />
                    <div></div>
                    <h1 className="col-span-1 md:col-span-2 text-2xl mt-5 text-[var(--theme)]">
                      Qayta ogohlantarish sanasi
                    </h1>
                    <input
                      type="datetime-local"
                      className="input col-span-1 md:col-span-2 "
                      name="datetime"
                      placeholder="Qo'shilgan payti"
                      required
                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    To'plamga qo'shish
                  </button>
                </div>
              </form>
            ),
            // Ochirish
            1: (
              <div className="content">
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz hozirda {listCollection.length} ta Oq'uvchini
                    ochirmoqdasiz va bularni qaytarib bolmaydi
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    onClick={function () {
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    onClick={DeleteCollection}
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </div>
            ),
            2: (
              <form className="content" action="" onSubmit={updateCollection}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Tayyor to'plam qo'shish</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setUpdate({});
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input type="hidden" name="id" defaultValue={update.id} />
                    <input
                      type="text"
                      className="input col-span-1 md:col-span-2 "
                      name="title"
                      placeholder="To'plam nomi"
                      required
                      defaultValue={update.title}
                    />
                    <div></div>
                    <h1 className="col-span-1 md:col-span-2 text-2xl mt-5 text-[var(--theme)]">
                      Qayta ogohlantarish sanasi
                    </h1>
                    <input
                      type="datetime-local"
                      className="input col-span-1 md:col-span-2 "
                      name="datetime"
                      placeholder="Qo'shilgan payti"
                      defaultValue={
                        update.time_alert &&
                        new Date(update.time_alert).toISOString().slice(0, -1)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    To'plamga qo'shish
                  </button>
                </div>
              </form>
            ),
          }[modalType]
        }
      </div>
    </>
  );
}
const top100Films = [
  { label: "Ingliz tili", id: 1 },
  { label: "Rus tili", id: 2 },
  { label: "Koreys tili", id: 3 },
  { label: "Ingliz spsh", id: 4 },
  { label: "Rus dchj", id: 5 },
  { label: "koreys tili beginner", id: 6 },
  { label: "Ingliz tili Upper", id: 7 },
];
