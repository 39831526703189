
const formatNum = (num) => {
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true
      });  
    return formatter.format(num);
};

const generateRL = (len) => {
    var letters = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
    var result = 'rE';
    for (let i = 0; i < len; i++) {
        result += letters[Math.ceil(Math.random() * letters.length)]
    };
    return result
};

const getDate = (given_date, is_hours=true) => {
    const date =  new Date(given_date)
    let month = ['Yan', 'Feb', 'Mar', 'Apr', 'May', 'Iyun', 'Iyul','Avg', 'Sen', 'Okt','Noy', 'Dekabr']
    const visibledate = `${date.getDate()}  ${month[date.getMonth()]} ${ date.getFullYear()} `
    let hours
    if (is_hours) {
        hours = ", " + date.toLocaleString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        })
    }
    else{
        hours = ""
    }
    return `${visibledate}${hours}`
}

function visibleNumber(value) {
    let str_value = value.toString()
    var n = parseInt(str_value.replaceAll(/ /g, ""), 10);
    n = n.toLocaleString().replaceAll(",", " ");
    if (n) {
      return n;
    } else {
      return "";
    }
  }

const rootPath = "https://api1.timeschool.uz"
export { formatNum, generateRL,getDate, visibleNumber, rootPath };




