import React, { useState, useEffect } from "react";
import budgetStyle from "../../../assets/css/budget.module.css";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from "../../../components/loader";

function Budget() {
  const [isLoading, setIsLoading] = useState(true);
  const [incomeDrop, setIncomeDrop] = useState(false);
  const [outcomeDrop, setOutcomeDrop] = useState(false);
  const [outcomeForm, setOutcomeForm] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [begin_date, setBeginDate] = useState("");
  const [finish_date, setFinishDate] = useState("")
  const [report, setReport] = useState(0);

  function callKeyup(e) {
    if (e.target.value.length < 12) {
      var n = parseInt(e.target.value.replaceAll(/\D/g, ""), 10);
      if (n) {
        e.target.value = n.toLocaleString().replaceAll(",", " ");
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = e.target.value.toLocaleString().slice(0, -1);
    }
  }

  useEffect(() => {
    if (begin_date, finish_date){
      setIsLoading(false)
        axios
          .get("budget/reports/factory", {params: {
            begin_date: `${begin_date.$y}-${begin_date.$M +1}-${begin_date.$D}`,
            finish_date: `${finish_date.$y}-${finish_date.$M +1}-${finish_date.$D}`,
        }})
          .then(function (response) {
            setBudgetList(response.data.data);
            setReport(response.data.report);
            setIsLoading(true)
          });
    }
  }, [finish_date, begin_date]);

  function visibleNumber(value) {
    var n = parseInt(value.replaceAll(/ /g, ""), 10);
    n = n.toLocaleString().replaceAll(",", " ");
    if (n) {
      return n;
    } else {
      return "";
    }
  }

  return (
    <>
    {!isLoading ?
        <Loader />
        :
        (
          <>
                <div className="flex container items-start md:justify-between justify-center flex-wrap md:mx-5 my-5 gap-5">
        <div className={budgetStyle.card}>
          <NavLink to="/budget/" className="text-xl text-[var(--theme)]">
            Kunlik Hisobotlar
          </NavLink>
          <h3 className="text-2xl ">
            <i className="fa-sharp fa-solid fa-diagram-subtask mx-3"></i>
            Boshqa Hisobotlar
          </h3>
        </div>

        <div
          className={`${budgetStyle.card} ${incomeDrop && budgetStyle.active}`}
        >
          <div className="flex items-center justify-between">
            <h3 className="text-3xl text-[var(--green)]">Kirim</h3>
            <div>
              <i
                className={`fa-sharp fa-solid fa-angle-up text-2xl ${budgetStyle.toggle}`}
                onClick={() => setIncomeDrop((prev) => !prev)}
              ></i>
              <NavLink to={"/get-payment/"}>
                <i
                  className={`text-2xl fa-solid  fa-plus mx-1 budgetStyle.toggle}`}
                ></i>
              </NavLink>
            </div>
          </div>

          <h4 className="text-2xl">
            {visibleNumber(`${report?.overall_income}`)}
          </h4>

          <div className={`${budgetStyle.drop}`}>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-solid fa-coin"></i>
                <h4 className="inline ml-2">Naqd pul</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_cash}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-sharp fa-solid fa-money-bill-transfer"></i>
                <h4 className="inline ml-2">Pul o'tkazish orqali</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_online}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-solid fa-credit-card"></i>
                <h4 className="inline ml-2">Plastik orqali</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_card}`)}</h5>
            </div>
          </div>
        </div>
        <div
          className={`${budgetStyle.card} ${
            outcomeDrop && budgetStyle.active
          } ${outcomeForm && budgetStyle.from_activate}`}
        >
          <div className="flex items-center justify-between">
            <h3 className="text-3xl text-[var(--red)]">Chiqim</h3>
            <div>
              <i
                className={`fa-sharp fa-solid fa-angle-up text-2xl mx-1 ${budgetStyle.toggle}`}
                onClick={() => (
                  setOutcomeDrop((prev) => !prev), setOutcomeForm(false)
                )}
              ></i>
              
            </div>
          </div>
          <h4 className="text-2xl">
            {visibleNumber(`${report.overall_outcome}`)}
          </h4>

          <div className={`${budgetStyle?.drop}`}>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-light fa-money-check-pen"></i>
                <h4 className="inline ml-2">Maoshlar</h4>
              </div>
              <h5>1 000 000</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-solid fa-chart-mixed"></i>
                <h4 className="inline ml-2">Jarimalar</h4>
              </div>
              <h5>{visibleNumber(`${report?.total_punishment}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-solid fa-hand-holding-dollar"></i>
                <h4 className="inline ml-2">Avanslar</h4>
              </div>
              <h5>{visibleNumber(`${report?.total_advance}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-thin fa-sack-dollar"></i>
                <h4 className="inline ml-2">Boshqa</h4>
              </div>
              <h5>{visibleNumber(`${report?.outlay_sum}`)}</h5>
            </div>
          </div>
          <div className={`${budgetStyle.from}`}>
            <br />
            <div className={budgetStyle.group_input}>
              <i className="fa-sharp fa-light fa-pen-ruler"></i>
              <input
                type="text"
                className="bg-transparent"
                placeholder="Chiqim nomi"
              />
            </div>
            <div className={budgetStyle.group_input}>
              <i className="fa-solid fa-dollar-sign"></i>
              <input
                type="text"
                placeholder="Summa"
                className="bg-transparent"
                onChange={(e) => callKeyup(e)}
              />
            </div>
            <div className={budgetStyle.group_input}>
              <i className="fa-solid fa-receipt"></i>
              <input
                type="text"
                className="bg-transparent"
                placeholder="Izoh"
              />
            </div>
            <br />
            <button className="hover:bg-transparent w-full bg-[var(--green)]  hover:text-[var(--green)] font-semibold text-white py-2 px-4 border rounded-xl hover:border-[var(--green)]  hover:border-transparent rounded">
              Qo'shish
            </button>
          </div>
        </div>
        <div className={budgetStyle.card}>
          <h3 className="text-3xl text-[var(--purple)]">Jami</h3>
          <h4 className="text-2xl">
            {visibleNumber(`${report?.overall_income - report?.overall_outcome}`)}
          </h4>
        </div>
      </div>
      <div className="bc-card">
        <div className="flex flex-wrap gap-5">
          <div className="bg-[var(--theme-bg)] flex items-center my-3  rounded-[15px] p-4 px-[20px]">
            <i className="fa-duotone fa-calendar text-2xl mx-3 text-[var(--green)]"></i>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month", "day"]}
                label={"boshlanish sanasi"}
                value={begin_date}
                onChange={(newValue) => setBeginDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: "100%" },
                    variant: "standard",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="bg-[var(--theme-bg)] flex items-center my-3  rounded-[15px] p-4 px-[20px]">
            <i className="fa-duotone fa-calendar text-2xl mx-3 text-[var(--red)]"></i>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                views={["year", "month", "day"]}
                label={"tugash sanasi"}
                value={finish_date}
                onChange={(newValue) => setFinishDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: "100%" },
                    variant: "standard",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="table-container">
          <div className="table-horizontal-container">
            <table className="table-information">
              <tbody>
                {budgetList?.map((item, index) => {
                  const WHY_GIVEN = ["advance", "punishment", "bonus"];
                  const type_of_tAction = [
                    "Avans olindi",
                    "Jarima solindi",
                    "Mukofat puli",
                  ];
                  return {
                    0: (
                      <tr className="outcome">
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.title}</span>
                          <br />
                          <a href="">{item.reason}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>..</span>
                        </td>
                        <td>
                          <span>Chiqim</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                      </tr>
                    ),
                    1: (
                      <tr className={WHY_GIVEN[item.why_given]}>
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.people_name}</span>
                          <br />
                          <a href={`tel: {people_name}`}>{item.people_phone}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>{item.reason}</span>
                        </td>
                        <td>
                          <span>{type_of_tAction[item.why_given]}</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                      </tr>
                    ),
                    2: (
                      <tr className="payment">
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.people_name}</span>
                          <br />
                          <a href={`tel: {people_name}`}>{item.people_phone}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>{item.payment_month} uchun</span>
                        </td>
                        <td>
                          <span>Oylik To'lov</span>
                          <br />
                          <span>
                            {visibleNumber(`${item.money_with_discount}`)}{" "}
                            {item.discount > 0 && (
                              <strong className="text-[var(--red)]">
                                {item.discount}%
                              </strong>
                            )}
                          </span>
                        </td>
                      </tr>
                    ),
                  }[item["list_type"]];
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
          </>
        )
        }

    </>
  );
}
export default Budget;
