import React, {useState, useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { NavLink } from "react-router-dom";
import axios from "axios"
import {
    Autocomplete,
    TextField
} from "@mui/material";

const useStyles = makeStyles({
    paper: {
        backgroundColor: "var(--theme-bg)"
    }
});

export default function GetPayment(params) {
    const classes = useStyles();

    const [search, setSearch] = useState("");
    const [student, setStudent] = useState([])

    useEffect(() => {
        if(search){
            axios
            .get("budget/payment/get/student/", {params: {
                search: search
            }})
            .then((response) => {
                setStudent(response.data)
            })
        }
        else{
            setStudent([])
        }
    },[search])


    return (
        <>
            <div className="bg-[var(--theme-sidebar)] flex-wrap rounded-3xl p-6 d-flex gap-5">
                <div className="bg-[var(--theme-bg)] flex items-center w-full lg:w-1/2 my-3 rounded-[15px] p-4 px-[20px]">
                    <i className="fa-duotone fa-user text-2xl mx-3 text-[var(--theme)]"></i>
                    <TextField size="small" className='w-full' variant='standard' onChange={(e) => setSearch(e.target.value)} placeholder="o'quvchi ismi" />
                </div>
            </div>
            <br />
            <div className="br-card p-4">
                <div className="w-full">
                    <div className="table-horizontal-container max-h-[400px] overflow-auto">
                        <table className='table-border td-min-width'>
                            <thead>
                                <tr>
                                    <th colSpan={1}>&#8470;</th>
                                    <th colSpan={2}>Ism Familya</th>
                                    <th colSpan={2}>Nomer</th>
                                    <th colSpan={2}>Nomer</th>
                                    <th colSpan={1}>amallar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {student.length ? student.map((item, index) =>{

                                    return(
                                        <tr key={index}>
                                            <td colSpan={1}>{index + 1}</td>
                                            <td colSpan={2}>{item.full_name}</td>
                                            <td colSpan={2}>{item.phone}</td>
                                            <td colSpan={2}>{item.parent_phone}</td>
                                            <td colSpan={1}>
                                            <NavLink 
                                                to={"/get-payment/" +item.id}
                                                style={{padding: "10px", margin: "15px"}}
                                                className="bg-[var(--theme)] text-white truncate mt-3 rounded-full cursor-pointer">
                                                <i className="fa-solid fa-file-invoice-dollar mx-1"></i>
                                                To'lov olish
                                            </NavLink>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                (
                                    <tr>
                                        <td colSpan={8}><h3 className='text-4xl text-[var(--red)]'>O'quvchi topilmadi</h3></td>
                                    </tr>
                                )
                                }
                            </tbody>
                            <tfoot>
                                <tr className=''>
                                    <td colSpan={1} style={{backgroundColor: "transparent"}}></td>
                                    <td colSpan={2} style={{backgroundColor: "transparent"}}></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={1}></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};
