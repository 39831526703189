import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Notification from "../../components/alert";
import defaultPic from "../../assets/img/braincore.png";
import Logo from "../../assets/img/braincore.png"
import axios from "axios";
import { rootPath } from "../../utils/main"

export default function Messages(params) {
  let messageId = useParams();
  const activeClass =
    "inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg text-[var(--theme)] border-0 border-[var(--theme)]  group";
  const activeSvgClass = "w-4 h-4 mr-2 text-[var(--theme)]  dark:text-blue-500";
  const simpelClass =
    "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg text-[var(--theme-color)] hover:text-[var(--theme)] hover:border-[var(--theme)]  group";
  const simpelSvgClass =
    "w-4 h-4 mr-2 text-[var(--theme-color)] group-hover:text-[var(--theme)]";
  const navigate = useNavigate();

  const [chat, setChat] = useState(false);
  const [responseUsers, setResponseUsers] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [chatWith, setChatWith] = useState(null);
  const [getUser, setGetUser] = useState(null);
  const [messages, setMessages] = useState(null);
  const [factory, setFactory] = useState(null)
  const [room, setRoom] = useState(null);

  const mountedStyle = { animation: "inAnimation 250ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards",
  };

  function visiblephoneKeyup(value) {
    if (value){
        var y = value.replaceAll("+998", "").replaceAll(" ", "");
        var x = y
          .replaceAll(/\D/g, "")
          .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
        if (y > 0) {
          return `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
            x[3] ? " " + x[3] : ""
          }${x[4] ? " " + x[4] : ""}`;
        } else {
          return "";
        }
    }
  }

  useEffect(() => {
    axios.get("chat/").then((res) => {
      setResponseUsers(res.data);
      setChatUsers(res.data);
    });
    axios
      .get("chat/" + messageId.id + "/")
      .then((res) => {
        setRoom(res.data.id);
        setMessages(res.data.messages);
        setChatWith(
          res.data.users.filter((item) => item.id == messageId.id)[0]
        );
        setGetUser(res.data.users.filter((item) => item.id != messageId.id)[0]);
      })
      .catch((res) => {
        if (res.response.status === 301) {
          navigate("/messages/");
          Notification(
            "Xato",
            "O'zingiz bilan o'zingiz yozisha olmaysiz",
            "var(--red)",
            5000
          );
        }
      });


      axios.get("chat/get/work-on/")
      .then((res) => {
          setFactory(res.data)
      })
  }, [useLocation()]);

  useEffect(() => {
    setInterval(() => {
      axios.get("chat/" + messageId.id + "/").then((res) => {
        setRoom(res.data.id);
        setMessages(res.data.messages);
        setChatWith(
          res.data.users.filter((item) => item.id == messageId.id)[0]
        );
        setGetUser(res.data.users.filter((item) => item.id != messageId.id)[0]);
      });
    }, 60000);
  }, []);
  function refreshData() {
    axios.get("chat/" + messageId.id + "/").then((res) => {
      setRoom(res.data.id);
      setMessages(res.data.messages);
      setChatWith(res.data.users.filter((item) => item.id == messageId.id)[0]);
      setGetUser(res.data.users.filter((item) => item.id != messageId.id)[0]);
    });
  }
  function FilterUsers(e) {
    let value = e.target.value.toLowerCase();
    let sorted = responseUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.surname.toLowerCase().includes(value) ||
        user.phone.toLowerCase().includes(value)
    );
    setChatUsers(sorted);
  }
  function sendMessage(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    let sendMeesage = {
      title: data.get("title"),
      chatId: room,
    };
    axios.post("chat/" + messageId.id + "/", sendMeesage).then((res) => {
      e.target.reset();
      setMessages(res.data);
    });

    axios.get("chat/get/work-on/").then((res) => {
      setFactory(res.data);
    });
  }

  return (
    <div className="grid grid-cols-12 gap-5">
      <div className="lg:col-span-5 col-span-12 bg-[var(--theme-sidebar)]  rounded-3xl p-5">
        <NavLink to="/messages" className="flex justify-between">
          <h1 className="text-2xl mt-1">
            <i className="fa-brands fa-discord text-2xl mx-2"></i>
            Chatlar
          </h1>
          <i
            className={`fa-solid fa-angle-down text-3xl lg:hidden ${
              !chat && "d-block rotate-180"
            }`}
            onClick={() => setChat((current) => !current)}
          ></i>
        </NavLink>
        <br />
        <div className={`${!chat ? "hidden lg:block" : "lg:block"}`}>
          <br />
          <form>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-[var(--theme-color)] sr-only "
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none">
                <svg
                  className="w-4 h-4 text-[var(--theme-color)]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-base text-[var(--theme-color)]  rounded-3xl border-2 bg-[var(--theme-bg)] border-[var(--theme-color)] focus:border-[var(--theme)] outline-none"
                onChange={FilterUsers}
                placeholder="Qidiruv"
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-[var(--theme)] hover:opacity-80 font-medium rounded-lg text-sm px-4 py-2 "
              >
                Search
              </button>
            </div>
          </form>

          <br />
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700 pr-5 overflow-y-auto"
            style={{ height: "calc(100vh - 450px)" }}
          >
            <NavLink to={`/messages/group`} className={"m-0 p-0 chat"}>
              <div className="py-3 sm:py-4 hover:bg-[var(--theme-bg)] px-3 rounded-xl">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      className="w-11 h-11 p-1 rounded-full ring-2 ring-[var(--theme)]"
                      src={factory?.factory.logo ? `${rootPath}${factory?.factory.logo}` : Logo}
                      alt="Bordered avatar"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium truncate">
                      {factory?.title} {factory?.factory.name}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {visiblephoneKeyup(factory?.contact_number)}
                    </p>
                  </div>
                </div>
              </div>
            </NavLink>
            <div className="w-[95%] bg-[var(--oppocity-bg)] mx-auto m-5"></div>
            {chatUsers.map((item, index) => (
              <NavLink to={`/messages/room/${item.id}`} key={`user-${index}`} className={"m-3 p-0 chat"}>
                <div className="py-3 sm:py-4 hover:bg-[var(--theme-bg)] px-3 hover:rounded-xl">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        className="w-11 h-11 p-1 rounded-full ring-2 ring-[var(--theme)]"
                        src={`${rootPath}${item.avatar_select_display}`}
                        alt="Bordered avatar"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium truncate">
                        {item.name} {item.surname}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {visiblephoneKeyup(item.phone)}
                      </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
      <div className="lg:col-span-7 col-span-12 bg-[var(--theme-sidebar)]  rounded-3xl p-5">
        <div className="flex items-center space-x-4 bg-[var(--theme-bg)] p-3  rounded-3xl">
          <div className="flex-shrink-0">
            <img
              className="w-[60px] h-[60px] object-contain p-1 rounded-full ring-2 ring-[var(--theme)]"
              src={
                chatWith
                  ? `${rootPath}${chatWith?.avatar_select_display}`
                  : defaultPic
              }
              alt="Bordered avatar"
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-lg font-medium truncate">
              {chatWith?.name} {chatWith?.surname}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
              {visiblephoneKeyup(`${chatWith?.phone}`)}
            </p>
          </div>
        </div>
        <br />
        <br />
        <div
          className="px-2 overflow-y-auto"
          style={{ height: "calc(100vh - 450px)" }}
        >
          {messages?.map((item, index) => {
            const sender = "flex w-full mt-2 space-x-3 max-w-xs";
            const reciver =
              "flex w-full mt-2 space-x-3 max-w-xs ml-auto gap-3 flex-row-reverse";
            return (
              <div
                key={index}
                className={item.written_by == messageId.id ? sender : reciver}
              >
                <img
                  className="w-10 h-10 object-contain p-1 rounded-full ring-2 ring-[var(--theme)]"
                  src={
                    item.written_by == messageId.id
                      ? rootPath +
                        chatWith?.avatar_select_display
                      : rootPath  + getUser?.avatar_select_display
                  }
                  alt="Bordered avatar"
                />
                <div>
                  <div
                    className={
                      item.written_by != messageId.id
                        ? `bg-[var(--theme-bg)] p-3 rounded-r-lg rounded-bl-lg`
                        : "bg-[var(--theme)] text-white p-3 rounded-r-lg rounded-bl-lg"
                    }
                  >
                    <p className="text-sm ">{item.title}</p>
                  </div>
                  <span className="text-xs text-gray-500 leading-none">
                    {new Date(item.created_at).toLocaleString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <form onSubmit={sendMessage}>
          <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
            <button
              type="button"
              onClick={refreshData}
              className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <i className="fa-duotone fa-arrows-rotate"></i>
            </button>

            <textarea
              name="title"
              id="chat"
              rows="2"
              className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your message..."
            ></textarea>
            <button
              type="submit"
              className="inline-flex justify-center p-2 text-[var(--theme)] rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
            >
              <svg
                className="w-5 h-5 rotate-90 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
              </svg>
              <span className="sr-only">Send message</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
