import React, { useState } from 'react';

export default function MarkDrop(props) {


    const [value, setValue] = useState(-1)
    const [color, setColor] = useState('#000')

    function handleChange(e){
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('color');
        setColor(option)
        props.getID(e.target.value)
    }

    React.useEffect(() => {
        const colors = ["#505050", "var(--red)", "#D2488A", "var(--yellow)", "var(--purple)", "var(--green)"]
        if (props.value !== null) {
            setColor(colors[parseInt(props.value)])
        }
        else{
            setColor("")
        }
    }, [props.value])

    // console.log(color)

    return (
        <div className={`status_bar bg-[${color}]`} >
            <select 
                name="" id=""
                className={`w-[80%] m-1 text-[#000] text-center rounded-lg outline-none`}
                onChange={handleChange}
            >
                <option selected={props.value === "0"} value="0" color="#000">---</option>
                <option selected={props.value === "5"} value="5" color="var(--green)">5</option>
                <option selected={props.value === "4"} value="4" color="var(--purple)">4</option>
                <option selected={props.value === "3"} value="3" color="var(--yellow)">3</option>
                <option selected={props.value === "2"} value="2" color="#D2488A">2</option>
                <option selected={props.value === "1"} value="1" color="var(--red)">1</option>
            </select>
        </div>
    )
};
