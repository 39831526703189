import React, { useState, useEffect } from "react";
import budgetStyle from "../../assets/css/budget.module.css";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from "../../components/loader";
import { rootPath } from "../../utils/main"

function Budget() {
  const [isLoading, setIsLoading] = useState(true);
  const [incomeDrop, setIncomeDrop] = useState(false);
  const [outcomeDrop, setOutcomeDrop] = useState(false);
  const [outcomeForm, setOutcomeForm] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [initialBudget, setInitialBudget] = useState([]);
  const [begin_date, setBeginDate] = useState("");
  const [finish_date, setFinishDate] = useState("")
  const [payment, setPayment] = useState(true)
  const [report, setReport] = useState(0);
  const [teachers, setTeachers]  = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [deleteType, setDeleteType] = useState({});
  
  const handleSelectTeacher = (e) => {
    setSelectedTeacher(e.target.value);
  }

  function deleteObj() {
    if (deleteType["type"] === 0) {
      axios
        .delete(`budget/outcome/serializer/`, {
          params: {
            obj_id: deleteType["obj_id"],
          },
        })
        .then((respone) => {
          let obj = budgetList.filter((item) => {
            if (
              item.list_type === 0 &&
              item.number_id === deleteType["obj_id"]
            ) {
              return true;
            }
            return false;
          });
          setBudgetList(
            budgetList.filter((item) => {
              if (
                item.list_type === 0 &&
                item.number_id === deleteType["obj_id"]
              ) {
                return false;
              }
              return true;
            })
          );

          let data = report;
          data["outlay_sum"] = data["outlay_sum"] - obj[0].money;
          data["overall_outcome"] = data["overall_outcome"] - obj[0].money;
          setReport(data);
          setModal(false);
          setModalType(0);
        });
      // .catch(function (error) {
      //   Notification(
      //     "Internet bilan muomo",
      //     "Nimadir ishlamayapdi",
      //     "var(--yellow)",
      //     5000
      //   );
      // });
    }
    if (deleteType["type"] === 1) {
      axios
        .delete(`accounts/worker/money/`, {
          params: {
            obj_id: deleteType["obj_id"],
          },
        })
        .then((respone) => {
          window.location.reload();
          //   let obj = budgetList.filter(item => item.number_id === deleteType["obj_id"])
          //   setBudgetList(budgetList.filter(item => item.number_id !== deleteType["obj_id"]))
          //   let data = report
          //   if(obj[0].why_given === 0){
          //     data['total_advance'] = data['total_advance'] - obj[0].money
          //     data['overall_outcome'] = data['overall_outcome'] - obj[0].money
          //   }
          //   if(obj[0].why_given === 1){
          //     data['total_punishment'] = data['total_punishment'] - obj[0].money
          //   }
          //   if(obj[0].why_given === 2){
          //     data['total_bonus'] = data['total_bonus'] - obj[0].money
          //     data['overall_outcome'] = data['overall_outcome'] - obj[0].money
          //   }
          //   // data['outlay'] = data['outlay_sum'] - obj[0].money
          //   setReport(data)
          //   setModal(false)
          //   setModalType(0)
        })
        .catch(function (error) {
          //   Notification(
          //     "Internet bilan muomo",
          //     "Nimadir ishlamayapdi",
          //     "var(--yellow)",
          //     5000
          //   );
        });
    }
    if (deleteType["type"] === 2) {
      axios
        .delete(`budget/payment/delete/${deleteType["obj_id"]}/`)
        .then((respone) => {
          window.location.reload();
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--yellow)",
            5000
          );
        });
    }
  }
  useEffect(() => {
    if (selectedTeacher !== "all") {
      setBudgetList(initialBudget.filter(t => {
          return t.teacher === parseInt(selectedTeacher);
      }))
    } else if (selectedTeacher === "all") {
      setBudgetList(initialBudget)
    }
    else {
      setBudgetList(initialBudget)
    }
  }, [selectedTeacher])

  function callKeyup(e) {
    if (e.target.value.length < 12) {
      var n = parseInt(e.target.value.replaceAll(/\D/g, ""), 10);
      if (n) {
        e.target.value = n.toLocaleString().replaceAll(",", " ");
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = e.target.value.toLocaleString().slice(0, -1);
    }
  }

  useEffect(() => {
    if (begin_date, finish_date){
      setIsLoading(false)
        axios
          .get("budget/reports", {params: {
            begin_date: `${begin_date.$y}-${begin_date.$M +1}-${begin_date.$D}`,
            finish_date: `${finish_date.$y}-${finish_date.$M +1}-${finish_date.$D}`,
        }})
          .then(function (response) {
            setBudgetList(response.data.data);
            setInitialBudget(response.data.data);
            setReport(response.data.report);
            setIsLoading(true)
          });
          axios
      .get(`/accounts/teachers/`)
      .then((res) => {setTeachers(res.data);})
      .catch((err) => {
        console.log(err);
      });
    }
  }, [finish_date, begin_date]);

  function visibleNumber(value) {
    var n = parseInt(value.replaceAll(/ /g, ""), 10);
    n = n.toLocaleString().replaceAll(",", " ");
    if (n) {
      return n;
    } else {
      return "";
    }
  }
  function print_budget() {
    var printContent = document.getElementById("daily_budget").innerHTML;

    try {
      var mywindow = window.open("", "PRINT", "height=400,width=600");
      let printHTMl = `
          <!DOCTYPE html>
          <html>
              <head>
                ${document.querySelector("head").innerHTML}
              </head>
              <body>
                ${printContent}
              </body>
          </html>
        `;
      mywindow.document.write(printHTMl);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      // setTimeout(function () {
      //   mywindow.print();
      //   mywindow.close();
      // }, 1000);
    } catch (err) {}
  }


  function print_reciept(rec_id) {
    let reciept = null;
    axios.get("budget/reciept/print/" + rec_id + "/").then((res) => {
      console.log(res.data);
      reciept = res.data;

      var discount = "";
      if (reciept?.discount > 0) {
        discount = `${reciept?.discount}%`;
      } else {
        if (reciept?.discount_absolute > 0) {
          discount = `${visibleNumber(`${reciept?.discount_absolute}`)} sum`;
        }
      }

      var mywindow = window.open("", "PRINT", "height=400,width=600");
      let printHTMl = `
        <!DOCTYPE html>
        <html>
            <head>
            </head>
            <body>
            <div>
            <div className="StyledReceipt" id="reciept">
            <div id="print">
                <div className="flex justify-center">
                    <img
                    src=${rootPath}${reciept?.log_company}
                    width=180px
                    alt=""
                    />
                </div>
                <h3 className="text-2xl text-[#000] text-center">
                ${reciept?.factory_name}
                </h3>
                <br />
                <br />
                <table className="table-print w-full text-[#000]">
                <tbody>
                    <tr></tr>
                    <tr>
                    <td>
                        <strong>Ism Familya:</strong>
                    </td>
                    <td>${reciept?.student_full_name}</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Ustoz:</strong>
                    </td>
                    <td>${reciept?.group_teacher}</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Darajasi:</strong>
                    </td>
                    <td>${reciept?.group_grade}</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Kurs narxi:</strong>
                    </td>
                    <td>
                        ${visibleNumber(`${reciept?.grade_price}`)} sum
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <strong>To'landi:</strong>
                    </td>
                    <td>${visibleNumber(`${reciept?.money}`)} sum</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Chegirma</strong>
                    </td>
                    <td>
                       <strong>${discount}</strong>
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Chegirmalar bilan:</strong>
                    </td>
                    <td>
                        ${visibleNumber(`${reciept?.money_with_discount}`)}
                        sum
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Oylik to'lov:</strong>
                    </td>
                    <td>${reciept?.month}</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Sana:</strong>
                    </td>
                    <td>${reciept?.created_at}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Vaqt:</strong>
                      </td>
                      <td>${reciept?.time}</td>
                    </tr>
                    <tr>
                    <td>
                        <strong>Qabul qiluvchi:</strong>
                    </td>
                    <td>${reciept?.created_by}</td>
                    </tr>
                </tbody>
                </table>

            </div>
            </div>
            <br />
            <br />
            </div>
        </div>
            </body>
        </html>
      `;
      mywindow.document.write(printHTMl);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      setTimeout(function () {
        mywindow.print();
        mywindow.close();
      }, 1000);
    });
  }

  return (
    <>
    {!isLoading ?
        <Loader />
        :
        (
          <>
                <div className="flex container items-start md:justify-between justify-center flex-wrap md:mx-5 my-5 gap-5">
        <div className={budgetStyle.card}>
          <NavLink to="/budget/" className="text-xl text-[var(--theme)]">
            Kunlik Hisobotlar
          </NavLink>
          <h3 className="text-2xl ">
            <i className="fa-sharp fa-solid fa-diagram-subtask mx-3"></i>
            Boshqa Hisobotlar
          </h3>
        </div>

        <div
          className={`${budgetStyle.card} ${incomeDrop && budgetStyle.active}`}
        >
          <div className="flex items-center justify-between">
            <h3 className="text-3xl text-[var(--green)]">Kirim</h3>
            <div>
              <i
                className={`fa-sharp fa-solid fa-angle-up text-2xl ${budgetStyle.toggle}`}
                onClick={() => setIncomeDrop((prev) => !prev)}
              ></i>
              <NavLink to={"/get-payment/"}>
                <i
                  className={`text-2xl fa-solid  fa-plus mx-1 budgetStyle.toggle}`}
                ></i>
              </NavLink>
            </div>
          </div>

          <h4 className="text-2xl">
            {visibleNumber(`${report?.overall_income}`)}
          </h4>

          <div className={`${budgetStyle.drop}`}>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-solid fa-coin"></i>
                <h4 className="inline ml-2">Naqd pul</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_cash}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-sharp fa-solid fa-money-bill-transfer"></i>
                <h4 className="inline ml-2">Pul o'tkazish orqali</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_online}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-solid fa-credit-card"></i>
                <h4 className="inline ml-2">Plastik orqali</h4>
              </div>
              <h5>{visibleNumber(`${report?.pay_card}`)}</h5>
            </div>
          </div>
        </div>
        <div
          className={`${budgetStyle.card} ${
            outcomeDrop && budgetStyle.active
          } ${outcomeForm && budgetStyle.from_activate}`}
        >
          <div className="flex items-center justify-between">
            <h3 onClick={() => setPayment(!payment)} className="text-3xl cursor-pointer text-[var(--red)]">Chiqim</h3>
            <div>
              <i
                className={`fa-sharp fa-solid fa-angle-up text-2xl mx-1 ${budgetStyle.toggle}`}
                onClick={() => (
                  setOutcomeDrop((prev) => !prev), setOutcomeForm(false)
                )}
              ></i>
              
            </div>
          </div>
          <h4 className="text-2xl">
            {visibleNumber(`${report.overall_outcome}`)}
          </h4>
          
          <div className={`${budgetStyle?.drop}`}>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-light fa-money-check-pen"></i>
                <h4 className="inline ml-2">Maoshlar</h4>
              </div>
              <h5>1 000 000</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div className="inline">
                <i className="fa-solid fa-chart-mixed"></i>
                <h4 className="inline ml-2">Jarimalar</h4>
              </div>
              <h5>{visibleNumber(`${report?.total_punishment}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-solid fa-hand-holding-dollar"></i>
                <h4 className="inline ml-2">Avanslar</h4>
              </div>
              <h5>{visibleNumber(`${report?.total_advance}`)}</h5>
            </div>
            <div className="flex items-center justify-between my-1">
              <div>
                <i className="fa-thin fa-sack-dollar"></i>
                <h4 className="inline ml-2">Boshqa</h4>
              </div>
              <h5>{visibleNumber(`${report?.outlay_sum}`)}</h5>
            </div>
          </div>
          <div className={`${budgetStyle.from}`}>
            <br />
            <div className={budgetStyle.group_input}>
              <i className="fa-sharp fa-light fa-pen-ruler"></i>
              <input
                type="text"
                className="bg-transparent"
                placeholder="Chiqim nomi"
              />
            </div>
            <div className={budgetStyle.group_input}>
              <i className="fa-solid fa-dollar-sign"></i>
              <input
                type="text"
                placeholder="Summa"
                className="bg-transparent"
                onChange={(e) => callKeyup(e)}
              />
            </div>
            <div className={budgetStyle.group_input}>
              <i className="fa-solid fa-receipt"></i>
              <input
                type="text"
                className="bg-transparent"
                placeholder="Izoh"
              />
            </div>
            <br />
            <button className="hover:bg-transparent w-full bg-[var(--green)]  hover:text-[var(--green)] font-semibold text-white py-2 px-4 border rounded-xl hover:border-[var(--green)]  hover:border-transparent rounded">
              Qo'shish
            </button>
          </div>
        </div>
        <div className={budgetStyle.card}>
          <h3 className="text-3xl text-[var(--purple)]">Jami</h3>
          <h4 className="text-2xl">
            {visibleNumber(`${report?.overall_income - report?.overall_outcome}`)}
          </h4>
        </div>
      </div>
      <div className="bc-card" >
        <div className="flex flex-wrap gap-5 items-center">
          <div className="bg-[var(--theme-bg)] flex items-center my-3  rounded-[15px] p-4 px-[20px]">
            <i className="fa-duotone fa-calendar text-2xl mx-3 text-[var(--green)]"></i>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month", "day"]}
                label={"boshlanish sanasi"}
                value={begin_date}
                onChange={(newValue) => setBeginDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: "100%" },
                    variant: "standard",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="bg-[var(--theme-bg)] flex items-center my-3  rounded-[15px] p-4 px-[20px]">
            <i className="fa-duotone fa-calendar text-2xl mx-3 text-[var(--red)]"></i>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                views={["year", "month", "day"]}
                label={"tugash sanasi"}
                value={finish_date}
                onChange={(newValue) => setFinishDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    style: { width: "100%" },
                    variant: "standard",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <select value={selectedTeacher} onChange={handleSelectTeacher} className="w-auto mx-5 py-3 color-[var(--theme-color)] bg-[var(--theme-bg)] px-4 rounded-md outline-none">
              <option value="all">Barchasi</option>
              {teachers?.map(teacher => (
                <option className="py-3 px-4 h-8" value={teacher.id} key={teacher.id}>{teacher.name} {teacher.surname}</option>
              ))}
            </select>
          
          <div className="m-auto">
            <button className="button rounded-xl hover:text-white"
              onClick={print_budget}
            >
              {" "}
              <i class="fa-solid fa-print"></i> Chop etish
            </button>
          </div>
        </div>
        <div className="table-container" id="daily_budget">
          <div className="table-horizontal-container" >
            <table className="table-information" >
              <tbody>
                {budgetList?.map((item, index) => {
                  const WHY_GIVEN = ["advance", "punishment", "bonus"];
                  const type_of_tAction = [
                    "Avans olindi",
                    "Jarima solindi",
                    "Mukofat puli",
                  ];
                  return {
                    0: (
                      <tr className="outcome">
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.title}</span>
                          <br />
                          <a href="">{item.reason}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>..</span>
                        </td>
                        <td>
                          <span>Chiqim</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                        <td>
                          <div className="flex p-5 gap-5 justify-center">
                            <div className="button">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.875 7L21 13.125"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.4441 23.5691L4.43066 17.5557"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="button">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.625 6.125L4.375 6.12501"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.375 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.625 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                    1: (
                      <tr className={WHY_GIVEN[item.why_given]}>
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.people_name}</span>
                          <br />
                          <a href={`tel: {people_name}`}>{item.people_phone}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>{item.reason}</span>
                        </td>
                        <td>
                          <span>{type_of_tAction[item.why_given]}</span>
                          <br />
                          <span>{visibleNumber(`${item.money}`)}</span>
                        </td>
                        <td>
                          <div className="flex p-5 gap-5 justify-center">
                            <div className="button">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.1376 23.6249H5.25C5.01794 23.6249 4.79538 23.5327 4.63128 23.3686C4.46719 23.2045 4.375 22.9819 4.375 22.7499V17.8623C4.375 17.7474 4.39763 17.6336 4.44161 17.5275C4.48558 17.4213 4.55003 17.3248 4.63128 17.2436L17.7563 4.11859C17.9204 3.95449 18.1429 3.8623 18.375 3.8623C18.6071 3.8623 18.8296 3.95449 18.9937 4.11859L23.8813 9.00615C24.0454 9.17024 24.1376 9.3928 24.1376 9.62487C24.1376 9.85693 24.0454 10.0795 23.8813 10.2436L10.7563 23.3686C10.675 23.4498 10.5786 23.5143 10.4724 23.5583C10.3663 23.6022 10.2525 23.6249 10.1376 23.6249Z"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.875 7L21 13.125"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.4441 23.5691L4.43066 17.5557"
                                  stroke="#4FB286"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="button">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.625 6.125L4.375 6.12501"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.375 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.625 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                    2: payment && (
                      <tr className="payment">
                        <td>{index + 1})</td>
                        <td>
                          <span>{item.people_name}</span>
                          <br />
                          <a href={`tel: {people_name}`}>{item.people_phone}</a>
                        </td>
                        <td>
                          <span>{item.created_at}</span>
                          <br />
                          <span>{item.payment_month} uchun</span>
                        </td>
                        <td>
                          <span>Oylik To'lov</span>
                          <br />
                          <span>
                            {visibleNumber(`${item.money_with_discount}`)}{" "}
                            {item.discount > 0 && (
                              <strong className="text-[var(--red)]">
                                {item.discount}%
                              </strong>
                            )}
                          </span>
                        </td>
                        <td>
                          <div className="flex p-5 gap-5 justify-center">
                          <button
                                className="button rounded-xl hover:text-white"
                                onClick={() => print_reciept(item.number_id)}
                              >
                                {" "}
                                <i class="fa-solid fa-print"></i>
                            </button>
                            <div
                             onClick={function () {
                              setModal(true);
                              setModalType(0);
                              setDeleteType({
                                type: 2,
                                obj_id: item.number_id,
                              });
                            }}
                             className="button">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.625 6.125L4.375 6.12501"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.375 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.625 11.375V18.375"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
                                  stroke="var(--red)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                  }[item["list_type"]];
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
            {
              {
                0: (
                  <div className="content">
                    <div className="body-content flex flex-col items-center">
                      <svg
                        width="237"
                        height="235"
                        viewBox="0 0 237 235"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                          stroke="#FFCC00"
                          strokeWidth="4"
                        />
                        <path
                          d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                          stroke="#2D2D2B"
                          strokeWidth="10"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M119.5 73.4375V124.844"
                          stroke="#FFCC00"
                          strokeWidth="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                          fill="#FFCC00"
                          stroke="#FFCC00"
                          strokeWidth="10"
                        />
                        <path
                          d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                          stroke="#FFCC00"
                          strokeWidth="3"
                        />
                        <path
                          d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                          stroke="#FFCC00"
                          strokeWidth="3.5"
                        />
                      </svg>
                      <h1 className="text-4xl">Ogohlantirish</h1>
                      <br />
                      <h3 className="text-2xl width-50 text-center">
                        Rostan ham ushbu elementni o'chirmoqchimisiz
                      </h3>
                    </div>
                    <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                      <button
                        onClick={function () {
                          setModalType(0);
                          setModal(false);
                        }}
                        className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                      >
                        Ortga qaytish
                      </button>
                      <button
                        onClick={deleteObj}
                        className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                      >
                        O'chirish
                      </button>
                    </div>
                  </div>
                ),
              }[modalType]
            }
          </div>
          </>
        )
        }

    </>
  );
}
export default Budget;
