import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react'
import AttendanceDrop from '../components/teacher/attendance_drop_all'
import axios from 'axios';


const TeacherAttendance = () => {
    const [users, setUsers] = useState([]);
    const [days, setDays] = useState([]);
    const [attendances, setAttendances] = useState([]);

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero based
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const getAttendance = (date, userId) => {
        var attendance = attendances.filter(x => {
            return x.to_date === date && x.user === userId
        })[0]
        return attendance?.come_status
    }

    const isDone = (userId) => {
        var attendance = attendances.filter(x => {
            return x.to_date === formattedDate && x.user === userId
        })[0]
        return attendance?.is_done
    }

    const sendAttendance = (userId, status) => {
        const formData = {
            user_id: userId,
            status: status
        }
        axios.post('attendance/teacher-attendance/', formData).then(res => fetchData()).catch(err => console.log(err));
    }

    const fetchData = () => {
        axios
            .get('/accounts/company/for-attendance/')
            .then((response) => {
                console.log(response);
                setDays(response.data.attendance_days)
                setUsers(response.data.users)
                setAttendances(response.data.attendances)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchData();
    }, [useLocation()])

    return (
        <div>
            <h1 className='text-2xl'>Bolimdagi Davomat</h1>
            <br />
            <div className="table-container bg-[var(--theme-sidebar)]">
                <div className="table-horizontal-container">
                    <table className='table-border'>
                        <thead>
                            <tr>
                                <th className='md:hidden'></th>
                                <th><span className='text-[var(--theme)] text-2xl'>&#8470;</span></th>
                                <th>ISM & Familya</th>
                                <th>{formattedDate}</th>
                                {days.map((day, idx) => {
                                    return (
                                        <th key={`id-${idx}`}>{day}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((item, idx) => {
                                const colors = ["bg-[var(--green)]", "bg-[var(--red)]", "bg-[var(--yellow)]", "bg-[var(--oppocity-bg)]"]
                                const texts = ["Keldi", "Kelmadi", "Kech qoldi", "Darsi yo'q"]
                                

                                return (
                                    <tr key={`item-${idx}`}>
                                        <td className='md:hidden'></td>
                                        <td>{idx + 1}</td>
                                        <td className='w-[200px]'><span>{item.name} {item.surname}</span></td>
                                        <td>
                                            { 
                                                isDone(item.id)
                                                ?
                                                <div className={`status_bar ${colors[parseInt(getAttendance(formattedDate, item.id))]}`}>{texts[parseInt(getAttendance(formattedDate, item.id))]}</div> 
                                                :
                                                <AttendanceDrop value={parseInt(getAttendance(formattedDate, item.id)).toString()} getID={e => {sendAttendance(item.id, e)}} />
                                            }
                                        </td>
                                        {days.map((day, idx) => {
                                            const atn = parseInt(getAttendance(day, item.id))
                                            return (
                                                <td key={`id-${idx}`}><div className={`status_bar ${colors[atn]}`}>{texts[atn]}</div></td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default TeacherAttendance
