import React from 'react';
import Loader from "../../components/loader";

export default function Lessons(params) {
    return (
        <div className="grid place-items-center h-[80vh]">
            <center>
                <Loader />
                <br /><br />
                <h1 className='text-6xl'>Bu sahifa endi Qo'shiladi</h1>
            </center>
        </div>
    )
};
