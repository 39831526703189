import React, { useState, useEffect } from "react";
import studentStyles from "../../assets/css/reception.module.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Notification from "../../components/alert";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";


import axios from "axios";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "var(--theme-bg)",
  },
});
export function SendAPIToStudentSet(id, obj) {
  axios
    .post(`/leads/student-sets-students/${id}/`, {
      students: obj.map((obj) => obj.id),
    })
    .then(function (response) {
      Notification("", "Nimadir ishlamayapdi", "var(--yellow)", 5000);
    })
    .catch(function (error) {
      Notification(
        "Internet bilan muomo",
        "Nimadir ishlamayapdi",
        "var(--yellow)",
        5000
      );
    });
}
export default function Students() {
  const [Students, SetStudent] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [listStudent, setlistStudent] = useState([]);
  const [subject, setSubject] = useState(null);
  const [grades, setGrades] = useState([]);
  const [gradeValue, SetGradeValue] = useState(null);
  const [Wherelist, setWherelist] = useState([]);
  const [where, setWhere] = useState(null);
  const [receptionist, setReceptionist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, SetSelectedUser] = useState();
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [details, setDetails] = useState({});

  const [collectionOptions, setCollectionOptions] = useState([]);
  const [selectedCol, setSelectedCol] = useState(null);
  const [setsOption, setSetsOption] = useState([]);
  const [valueSets, setValueSets] = useState(null);
  const [update, setUpdate] = useState({})


  const [studentComment, setStudentComment] = useState("");
  const [commentType, setCommentType] = useState(null)
  const [CSid, setCSid] = useState(null);
  const [comments, setComments] = useState([])
  
 const routerParams = useParams();

  useEffect(() => {
    axios
      .all([
        axios.get("main/subject/"),
        axios.get("leads/where-list/"),
        axios.get("accounts/workers/"),
        axios.get("leads/student-collections/"),
        axios.get("leads/student-set/"),
        axios.get("leads/student-sets/detail/" + routerParams.collection_id + "/"),
      ])
      .then((response) => {
        setSubjects(response[0].data);
        setWherelist(response[1].data);
        setReceptionist([...response[2].data, {id: null, name: "Online", surname: "qo'shilganlar"} ]);
        setCollectionOptions(response[3].data);
        setSetsOption(response[4].data);
        SetStudent(response[5].data?.student)
        setDetails(response[5].data?.collection)
      })
    setIsLoading(false);
  }, [useLocation()]);

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allStudents = Students;
      setlistStudent(allStudents);
    } else {
      setlistStudent([]);
    }
  };
  const handleCountryChange = (e, student) => {
    if (e.target.checked) {
      setlistStudent([...listStudent, student]);
    } else {
      setlistStudent(listStudent.filter((item) => item.id !== student.id));
    }
  };
  const classes = useStyles();
  
  function phoneKeyup(e) {
    var y = e.target.value.replaceAll("+998 ", "").replaceAll(" ", "");
    var x = y.replaceAll(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (y > 0) {
      e.target.value = `${x[1] ? "+998 " + x[1] : ""}${x[2] ? " " + x[2] : ""}${
        x[3] ? " " + x[3] : ""
      }${x[4] ? " " + x[4] : ""}`;
    } else {
      e.target.value = "";
    }
  }
  
  function dateKeyup(e) {
    var y = e.target.value.replaceAll("/", "");
    var x = y.replaceAll(/\D/g, "").match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    if (e.target.value.length) {
      if (+x[2] > 12) {
        x[2] = "12";
      }
      if (x[2] === "00") {
        x[2] = "01";
      }
      var checkDate = new Date(x[1], x[2], 0).getDate();
      if (+x[3] > checkDate) {
        x[3] = checkDate;
      }
      if (x[3] === "00") {
        x[3] = "01";
      }
      e.target.value = `${x[1] ? x[1] : ""}${x[2] ? "-" + x[2] : ""}${
        x[3] ? "-" + x[3] : " "
      }`;
    } else {
      e.target.value = "";
    }
  }

  function FormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    let full_name = data.get("full_name");
    let phone = data.getAll("phone");
    let parent_phone = data.getAll("parent_phone");
    let birthday = data.getAll("birthday");
    let comment = data.getAll("comment");
    if (phone[0]?.length === 17 && parent_phone[0]?.length === 17) {
      if (full_name.trim().length > 4) {
        if (birthday[0]?.length === 10) {
          if (subject && where && gradeValue && e.target.checkValidity()) {
            let form = {
              full_name: full_name,
              phone: phone[0],
              time_alert: data.get("time_alert") || null,
              parent_phone: parent_phone[0],
              birthday: birthday[0],
              where: where.id,
              subject: subject.id,
              grade: gradeValue.id,
              comment: comment[0],
            };
            axios
              .post("accounts/students/create/", form)
              .then(function (response) {
                if (response.status === 201) {
                  e.target.reset();
                  SetStudent([...Students, response.data]);
                  Notification(
                    "Qo'shildi",
                    "Mufaqiyatli amalga oshdi",
                    "var(--green)",
                    5000
                  );
                }
              })
              .catch((error) => {
                Notification(
                  "Tog'ri to'ldiring",
                  "Yoki keyinroq urinib koring",
                  "var(--red)",
                  5000
                );
              });
          } else {
            Notification(
              "XATO !!!",
              "Hamma joyni to'g'ri to'ldiring",
              "var(--red)",
              5000
            );
          }
        } else {
          Notification("XATO !!!", "Sana xato yozildi", "var(--red)", 5000);
        }
      } else {
        Notification(
          "XATO !!!",
          "Ism familya xato yozildi",
          "var(--red)",
          5000
        );
      }
    } else {
      Notification(
        "XATO !!!",
        "Hamma Telefon raqamni tog'ri to'ldiring",
        "var(--red)",
        5000
      );
    }
  }

  function updateStudent(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    let full_name = data.get("full_name");
    let phone = data.getAll("phone");
    let parent_phone = data.getAll("parent_phone");
    let birthday = data.getAll("birthday");
    let comment = data.getAll("comment");
    if (phone[0]?.length === 17 && parent_phone[0]?.length === 17) {
      if (full_name.trim().length > 4) {
        if (birthday[0]?.length === 10) {
          if (subject && where && (gradeValue || update.grade)) {
            
            let form = {
              id: data.get("id"),
              time_alert: data.get("time_alert") || null,
              full_name: full_name,
              phone: phone[0],
              parent_phone: parent_phone[0],
              birthday: birthday[0],
              where: where.id,
              subject: subject.id,
              grade: gradeValue ? gradeValue.id : update.grade.id,
              comment: comment[0],
            };
            console.log(form)
            axios
              .put("accounts/students/create/", form)
              .then(function (response) {
                if (response.status === 201) {
                  e.target.reset();
                  let list = Students
                  let change = false
                  
                  for (let i=0; i<list.length; i++){
                    if (list[i]['id'] === response.data.id){
                      list[i] = response.data
                      change= true
                    }
                  }
                  SetStudent(list)
                  setModal(false)
                  setModalType(0)
                  if(!change){
                    SetStudent([...Students, response.data]);
                  }

                  Notification(
                    "Qo'shildi",
                    "Mufaqiyatli amalga oshdi",
                    "var(--green)",
                    5000
                  );
                }
              })
              .catch((error) => {
                Notification(
                  "Tog'ri to'ldiring",
                  "Yoki keyinroq urinib koring",
                  "var(--red)",
                  5000
                );
              });
          } else {
            Notification(
              "XATO !!!",
              "Hamma joyni to'g'ri to'ldiring",
              "var(--red)",
              5000
            );
          }
        } else {
          Notification("XATO !!!", "Sana xato yozildi", "var(--red)", 5000);
        }
      } else {
        Notification(
          "XATO !!!",
          "Ism familya xato yozildi",
          "var(--red)",
          5000
        );
      }
    } else {
      Notification(
        "XATO !!!",
        "Hamma Telefon raqamni tog'ri to'ldiring",
        "var(--red)",
        5000
      );
    }
  }

  useEffect(() => {
    if (subject !== null) {
      axios.get("main/grade/" + subject.id + "/").then((response) => {
        setGrades(response.data);
      });
      setIsLoading(false)
      SetGradeValue(null);

    } else {
      setGrades([]);
      SetGradeValue(null);
    }
  
  }, [subject]);




  function DeleteCollection(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    let arr = listStudent.map((item) => item.id);
    axios
      .post(`/accounts/students/list/`, {
        ids: arr,
        reason: data.get('reason')
      })
      .then(function (response) {
        SetStudent(Students.filter((item) => !arr.includes(item.id)));
        setlistStudent([]);

        setModal(false);
        setModalType(0);
        Notification("muvaffaqiyatli", "O'quvchilar o'chirildi", 5000);
      })
      .catch(function (error) {
        Notification(
          "Internet bilan muomo",
          "Nimadir ishlamayapdi",
          "var(--red)",
          5000
        );
      });
  }

  function sendToCollection() {
    if (!selectedCol) {
      Notification(
        "Xato bajarildi",
        "Qaysi to'plamligini tanlang",
        "var(--yellow)",
        5000
      );
    }
    if (!listStudent.length) {
      Notification(
        "Xato bajarildi",
        "Tanlangan o'quvchilar bo'm bo'sh",
        "var(--yellow)",
        5000
      );
    }
    if (selectedCol && listStudent.length) {
      axios
        .post(`leads/collection-student/${selectedCol.id}/`, {
          my_field: "update",
          students: listStudent.map((item) => item.id),
        })
        .then((response) => {
          setlistStudent([]);
          Notification(
            "muvaffaqiyatli",
            "O'quvchilar To'plamga qo'shildi",
            "var(--green)",
            5000
          );
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--red)",
            5000
          );
        });
    }
  }
  function sendToStudentSets() {
    if (!valueSets) {
      Notification(
        "Xato bajarildi",
        "Qaysi 'Tayyor To'plamligi' tanlang",
        "var(--yellow)",
        5000
      );
    }
    if (!listStudent.length) {
      Notification(
        "Xato bajarildi",
        "Tanlangan o'quvchilar bo'm bo'sh",
        "var(--yellow)",
        5000
      );
    }
    if (valueSets && listStudent.length) {
      axios
        .post(`leads/student-sets-students/${valueSets.id}/`, {
          my_field: "update",
          students: listStudent.map((item) => item.id),
        })
        .then((response) => {
          setlistStudent([]);
          Notification(
            "muvaffaqiyatli",
            "O'quvchilar To'plamga qo'shildi",
            "var(--green)",
            5000
          );
        })
        .catch(function (error) {
          Notification(
            "Internet bilan muomo",
            "Nimadir ishlamayapdi",
            "var(--red)",
            5000
          );
        });
    }
  }

  function editObj(obj) {
    setUpdate(obj);
    setSubject(obj.subject);
    SetGradeValue(obj.grade);
    setWhere(obj.where)
    setModal(true)
    setModalType(4)

  }


  function getComments(id) {
    axios.get(`main/comments/get/${id}/`).then((response) => {
      setComments(response.data)
      let student = Students.filter((item) => item.id === id);
      setStudentComment(student[0].comment);
      setModal(true);
      setModalType(5);
      setCSid(id)
    });
  }

  function AddComment(e){
    e.preventDefault();
    let data = new FormData(e.target);
    console.log(CSid, commentType, data.get("comment"))

    axios
    .post(
      `main/comments/get/${CSid}/`,
      {
        type_comment: `${commentType.id}`,
        comment: data.get("comment")
      }
    )
    .then((response) => {
      e.target.reset()
      setComments(response.data)
      setModal(true);
      setModalType(5);
    })
  }

  return (
    <div className={studentStyles.main_page}>
      <div className="br-card">
        <br />
        <div className={studentStyles.header_content}>
          <div className="flex gap-4 flex-wrap my-4">
            <h2 className="text-3xl m-3 font-bold"><strong className="text-[var(--theme)]">{details?.title}</strong> To'plami</h2>
          </div>
          <div className="flex gap-4 items-center flex-wrap my-4">
            <button
              onClick={function () {
                if (listStudent.length > 0) {
                  setModalType(3);
                  setModal(true);
                } else {
                  Notification(
                    "Xato bajarildi",
                    "Avval o'quvchilarni tanlang",
                    "var(--yellow)",
                    5000
                  );
                }
              }}
              className={`${studentStyles.button}  text-[var(--blue)]`}
            >
              <i className="fa-regular fa-grid-round-2-plus text-xl"></i>
            </button>
            <button
              onClick={function () {
                setModal(true);
                setModalType(2);
              }}
              className={`${studentStyles.button}  text-[var(--purple)]`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 7H28"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25 4V10"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.5066 14.7728C22.9718 16.2167 22.0316 17.4754 20.7988 18.3979C19.566 19.3203 18.0931 19.8672 16.557 19.9728C15.021 20.0784 13.4871 19.7383 12.1396 18.9933C10.7922 18.2483 9.68847 17.1301 8.96112 15.773C8.23377 14.416 7.91371 12.8778 8.03938 11.3432C8.16505 9.80864 8.7311 8.34301 9.66954 7.12236C10.608 5.90172 11.8789 4.97798 13.3296 4.46212C14.7803 3.94627 16.3491 3.86023 17.8475 4.21436"
                  stroke="#7C90DB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={function () {
                setModal(true);
                setModalType(0);
              }}
              className={`${studentStyles.button} text-[var(--yellow)]`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 7L16 18L4 7"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8184 16L4.30859 24.7174"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.6916 24.7175L18.1816 16"
                  stroke="#FFCC00"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={function () {
                setModal(true);
                setModalType(1);
              }}
              className={`${studentStyles.button}`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.687 7.4375L5.31201 7.43751"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8125 13.8125V22.3125"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.1875 13.8125V22.3125"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26.5625 7.4375V27.625C26.5625 27.9068 26.4506 28.177 26.2513 28.3763C26.052 28.5756 25.7818 28.6875 25.5 28.6875H8.5C8.21821 28.6875 7.94796 28.5756 7.7487 28.3763C7.54944 28.177 7.4375 27.9068 7.4375 27.625V7.4375"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.3125 7.4375V5.3125C22.3125 4.74891 22.0886 4.20841 21.6901 3.8099C21.2916 3.41138 20.7511 3.1875 20.1875 3.1875H13.8125C13.2489 3.1875 12.7084 3.41138 12.3099 3.8099C11.9114 4.20841 11.6875 4.74891 11.6875 5.3125V7.4375"
                  stroke="#FC3F1D"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <br /><br />
        <div className={studentStyles.table_cap}>
          <table className="td-min-width-150">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className={studentStyles.checkbox}
                    checked={Students.length === listStudent.length}
                    onChange={handleCheckAllChange}
                  />
                </th>
                <th>Ism & familya</th>
                <th><pre>    Telefon raqami    </pre></th>
                <th>&#160;Fan&#160;</th>
                <th>Qayerdan</th>
                <th>So'ngi izoh</th>
                <th>Keyingi bog'lanish sanasi</th>
                <th> Kim qo'shdi</th>
                <th>Kim yangiladi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Students.map((student, idx) =>{ 
                const date =  new Date(student.time_alert)
                let month = ['Yan', 'Feb', 'Mar', 'Apr', 'May', 'Iyun', 'Iyul','Avg', 'Sen', 'Okt','Noy', 'Dekabr']
                const visibledate = `${date.getDate()}  ${month[date.getMonth()]} ${ date.getFullYear()} `
              
                return(
                <tr
                  className={`${studentStyles.row} ${
                    listStudent.includes(student.id) && studentStyles.active
                  }`}
                  key={`${student.id}qwert`}
                >
                  <td>
                    <input
                      type="checkbox"
                      id="checkbox"
                      className={studentStyles.checkbox}
                      checked={listStudent.includes(student)}
                      onChange={(e) => handleCountryChange(e, student)}
                    />
                  </td>
                  <td>
                    <strong className="px-2">{student.full_name}</strong>
                  </td>
                  <td>
                    <ul className="px-2">
                      <li>
                        <a href="tel:+998972361677">{student.phone}</a>
                      </li>
                      <li>
                        <a href="tel:+998972361677">{student.parent_phone}</a>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className={`${studentStyles.subject} p-3`}>
                      <li className="">{student.subject?.title}</li>
                      <li>{student.grade?.title}</li>
                    </ul>
                  </td>
                  <td>
                    <a className="text-[var(--theme)] text-base font-semibold px-2">
                      {student.where?.title}
                    </a>
                  </td>
                  <td>{student.comment || "Izoh yo'q"}</td>
                  <td>{student.time_alert ?
                            visibledate + " " +
                             new Date(student.time_alert)
                              .toLocaleString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                            })
                          :
                          "Vaqt berilmagan"
                      }
                      </td>
                  <td>
                    <NavLink
                      to=""
                      className="bg-[var(--theme)] text-white  p-2 px-3 rounded-full"
                    >
                      <i className="fa-solid fa-user-secret mx-1"></i>
                      {student?.created_by?.name}
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      to=""
                      className="bg-[#EA510D] text-white  p-2 px-3 rounded-full"
                    >
                      <i className="fa-solid fa-user-secret mx-1"></i>
                      {student?.updated_by?.name}
                    </NavLink>
                  </td>
                  <td>
                    <button
                      className="bg-[var(--yellow)] text-white h-[40px] w-[40px] rounded-full"
                      onClick={(e) => editObj(student)}
                    >
                      <i className="fa-light fa-pen-to-square"></i>
                    </button>
                    <button
                          className="bg-[var(--green)] mx-1  text-white h-[40px] w-[40px] rounded-full"
                          onClick={(e) => getComments(student.id)}
                        >
                          <i className="fa-solid fa-comment"></i>
                    </button>
                  </td>
                </tr>
                )
              })}
            </tbody>
          </table>
          <br />
          <br />
        </div>
      </div>
      <div className={`modal ${modal && "active"}`}>
        {
          {
            // XABAR YOZISH
            0: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">
                    Tanlangan foydalanuvchilar: {listStudent.length} ta{" "}
                  </h3>
                  <div className="flex gap-3">
                    <button className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--yellow)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <MultiAutocomplete
                    sx={{
                      border: "1px solid var(--yellow)",
                      outline: "var(--yellow)",
                    }}
                    color="var(--yellow)"
                    placeholder="O'quvchilar"
                    HandleChange={(event, value) => setlistStudent(value)}
                    value={listStudent}
                    options={Students}
                  />
                  <textarea
                    name=""
                    rows={7}
                    className="textarea"
                    placeholder="Xabar yozish (majburiy)"
                    defaultValue="Assalomu alaykum [oquvchi] siz ushbu [guruh] uchun to'lov qilishni unutman"
                  ></textarea>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    className="bg-[--yellow] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    xabar berish
                  </button>
                </div>
              </div>
            ),
            // Ochirish
            1: (
              <form className="content" onSubmit={DeleteCollection}>
                <div className="body-content flex flex-col items-center">
                  <svg
                    width="237"
                    height="235"
                    viewBox="0 0 237 235"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5378 98.7239L16.469 97L13.5378 95.2761L2.71127 88.9085L9.96886 76.1178L20.7498 82.4439L23.7603 84.2104L23.762 80.7199L23.7681 68H38.2319L38.238 80.7199L38.2397 84.2104L41.2502 82.4439L52.031 76.1178L59.2887 88.9085L48.4621 95.2761L45.5309 97L48.4621 98.7239L59.2887 105.091L52.031 117.882L41.2502 111.556L38.2397 109.79L38.238 113.28L38.2319 126H23.7681L23.762 113.28L23.7603 109.79L20.7498 111.556L9.96886 117.882L2.71127 105.091L13.5378 98.7239Z"
                      stroke="#FFCC00"
                      strokeWidth="4"
                    />
                    <path
                      d="M119.5 205.625C168.17 205.625 207.625 166.17 207.625 117.5C207.625 68.8299 168.17 29.375 119.5 29.375C70.8299 29.375 31.375 68.8299 31.375 117.5C31.375 166.17 70.8299 205.625 119.5 205.625Z"
                      stroke="#2D2D2B"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M119.5 73.4375V124.844"
                      stroke="#FFCC00"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M125.516 157.891C125.516 161.213 122.822 163.906 119.5 163.906C116.178 163.906 113.484 161.213 113.484 157.891C113.484 154.568 116.178 151.875 119.5 151.875C122.822 151.875 125.516 154.568 125.516 157.891Z"
                      fill="#FFCC00"
                      stroke="#FFCC00"
                      strokeWidth="10"
                    />
                    <path
                      d="M187.84 63.293L190.039 62L187.84 60.707L181.033 56.7036L185.509 48.8161L192.281 52.7898L194.539 54.1147L194.54 51.4968L194.544 43.5H203.456L203.46 51.4968L203.461 54.1147L205.719 52.7898L212.491 48.8161L216.967 56.7036L210.16 60.707L207.961 62L210.16 63.293L216.967 67.2964L212.491 75.1839L205.719 71.2102L203.461 69.8853L203.46 72.5032L203.456 80.5H194.544L194.54 72.5032L194.539 69.8853L192.281 71.2102L185.509 75.1839L181.033 67.2964L187.84 63.293Z"
                      stroke="#FFCC00"
                      strokeWidth="3"
                    />
                    <path
                      d="M170.411 179.508L172.976 178L170.411 176.492L159.372 169.999L166.877 156.774L177.876 163.228L180.511 164.774L180.512 161.72L180.518 148.75H195.482L195.488 161.72L195.489 164.774L198.124 163.228L209.123 156.774L216.628 169.999L205.589 176.492L203.024 178L205.589 179.508L216.628 186.001L209.123 199.226L198.124 192.772L195.489 191.226L195.488 194.28L195.482 207.25H180.518L180.512 194.28L180.511 191.226L177.876 192.772L166.877 199.226L159.372 186.001L170.411 179.508Z"
                      stroke="#FFCC00"
                      strokeWidth="3.5"
                    />
                  </svg>
                  <h1 className="text-4xl">Ogohlantirish</h1>
                  <br />
                  <h3 className="text-2xl width-50 text-center">
                    Siz hozirda {listStudent.length} ta Oq'uvchini ochirmoqdasiz
                    va bularni qaytarib bolmaydi
                  </h3>
                </div>
                <input
                  type="text" 
                  name="reason"
                  maxLength={125}
                  required={true}
                  className="input" placeholder="Sabab" />
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap">
                  <button
                    type="reset"
                    onClick={function () {
                      setModalType(0);
                      setModal(false);
                    }}
                    className="bg-[--green] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Ortga qaytish
                  </button>
                  <button
                    type="submit"
                    className="bg-[--red] w-full  hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'chirish
                  </button>
                </div>
              </form>
            ),
            2: (
              <form className="content" action="" onSubmit={FormSubmit}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi qo'shish </h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input
                      type="text"
                      className="input"
                      name="full_name"
                      placeholder="Ism familya"
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="phone"
                      className="input"
                      placeholder="nomer"
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="parent_phone"
                      className="input"
                      placeholder="Ota Onasini nomeri"
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => dateKeyup(e)}
                      className="input"
                      name="birthday"
                      placeholder="Tug'ilgan kuni (Yil/Oy/Kun)"
                      required
                    />
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setSubject(newValue);
                        } else {
                          setSubject(null);
                        }
                      }}
                      value={subject}
                      disablePortal
                      id="combo-box-demo"
                      options={subjects}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Fan" />
                      )}
                    />
                    <Autocomplete
                      className="input"
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          SetGradeValue(newValue);
                        } else {
                          SetGradeValue(null);
                        }
                      }}
                      value={gradeValue}
                      disablePortal
                      id="combo-box-demo"
                      options={grades}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Daraja" />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setWhere(newValue);
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={Wherelist}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Qayerdan" />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={2}
                      placeholder="Izoh"
                      
                    ></textarea>
                    <input 
                      type="datetime-local"
                      className="input col-span-1 md:col-span-2 "
                      name="time_alert"
                      defaultValue={update.time_alert && new Date(update.time_alert).toISOString().slice(0, -1)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'quvchi qo'shish
                  </button>
                </div>
              </form>
            ),
            3: (
              <div className="content">
                <div className="header  flex justify-between align-center">
                  <h3 className="text-2xl ml-3">
                    <strong>To'plamga</strong> Yoki <strong>Tayyorga</strong>{" "}
                    qo'shish
                  </h3>
                  <div className="flex gap-3">
                    <span className="button" onClick={() => setModal(false)}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="">
                    <h1 className="text-2xl">To'plamga Qoshish</h1>
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setSelectedCol(newValue);
                      }}
                      value={selectedCol}
                      disablePortal
                      id="combo-box-demo"
                      options={collectionOptions}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="To'plam" />
                      )}
                    />
                    <br />
                    <button
                      onClick={sendToCollection}
                      className="bg-[--green] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      To'plamga qoshish
                    </button>
                    <br />
                    <br />
                    <h1 className="text-2xl">Tayyorga Qoshish</h1>
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setValueSets(newValue);
                      }}
                      value={valueSets}
                      disablePortal
                      id="combo-box-demo"
                      options={setsOption}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Tayyor" />
                      )}
                    />
                    <br />
                    <button
                      onClick={sendToStudentSets}
                      className="bg-[--red] hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                    >
                      To'plamga qoshish
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 sm:flex-wrap"></div>
              </div>
            ),
            4: (
              <form className="content" action="" onSubmit={updateStudent}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi tahrirlash </h3>
                  <div className="flex gap-3">
                    <button
                      type="reset"
                      className="button" onClick={function(){setUpdate({}); setModal(false);}}>
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </button>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <input type="hidden" name="id" defaultValue={update.id} />
                    <input
                      type="text"
                      className="input"
                      name="full_name"
                      placeholder="Ism familya"
                      defaultValue={update.full_name}
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="phone"
                      className="input"
                      placeholder="nomer"
                      defaultValue={update.phone}
                      required
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => phoneKeyup(e)}
                      name="parent_phone"
                      className="input"
                      placeholder="Ota Onasini nomeri"
                      defaultValue={update.parent_phone}
                    />
                    <input
                      type="text"
                      onKeyUp={(e) => dateKeyup(e)}
                      className="input"
                      name="birthday"
                      placeholder="Tug'ilgan kuni (Yil/Oy/Kun)"
                      defaultValue={update.birthday}
                      required
                    />
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                          setSubject(newValue);
                      }}
                      defaultValue={update.subject}
                      disablePortal
                      id="combo-box-demo"
                      options={subjects}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Fan" />
                      )}
                    />
                    <Autocomplete
                      className="input"
                      onChange={(event, newValue) => {
                          SetGradeValue(newValue);
                      }}
                      defaultValue={update.grade}
                      disablePortal
                      id="combo-box-demo"
                      options={grades}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Daraja" />
                      )}
                    />
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setWhere(newValue);
                      }}
                      disablePortal
                      value={update.where}
                      id="combo-box-demo"
                      options={Wherelist}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Qayerdan" />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={2}
                      placeholder="Izoh"
                      defaultValue={update.comment}
                    ></textarea>
                    <input 
                    type="datetime-local"
                    className="input col-span-1 md:col-span-2 "
                    name="time_alert"
                    placeholder="Qo'shilgan payti"
                    defaultValue={update.time_alert && new Date(update.time_alert).toISOString().slice(0, -1)}

                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    O'quvchi qo'shish
                  </button>
                </div>
              </form>
            ),
            5: (
              <div className="content">
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">O'quvchi uchun izohlar</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setUpdate({});
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content block mt-5">
                  <div className="block">
                    <div>
                      <div className="bg-[var(--theme-bg)] max-w-[100%] text-xl w-fit p-2 rounded-xl">
                        <strong>
                          
                          <i className="fa-solid fa-user-vneck text-[var(--theme)]"></i>
                          O'quvchini izohi:
                        </strong>
                        <span>{studentComment}</span>
                      </div>
                    </div>
                  </div>

                </div>
                <br />
                <div className="h-full overflow-y-auto">
                  <div className="table-horizontal-container">
                    <table className="table-information">
                      <tbody>
                        {
                          comments?.map((comment, index) => {
                            const type = ["payment","punishment", "bonus", "advance", "outcome" ]
                            const type_color = ["bg-[var(--yellow)]", "bg-[var(--red)]", "bg-[var(--purple)]", "bg-[var(--green)]","bg-[var(--oppocity-bg)]"]
                            const text = ["Qabulxona","Davomat", "To'lov", "Taklif", "Shikoyat"]
                            return(
                              <tr key={`comment-${index}`} className={`${type[comment.type_comment]}`}>
                                <td className="w-[20px]">{index +1}</td>
                                <td>{comment.comment}</td>
                                <td><div className={`status_bar ${type_color[comment.type_comment]}`}>{text[comment.type_comment]}</div></td>
                              </tr>
                            )
                          })
                        }
                        
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="flex">
                  <button
                    title="xabar berish"
                    onClick={()=>{
                      setModal(true);
                      setModalType(6)
                    }}
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    boshqa izoh qo'shish
                  </button>
                </div>
              </div>
            ),
            6: (
              <form className="content" action="" onSubmit={AddComment}>
                <div className="header flex justify-between align-center">
                  <h3 className="text-2xl ml-3">Izoh qo'shish</h3>
                  <div className="flex gap-3">
                    <span
                      className="button"
                      onClick={function () {
                        setUpdate({});
                        setModal(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark text-xl w-5 text-[var(--theme)]"></i>
                    </span>
                  </div>
                </div>
                <div className="body-content">
                  <div className="grid">
                    <Autocomplete
                      className=" input"
                      onChange={(event, newValue) => {
                        setCommentType(newValue);
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        {
                          id: 0,
                          title: "Qabulxona"
                        },
                        {
                          id: 1,
                          title: "Davomat"
                        },
                        {
                          id: 2,
                          title: "To'lov"
                        },
                        {
                          id: 3,
                          title: "Taklif"
                        },
                        {
                          id: 4,
                          title: "Shikoyat"
                        },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.title}
                      classes={{ paper: classes.paper }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required label="Izoh turi" />
                      )}
                    />
                    <textarea
                      name="comment"
                      className="input"
                      id=""
                      rows={4}
                      placeholder="Izoh"
                      defaultValue={update.comment}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="flex">
                  <button
                    title="xabar berish"
                    type="submit"
                    className="bg-[--theme] w-full hover:opacity-70 text-white font-bold py-2 px-4 rounded-full"
                  >
                    Izohni qo'shish
                  </button>
                </div>
              </form>
            ),
          }[modalType]
        }
      </div>
    </div>
  );
}

const MultiAutocomplete = (props) => {
  return (
    <Autocomplete
      sx={{ m: 1 }}
      style={{ "--set-color": props.color }}
      value={props.value}
      onChange={(event, value) => props.HandleChange(event, value)}
      multiple
      id="tags-standard"
      options={props.options}
      getOptionLabel={(option) => option.full_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem
          key={`${option.id}`}
          value={option.id}
          sx={{ justifyContent: "space-between" }}
          {...props}
        >
          {option.full_name}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.placeholder}
          placeholder={props.placeholder}
          fullWidth
        />
      )}
    />
  );
};
