import React, { useState } from "react";
import {
  MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const MultiAutocomplete = (props) => {
    return (
      <Autocomplete
      sx={{ m: 1 }}
      style={{'--set-color': props.color}}
      value={props.value}
      onChange={(event, value) => props.HandleChange(event, value)}
      multiple
      id="tags-standard"
      options={props.options}


      
      getOptionLabel={(option) => option.name}


      disableCloseOnSelect
      renderOption={(props, option, extra, { selected }) => (
          <MenuItem
              key={`${extra}-${option.id}`}
              value={option}
              sx={{ justifyContent: "space-between" }}
              {...props}
          >
              {option.name}
              {selected ? <CheckIcon color="info" /> : null}
          </MenuItem>
      )}
      renderInput={(params) => (
          <TextField
              {...params}
              variant="outlined"
              label={props.placeholder}
              placeholder={props.placeholder}
              fullWidth
          />
      )} />
    );
  };

export default MultiAutocomplete